import React from 'react';
import { connect } from 'react-redux';
import { Redirect, NavLink, withRouter } from 'react-router-dom';
import { AccountHeader,CouponsSubNav,TextField } from '../components';
import { Internationalization, CurrencyFormatter,Validation,ApiService } from '../lib';
import {PhoneNumberFormat, PhoneNumberUtil} from 'google-libphonenumber';
import {RewardHelper } from '../services/rewardHelper';
import async from 'async';
const ValidationEnrollRules = [
    {
        input: 'phone',
        output: 'phoneErrorMessage',
        stopOnError: true,
        rules: [
            {
                rule: Validation.rules.notEmpty, 
                message: Internationalization.strings('verify_phone_screen.validation.phone_required')
            },
            {
                rule: Validation.rules.phoneNumber,
                message: Internationalization.strings('verify_phone_screen.validation.phone_not_valid')
            }
        ]
    },    
];
const ValidationVerifyRules=[
    {
        input: 'code',
        output: 'codeErrorMessage',
        stopOnError: true,
        rules: [
            {
                rule: Validation.rules.notEmpty,
                message: Internationalization.strings('verify_phone_screen.validation.code_required')
            }      
        ]
    },
];
class RewardsScreen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mode:(this.props.isVerified)? 'display' :'enroll',
            phone: null,
            code: null,
            
            phoneErrorMessage: null,
            codeErrorMessage: null,
            initial: {
                phone: null,                
                code:null,
                
            }
        };
    }

    componentDidMount() {
        var guest = this.props.guest;
        if (guest) {
            this.setState({
              
                phone: guest.phone,
                initial: {
                  
                    phone: guest.phone
                }
            });
        }
    }

    onTextChanged(e, fieldName) {
        this.setState({[fieldName]: e.target.value});
    }
    onResendCodePressed() {
        var phone = this.state.phone;
        ApiService.instance.loyaltyResendEnrollCode({
            phoneNumber: phone,
        }, (error, data, response) => {
            if (error) {
                this.props.toast.error(Internationalization.strings('verify_phone_screen.resend_error'));
                return;
            }
            this.props.toast.success(Internationalization.strings('verify_phone_screen.resend_success'));
        })
    }

    onVerifyCodePressed() {
        var isValid = Validation.isValid(this.state, ValidationVerifyRules, (newState) => this.setState(newState));
        if (!isValid) {
            return;
        }

        var phone = this.state.phone;

        this.props.verify(phone, this.state.code)
        .then(()=>{
            this.props.toast.success(Internationalization.strings('verify_phone_screen.verify_success'));
            this.setState({mode:'display'});
        })
        .catch((reason) => {
            if(reason == 409) {
                this.props.toast.error(Internationalization.strings('verify_phone_screen.conflict_error'));
                                
            } else {
                this.props.toast.error(Internationalization.strings('verify_phone_screen.verify_error'));
            }
        })
    }

    onEnrollPressed() {
        var isValid = Validation.isValid(this.state, ValidationEnrollRules, (newState) => this.setState(newState));
        if (!isValid) {
            return;
        }
        
        var phone = this.state.phone;
        ApiService.instance.loyaltyEnroll({
            phoneNumber: phone,
        }, (error, data, response) => {
            if (error) {
                
                this.props.toast.error(Internationalization.strings('verify_phone_screen.resend_error'));
                return;
            }else{
                this.setState({
                    mode: 'verify',
                    initial: {
                        phone: this.state.phone,                
                    }
                });
            }
            this.props.toast.success(Internationalization.strings('verify_phone_screen.resend_success'));
        })
        
    }


    onEnrollResendPressed() {
        var isValid = Validation.isValid(this.state, ValidationEnrollRules, (newState) => this.setState(newState));
        if (!isValid) {
            return;
        }
        
        var phone = this.state.phone;
        ApiService.instance.loyaltyResendEnrollCode({
            phoneNumber: phone,
        }, (error, data, response) => {
            if (error) {
                
                this.props.toast.error(Internationalization.strings('verify_phone_screen.resend_error'));
                return;
            }else{
                this.setState({
                    mode: 'verify',
                    initial: {
                        phone: this.state.phone,                
                    }
                });
            }
            this.props.toast.success(Internationalization.strings('verify_phone_screen.resend_success'));
        })
        
    }


    renderEnroll(){
       
        return (
            <div className="container">
                <div className="form col-half ">
                    <h1>Enroll Today!</h1>
                    <p>Enter your phone number to join our loyalty program. </p>
                    <div className="input full-size">
                        <TextField 
                            name="phone"
                            placeholder="Phone Number" 
                            defaultValue={this.state.initial.phone}
                            onChange={(e) => this.onTextChanged(e, 'phone')} 
                            onEnterPress={()=>this.onEnrollPressed()}
                            errorMessage={this.state.phoneErrorMessage} />
                    </div>
                    <div className="row">
                        <a href="javascript://" onClick={()=>this.onEnrollPressed()} className="btn btn-inline btn-primary "><span>Send Code</span></a><span>&nbsp;&nbsp;A code will be texted to your phone to verify your phone number.</span>

                    </div>   
                </div>
                
            </div>
        )
    }
    
    renderCodeVerify(){
        return (
            <div className="container">
                <div className="form col-half ">
                    <h1>Thank you!</h1>
                    <p>We just sent you a text.   Please put the code in below to verify your phone number. </p>
                    <div className="input full-size">
                        <TextField 
                            name="code"
                            placeholder="Code" 
                            
                            defaultValue={this.state.initial.code}
                            onChange={(e) => this.onTextChanged(e, 'code')} 
                            onEnterPress={()=>this.onVerifyCodePressed()}
                            errorMessage={this.state.codeErrorMessage} />
                    </div>
                    <div className="row">
                        <a href="javascript://" onClick={()=>this.onVerifyCodePressed()} className="btn btn-inline btn-primary "><span>Enroll</span></a><span>&nbsp;&nbsp;Didn't receive a code?. &nbsp;&nbsp;<a href="javascript://" onClick={()=>this.onEnrollPressed()} > Send a new one</a></span>

                    </div>   
                </div>
            </div>
        )
    }
    renderDisplay(){
        var subTitleModel = {
            count: this.props.balance,
            singular: this.props.terminology.one,
            plural: this.props.terminology.other            
        };
        return (
            <div className="container">
                <h1>{Internationalization.strings('rewards_screen.title')}</h1>
                <p>{Internationalization.strings('rewards_screen.sub_title', subTitleModel)}.</p>
                <div className="container">
                        <div className="rewards-list">
                            {this.props.rewards.length == 0 && <h2 className="sub-heading">You don't have any rewards.</h2>}
 
                            {this.renderRewards()}
                        </div>
                </div>
            </div>
        )
    }

    renderPartial() {
        
        switch (this.state.mode) {
            case "enroll":
                return this.renderEnroll();    
                break;
            case "verify":
                    return this.renderCodeVerify();    
                    break;
            case "display":
                return this.renderDisplay();    
                break;
            default:
                return this.renderEnroll();      
                break;
        }
    }

    render(){
        return(
            <div className="my-acct-my-rewards">
            <AccountHeader />
            <CouponsSubNav/>
            <div className="body my-account-pages">
              {this.renderPartial()}
            </div>        
        </div>
        );
    }
    addRewardToCart(reward) {
       
        if (!this.hasPointsForReward(reward)) {
            return;
        }
        this.props.addReward(reward);
       
        this.props.toast.success(Internationalization.strings('rewards_screen.reward_added_to_cart'));
    }

    getRewardDescription(reward) {
        var result = RewardHelper.getRewardTranslationModel(reward, this.props.currencyCode, this.props.categoriesById, this.props.variationsById, this.props.itemsById);
        return Internationalization.strings('reward_notes.' + result.key, result.model);
    }

    getRewardNote(reward) {
        var model = {
            count: reward.Points,
            singular: this.props.terminology.one,
            plural: this.props.terminology.other
        };

        return Internationalization.strings('rewards_screen.cost', model);
    }
    
    hasPointsForReward(reward) {
        var required = reward.Points;
        var balance = this.props.balance;
        if (balance >= required) {
            return true;
        }
        return false;
    }

    renderReward(reward) {
        var name = reward.Name;
        var points = reward.Points;
        var description=this.getRewardDescription(reward);
        var notes=this.getRewardNote(reward);
        var imageClasses = ['fas','fa-5x', 'fa-fw'];
        if (reward.Percentage) {
            imageClasses.push('fa-percent');
        } else {
            imageClasses.push('fa-tags');
        }
        
        return (
            <div className="row" key={reward.Id}>
                <div className="title-info">
                    <h3><a href="javascript://" onClick={() => this.addRewardToCart(reward)}>{name}</a></h3>
                    <div className="description">{description}</div>  
                    <div className="notes">{notes}</div>  
                    
                </div>
                <div>
                   {this.hasPointsForReward(reward) && <a href="javascript://" onClick={() => this.addRewardToCart(reward)} className="btn btn-inline btn-primary add-to-bag-btn"><span>Redeem</span></a>}
                </div>
            </div>
        );
    }

    renderRewards() {
        var rewards = [];
        for(var i=0;i<this.props.rewards.length;i++) {
            var reward = this.props.rewards[i];
            rewards.push(this.renderReward(reward));
        }        
        return rewards;
    }
}

const mapStateToProps = (state) => {


    return {
        rewards: state.loyalty.rewards,
        terminology: state.loyalty.terminology,
        balance: state.loyalty.balance,
        hasCoupons: state.loyalty.coupons && state.loyalty.coupons.length > 0,
        location: state.location.currentLocation,
        currencyCode: state.location.currentLocation.Currency,
        categoriesById: state.catalog.categoriesById,
        itemsById: state.catalog.itemsById,
        variationsById: state.catalog.variationsById,

        isVerified: state.loyalty.enrolled||false,
    }
}; 

const mapDispatchToProps = (dispatch) => ({
    addReward: (reward) => {
        dispatch({type:'CART_REWARD_ADD', reward: reward});
    },
    verify: (phone, code) => {
        return new Promise((resolve, reject) => {
            var model = {
                phoneNumber: phone,
                code: code
            };
            ApiService.instance.loyaltyVerify(model, (error, data, response) => {
                if (error) {
                    if (response && response.status && response.status == 409) {
                        ApiService.instance.loyaltyStatus({}, (statusError, statusData, statusResponse) => {
                            dispatch({type: 'LOYALTY_INITIALIZED', data: statusData });
                            reject(409);
                        });
                        return;
                    } else {
                        reject(error);
                        return;
                    }
                }

                dispatch({type:'LOYALTY_VERIFIED', data: data});
                resolve(data);
            });
        });
    },
});
  

export default connect(mapStateToProps, mapDispatchToProps)(RewardsScreen);