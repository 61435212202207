import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import async from 'async';
import { Hero, EmailField, TextField, PasswordField, PhoneField, Checkbox } from '../components';
import { Validation, ApiService, CardService, InventoryHubService } from '../lib';
import { LocationHelper } from '../services';

const RegisterValidationRules = [
    {
      input: 'firstName',
      output: 'firstNameErrorMessage',
      stopOnError: true,
      rules: [
        {
          rule: Validation.rules.notEmpty,
          message: 'First Name is required'
        }      
      ]
    },
    {
      input: 'lastName',
      output: 'lastNameErrorMessage',
      stopOnError: true,
      rules: [
        {
          rule: Validation.rules.notEmpty,
          message: 'Last Name is required'
        }      
      ]
    },
    {
      input: 'email',
      output: 'emailErrorMessage',
      stopOnError: true,
      rules: [
        {
          rule: Validation.rules.notEmpty,
          message: 'Email Address is required'
        },
        {
          rule: Validation.rules.email,
          message: 'Email Address is not valid'
        }
      ]
    },
    {
      input: 'phoneNumber',
      output: 'phoneNumberErrorMessage',
      stopOnError: true,
      rules: [
        {
          rule: Validation.rules.notEmpty,
          message: 'Phone Number is required'
        },
        {
          rule: Validation.rules.phoneNumber,
          message: 'Phone Number is not valid'
        }      
      ]
    },    
    {
      input: 'password',
      output: 'passwordErrorMessage',
      stopOnError: true,
      rules: [
        {
          rule: Validation.rules.notEmpty,
          message: 'Password is required'
        }      
      ]
    },
    {
      input: 'passwordConfirmation',
      output: 'passwordConfirmationErrorMessage',
      stopOnError: true,
      rules: [
        {
          rule: Validation.rules.notEmpty,
          message: 'Password Confirmation is required'
        },
        {
          rule: Validation.rules.equalsOtherInput,
          message: 'Password Confirmation must match Password',
          other: 'password'
        }
      ]
    },
  ];  

const VerifyValidationRules = [
  {
      input: 'code',
      output: 'codeErrorMessage',
      stopOnError: true,
      rules: [
          {
              rule: Validation.rules.notEmpty,
              message: 'Code is required'
          }
      ]
  }
];

class SignUpScreen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            firstName: null,
            lastName: null,
            email: null,
            phoneNumber: null,
            password: null,
            passwordConfirmation: null,
            textNotificationOptIn: true,
            code: null,
            firstNameErrorMessage: null,            
            lastNameErrorMessage: null,            
            emailErrorMessage: null,
            phoneNumberErrorMessage: null,
            passwordErrorMessage: null,
            passwordConfirmationErrorMessage: null,
            codeErrorMessage: null,
            registerPending: false,
            verifyPending: false,
            loginPending: false,
            step: 'register'
        };
    }

    onTextChanged(e, fieldName) {
        this.setState({[fieldName]: e.target.value});
    }

    onRegisterPressed() {
        var isValid = Validation.isValid(this.state, RegisterValidationRules, (newState) => this.setState(newState));
        if (!isValid) {
            return;
        }

        this.setState({registerPending: true});

        var textNotificationOptIn = this.props.supportsTextNotifications ? this.state.textNotificationOptIn : null;

        var model = {
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            email: this.state.email,
            phoneNumber: this.state.phoneNumber,
            password: this.state.password,
            confirmPassword: this.state.passwordConfirmation,
            textNotificationOptIn: textNotificationOptIn,
            skipVerify: false
        };

        this.props.register(model)
            .then(() => {
              this.setState({registerPending: false, step: 'verify'});
            })
            .catch((reason) => {
                this.setState({registerPending: false});
                this.props.toast.error(reason);
            });
    }

    onResendCodePressed() {
      this.props.resendCode(this.state.email)
        .then((msg) => {
          this.props.toast.success(msg);
        }).catch((msg) => {
          this.props.toast.error(msg);
        });
    }

    onVerifyAccountPressed() {
      var isValid = Validation.isValid(this.state, VerifyValidationRules, (newState) => this.setState(newState));
      if (!isValid) {
          return;
      }
  
      this.setState({verifyPending: true});
  
      this.props.verify(this.state.email, this.state.code)
        .then(() => {
          this.setState({verifyPending: false, step: 'login'});
          this.completeWithLogin();
        })
        .catch((reason) => {
          this.setState({verifyPending: false});
          this.props.toast.error(reason);
        });
    }

    completeWithLogin() {
      this.setState({loginPending: true});
      this.props.login(this.state.email, this.state.password, this.props.localFavorites, this.props.savedLocationId, LocationHelper.instance.applyLocationIncludeExcludeRules)
        .then(() => {
            this.setState({loginPending: false});      
            this.props.history.push('/my-account');
        })
        .catch((reason) => {
            this.setState({loginPending: false});
            this.props.toast.error(reason);
        });      
    }

    render() {
        return (
            <div className="account">
                <Hero message="Welcome back, please login." />
                <div className="body">
                    <div className="container">
                        {this.state.step == 'register' && !this.state.registerPending && this.renderRegisterForm()}
                        {this.state.step == 'verify' && !this.state.verifyPending && this.renderVerifyForm()}
                        {(this.state.registerPending || this.state.verifyPending || this.state.loginPending) && this.renderPending()}
                    </div>
                </div>
            </div>
        );
    }

    renderVerifyForm() {
        return (
        <div>
            <div className="col-half center-block">
                <div className="account-status-buttons tabs">
                    <Link to='/sign-in' id="tab1">Already a customer</Link>
                    <a href="javascript://" id="tab2" className="active">I'm a new customer</a>
                </div>
            </div>
            <div id="tab2_block" className="form col-half center-block new-customer-form tab-block active">
                <div className="input full-size">
                  <TextField 
                    name="code"
                    placeholder="Code"
                    onChange={(e) => this.onTextChanged(e, 'code')} 
                    onEnterPress={()=>this.onVerifyAccountPressed()}
                    errorMessage={this.state.codeErrorMessage} />
                    <div className="additional right">Didn't get it? <a href="javascript://" onClick={() => this.onResendCodePressed()}>Resend Code</a></div>
                </div>
                <div className="button full-size">
                    <button className="btn btn-primary" onClick={() => this.onVerifyAccountPressed()}>Verify Account</button>
                </div>
            </div>
        </div>
        );
    }

    renderRegisterForm() {

      var textNotificationOptInField = null;
      if (this.props.supportsTextNotifications) {
        textNotificationOptInField = (
          <div className="input full-size">
            <Checkbox label="Send me order updates via text message" 
              value={true} 
              checked={this.state.textNotificationOptIn} 
              onEnterPress={() => this.onRegisterPressed()}
              onCheck={(value) => { this.setState({textNotificationOptIn: value}) }} />
          </div>
        );
      }

      return (
      <div>
          <div className="col-half center-block">
              <div className="account-status-buttons tabs">
                  <Link to='/sign-in' id="tab1">Already a customer</Link>
                  <a href="javascript://" id="tab2" className="active">I'm a new customer</a>
              </div>
          </div>
          <div id="tab2_block" className="form col-half center-block new-customer-form tab-block active">
              <div className="input full-size">
                  <TextField 
                      name="firstName"
                      placeholder="First Name" 
                      onChange={(e) => this.onTextChanged(e, 'firstName')} 
                      onEnterPress={() => this.onRegisterPressed()}
                      errorMessage={this.state.firstNameErrorMessage} />
              </div>
              <div className="input full-size">
                  <TextField 
                      name="lastName"
                      placeholder="Last Name" 
                      onChange={(e) => this.onTextChanged(e, 'lastName')} 
                      onEnterPress={() => this.onRegisterPressed()}
                      errorMessage={this.state.lastNameErrorMessage} />
              </div>
              <div className="input full-size">
                  <EmailField 
                      name="email"
                      placeholder="Email Address" 
                      onChange={(e) => this.onTextChanged(e, 'email')} 
                      onEnterPress={() => this.onRegisterPressed()}
                      errorMessage={this.state.emailErrorMessage} />
              </div>
              <div className="input full-size">
                  <PhoneField 
                      name="phoneNumber"
                      placeholder="Phone Number" 
                      onChange={(e) => this.onTextChanged(e, 'phoneNumber')} 
                      onEnterPress={() => this.onRegisterPressed()}
                      errorMessage={this.state.phoneNumberErrorMessage} 
                      underMessage="(in case we need to reach you about your order)"
                      />
              </div>
              <div className="input full-size">
                  <PasswordField 
                      name="password"
                      placeholder="Password" 
                      onChange={(e) => this.onTextChanged(e, 'password')} 
                      onEnterPress={() => this.onRegisterPressed()}
                      errorMessage={this.state.passwordErrorMessage} />
              </div>
              <div className="input full-size">
                  <PasswordField 
                      name="passwordConfirm"
                      placeholder="Confirm Password"
                      onChange={(e) => this.onTextChanged(e, 'passwordConfirmation')}
                      onEnterPress={() => this.onRegisterPressed()}
                      errorMessage={this.state.passwordConfirmationErrorMessage} />
              </div>
              {textNotificationOptInField}
              <div className="button full-size">
                  <button className="btn btn-primary" onClick={() => this.onRegisterPressed()}>Create Account</button>
              </div>
          </div>
      </div>
      );
  }

    renderPending() {
        return (
            <div className="form col-half center-block text-center">
                <i className="fas fa-spinner fa-pulse fa-5x"></i>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        supportsTextNotifications: state.catalog.data.HasTextNotifications,
        localFavorites: state.favorites.items,
        savedLocationId: state.location.selectedLocationId
    }
  };
  

const mapDispatchToProps = (dispatch) => ({
    register: (model) => {
      return new Promise((resolve, reject) => {
        ApiService.instance.accountRegister(model, (error, data, response) => {
          if (error) {
            var message = 'Unable to complete registration!';
            if (response && response.body && response.body.length && response.body[0].Errors && response.body[0].Errors.length) {
              message = response.body[0].Errors[0];
            }
            reject(message);
            return;
          }
          resolve(data);
        });
      });
    },

    resendCode: (email) => {
      return new Promise((resolve, reject) => {
        ApiService.instance.accountResendRegisterCode({
          email: email,
        }, (error, data, response) => {
            if (error) {
                reject("Unable to resend code!");
                return;
            }
            resolve("Code was sent!");
        });
      });
    },
  
    verify: (email, code) => {
      return new Promise((resolve, reject) => {
        ApiService.instance.accountVerify({
          email: email,
          code: code
        }, (error, data, response) => {
            if (error) {
                var message = "Unable to verify account";
                if (response && response.body && response.body.length && response.body[0].Errors && response.body[0].Errors.length) {
                  message = response.body[0].Errors[0];
                }
                reject(message);
                return;
            }
            resolve("Account verified!");
        });
      });
    },
  
    login: (username, password, localFavorites, savedLocationId, applyLocationIncludeExcludeRulesCallback) => {
        dispatch({ type: 'AUTH_LOGIN_PENDING' });
        return new Promise((resolve, reject) => {
            ApiService.instance.login({
                Username: username,
                Password: password
            }, (error, data, response) => {
                if (error) {
                    dispatch({type: 'AUTH_LOGIN_COMPLETED', isLoggedIn: false });
                    reject('Invalid email address or password!');
                } else {
                    async.parallel({
                        authStatus: (cb) => ApiService.instance.accountStatus({}, (e, data, response) => {
                          dispatch({type: 'AUTH_STATUS', data: data });
                          cb(null, true);
                        }),                          
                        orders: (cb) => ApiService.instance.orderList({}, (e, data, response)=> {
                            dispatch({type: 'ORDERS_INITIALIZED', data: data });
                            cb(null, true);
                        }),                        
                        catalog: (cb) => ApiService.instance.catalogIndex({}, (e, data, response) => {
                          data.Locations = applyLocationIncludeExcludeRulesCallback(data.Locations);
                          dispatch({ type: 'CATALOG_INITIALIZED', data: data });
                          dispatch({ type: 'LOCATION_INITIALIZED', locations: data.Locations });
                          var locationToSelect = null;
  
                          if (data.Locations.length == 1) {
                              // auto select the only location
                              locationToSelect = data.Locations[0];
                          } else if (savedLocationId && data.Locations.length > 1) {
                              for(var i=0;i<data.Locations.length;i++) {
                                  var possibleLocation = data.Locations[i];
                                  if (possibleLocation.Id == savedLocationId) {
                                      locationToSelect = possibleLocation;
                                      break;
                                  }
                              }
                          }
  
                          if (locationToSelect) {
                              dispatch({ type: 'LOCATION_SELECTED', location: locationToSelect });
                              InventoryHubService.instance.locationChanged(locationToSelect.Id, () => {
                                  cb(null, true);
                              });
                          } else {
                              cb(null, true);
                          }
                        }),                                               
                        /*
                        mergeFavorites: (cb) => ApiService.instance.favoriteMerge({items: localFavorites}, (e, data, response) => {
                            dispatch({type: 'FAVORITES_INITIALIZED', data: data });
                            cb(null, true);
                        }),
                        */
                        loyalty: (cb) => ApiService.instance.loyaltyStatus({}, (e, data, response) => {
                            dispatch({type: 'LOYALTY_INITIALIZED', data: data })
                            cb(null, true);
                        }),
                        cards: (cb) => CardService.instance.mergeInit((data, defaultCard) => {
                          dispatch({type: 'CARDS_INITIALIZED', cards: [], defaultCard: defaultCard, remote: data });
                          cb(null, true);
                        }),
                    }, (err, results) => {
                        dispatch({type: 'AUTH_LOGIN_COMPLETED', isLoggedIn: true });
                        resolve(true);
                    });
                }
            });
        });    
    }
  });
  

export default connect(mapStateToProps, mapDispatchToProps)(SignUpScreen);