var _privateKey;

export default class CustomStorage {
    static setPrivateKey(privateKey) {
        _privateKey = privateKey;
    }

    static get(key) {
        return global.storage.unsecure
            .getItem(key)
            .then((response) => { 
                if (response)
                    return JSON.parse(response); 
                return null;
            });
    }

    static set(key, value) {
        global.storage.unsecure
            .setItem(key, JSON.stringify(value));
    }

    static getPrivate(key) {
        if (!_privateKey)
            throw 'Invalid Storage Private Key';

        var fullKey = _privateKey + '_' + key;
        return new Promise((resolve, reject) => {
            global.storage.secure
            .get(fullKey)
            .then((response) => {
                if (response)
                {
                    resolve(JSON.parse(response));
                } else {
                    resolve(null);
                }
            }, (err) => {
                resolve(null)
            });
        });
    }

    static setPrivate(key, value) {
        if (!_privateKey)
            throw 'Invalid Storage Private Key';        
        var fullKey = _privateKey  + '_' + key;
        global.storage.secure.set(fullKey, JSON.stringify(value));
    }
}
