/**
 * PoppinPay API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import OrderResultModel from './OrderResultModel';

/**
* The OrderListResultModel model module.
* @module model/OrderListResultModel
* @version 1.0.0
*/
export default class OrderListResultModel {
    /**
    * Constructs a new <code>OrderListResultModel</code>.
    * @alias module:model/OrderListResultModel
    * @class
    */

    constructor() {
        
        
        
    }

    /**
    * Constructs a <code>OrderListResultModel</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/OrderListResultModel} obj Optional instance to populate.
    * @return {module:model/OrderListResultModel} The populated <code>OrderListResultModel</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new OrderListResultModel();
                        
            
            if (data.hasOwnProperty('Orders')) {
                obj['Orders'] = ApiClient.convertToType(data['Orders'], [OrderResultModel]);
            }
        }
        return obj;
    }

    /**
    * @member {Array.<module:model/OrderResultModel>} Orders
    */
    'Orders' = undefined;




}
