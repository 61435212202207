import i18n from 'i18n-js';
import moment from 'moment';
import {createIntl, createIntlCache} from 'react-intl';

// Should the app fallback to English if user locale doesn't exists
i18n.fallbacks = true;

// Define the supported translations
i18n.translations = global.translations;

const fullLocale = global.language;

const languageLocale = fullLocale.indexOf("-") >= 0 ? fullLocale.substr(0, fullLocale.indexOf("-")) : fullLocale;

i18n.locale = fullLocale;

moment.locale(languageLocale);

i18n.missingTranslation = function (scope, options) { return scope; };

const intlCache = createIntlCache();
const intl = createIntl({ locale: languageLocale, defaultLocale: 'en' }, intlCache);

export default class Internationalization {
  static strings(name, params = {}) {
    return i18n.t(name, params);
  }

  static formatNumber(value, options = {}) {
    return intl.formatNumber(value, options);
  }

  static getCurrentLanguageCode() {
    return languageLocale;
  }

  static getNativePhoneMask(countryCode) {
    // US, Canada, Japan, Australia, and the United Kingdom
    var map = {
      'US': '[000]{-}[000]{-}[0000]',
      'CA': '[000]{-}[000]{-}[0000]',
      'JP': '[00]{-}[0000]{-}[0000]',
      'AU': '[0000]{ }[000]{ }[000]',
      'GB': '[000]{ }[0000]{ }[0000]'
    };

    return map[countryCode] || null;
  }
}