import React from 'react';
import { connect } from 'react-redux';
import { Redirect, NavLink, withRouter } from 'react-router-dom';
import { AccountHeader } from '.';
import { Internationalization, CurrencyFormatter } from '../lib';
class CouponsSubNav extends React.Component {
    render() {
        return (
                <div className="container">
                    <div className="my-account-sub-menu">
                        {this.props.hasLoyalty && (<div><NavLink to="/my-rewards" activeClassName='active'><span>Rewards</span></NavLink></div>)}
                        <div><NavLink to="/my-coupons" activeClassName='active'><span>Coupons</span></NavLink></div>
                        
                    </div>
                </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        hasLoyalty: state.loyalty.available,
    };
};

const mapDispatchToProps = (dispatch) => ({
    
});

export default connect(mapStateToProps, mapDispatchToProps)(CouponsSubNav);

