import { ConfigService } from '../lib';

export default class LocationHelper {

    static instance:LocationHelper;
    
    applyLocationIncludeExcludeRules(locations:any) {
        var config:any = ConfigService.get();

        var includeLocations = config.IncludeLocations;
        var excludeLocations = config.ExcludeLocations;

        var useInclude = includeLocations && includeLocations.length > 0;
        var useExclude = excludeLocations && excludeLocations.length > 0;
        if (!useInclude && !useExclude) {
            return locations;
        }

        if (useInclude) {
            includeLocations = includeLocations.map((v:string) => v.toLowerCase());
        }
        if (useExclude) {
            excludeLocations = excludeLocations.map((v:string) => v.toLowerCase());
        }

        var finalLocations = [];
        for(var i=0;i<locations.length;i++) {
            var location = locations[i];

            var id = location.Id.toLowerCase();

            var keepInclude = useInclude && includeLocations.indexOf(id) >= 0;
            var keepExclude = useExclude && !(excludeLocations.indexOf(id) >= 0);
            if (keepInclude || keepExclude) {
                finalLocations.push(location);
            }
        }
        return finalLocations;
    }   
}

LocationHelper.instance = new LocationHelper();
