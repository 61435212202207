import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

class CategorySlide extends React.Component {
    onClick() {
        this.props.select(this.props.category);
    }

    renderLink() {
        var category = this.props.category;

        var url = '/categories/' + category.Id;        
        return (<div key={category.Id} className={this.props.selected ? 'active-category':''}>
            <Link onClick={() => this.onClick()} to={url}><span>{category.Name}</span></Link>
        </div>);
    }

    renderScroll() {
        var category = this.props.category;

        var url = '#category' + category.Id;
        return (<div key={category.Id} className={this.props.selected ? 'active-category':''}>
            <a onClick={() => this.onClick()} href={url}><span>{category.Name}</span></a>
        </div>);
    }    
    
    render() {
        var mode = this.props.mode || "link";
        if (mode == "link") {
            return this.renderLink();
        }
        return this.renderScroll();
    }
}

const mapStateToProps = (state) => {
    return {

    };
};

const mapDispatchToProps = (dispatch) => ({
    select: (category) => {
        dispatch({type: 'CATALOG_SELECT_CATEGORY', category: category});
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(CategorySlide);