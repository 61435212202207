import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Validation, ApiService, CardService, Internationalization, CustomStorage, InventoryHubService } from '../lib';
import async from 'async';
import { Hero, EmailField, PhoneField, PasswordField, TextField, Checkbox } from '../components';
import { LocationHelper } from '../services';


const FirstNameValidationRule = {
    input: 'firstName',
    output: 'firstNameErrorMessage',
    stopOnError: true,
    rules: [
        {
            rule: Validation.rules.notEmpty,
            message: 'First Name is required'
        }      
    ]
};

const LastNameValidationRule = {
    input: 'lastName',
    output: 'lastNameErrorMessage',
    stopOnError: true,
    rules: [
        {
            rule: Validation.rules.notEmpty,
            message: 'Last Name is required'
        }      
    ]
};

const PhoneValidationRule = {
    input: 'phone',
    output: 'phoneErrorMessage',
    stopOnError: true,
    rules: [
        {
            rule: Validation.rules.notEmpty,
            message: "Phone Number is required"
        },
        {
            rule: Validation.rules.phoneNumber,
            message: "Phone Number is not valid"
        }
    ]
};

const EmailValidationRule = {
    input: 'email',
    output: 'emailErrorMessage',
    stopOnError: true,
    rules: [
        {
            rule: Validation.rules.notEmpty,
            message: "Email Address is required"
        },
        {
            rule: Validation.rules.email,
            message: "Email Address is not valid"
        }
    ]
};

const PasswordValidationRule = {
    input: 'password',
    output: 'passwordErrorMessage',
    stopOnError: true,
    rules: [
        {
            rule: Validation.rules.notEmpty,
            message: "Password is required"
        }
    ]
};

const CodeValidationRule =   {
    input: 'code',
    output: 'codeErrorMessage',
    stopOnError: true,
    rules: [
        {
            rule: Validation.rules.notEmpty,
            message: 'Code is required'
        }
    ]
};

class SignInScreen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: null,
            emailErrorMessage: null,
            phone: null,
            phoneErrorMessage: null,
            password: null,
            passwordErrorMessage: null,
            loginPending: false,
            verifyPending: false,
            registerPending: false,
            step: 'login',

            firstName: null,
            lastName: null,
            textNotificationOptIn: true,
            firstNameErrorMessage: null,
            lastNameErrorMessage: null
        };
    }

    redirectAway() {
        CustomStorage.get('returnUrl').then((returnUrl) => {
            this.setState({loginPending: false, verifyPending: false, verifyPending: false});
            this.props.history.push(returnUrl || '/my-account');
        });
    }

    onLoginClicked() {
        var rules = null;
        if (this.props.usernameMode == 'Email' && this.props.passwordMode == 'UserDefined') {
            rules = [EmailValidationRule, PasswordValidationRule];
        } else if (this.props.usernameMode == 'Email' && this.props.passwordMode == 'OneTime') { 
            rules = [EmailValidationRule];
        } else if (this.props.usernameMode == 'Phone' && this.props.passwordMode == 'UserDefined') { 
            rules = [PhoneValidationRule, PasswordValidationRule];
        } else if (this.props.usernameMode == 'Phone' && this.props.passwordMode == 'OneTime') { 
            rules = [PhoneValidationRule];
        }

        var isValid = Validation.isValid(this.state, rules, (newState) => this.setState(newState));
        if (!isValid) {
            return;
        }
        
        this.setState({loginPending: true});

        if (this.props.passwordMode == 'UserDefined') {
            var username = this.props.usernameMode == 'Email' ? this.state.email : this.state.phone;
            this.props.login(username, this.state.password, this.props.localFavorites, this.props.savedLocationId, LocationHelper.instance.applyLocationIncludeExcludeRules)
            .then(() => {
                this.redirectAway();
            })
            .catch((reason) => {
                this.setState({loginPending: false});
                if (this.props.usernameMode == 'Email') {
                    this.props.toast.error('Invalid email address or password!');
                } else {
                    this.props.toast.error('Invalid phone number or password!');
                }
            });
        } else {
            if (this.props.usernameMode == 'Email') {
                this.props.sendEmailLoginCode(this.state.email)
                    .then((code) => {
                        if (code == 200) {
                            this.setState({loginPending: false, step: 'verify'});
                        } else if (code == 204) {
                            this.setState({loginPending: false, step: 'register'});
                        } else if (code == 206) {
                            this.setState({loginPending: false, step: 'register'});
                        }
                    })
                    .catch((code) => {
                        this.setState({loginPending: false});
                        this.props.toast.error('Error during email address login. Please try again.');
                    });
            } else {
                this.props.sendPhoneLoginCode(this.state.phone)
                .then((code) => {
                    if (code == 200) {
                        this.setState({loginPending: false, step: 'verify'});
                    } else if (code == 204) {
                        this.setState({loginPending: false, step: 'register'});
                    } else if (code == 206) {
                        this.setState({loginPending: false, step: 'register'});
                    }
                })
                .catch((reason) => {
                    this.setState({loginPending: false});
                    this.props.toast.error('Error during phone number login. Please try again.');
                });                    
            }
        }
    }

    onResendCodeClicked() {
        if (this.props.usernameMode == 'Email') {
            this.props.sendEmailLoginCode(this.state.email)
            .then(() => {
                this.props.toast.success('Code was sent!');    
            })
            .catch((reason) => {
                this.props.toast.error('Unable to resend code!');
            });
        } else {
            this.props.sendPhoneLoginCode(this.state.phone)
            .then(() => {
                this.props.toast.success('Code was sent!');
            })
            .catch((reason) => {
                this.props.toast.error('Unable to resend code!');
            });                    
        }
    }

    onChangePhoneNumberClicked() {
        this.setState({step: 'login'});
    }

    onRegisterPressed() {
        var rules = [ FirstNameValidationRule, LastNameValidationRule ];
        if (this.props.usernameMode == 'Email') {
            rules.push(PhoneValidationRule);
        } else {
            rules.push(EmailValidationRule);
        }
        var isValid = Validation.isValid(this.state, rules, (newState) => this.setState(newState));
        if (!isValid) {
            return;
        }

        this.setState({registerPending: true});

        var textNotificationOptIn = this.props.supportsTextNotifications ? this.state.textNotificationOptIn : null;

        var model = {
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            email: this.state.email,
            phoneNumber: this.state.phone,
            textNotificationOptIn: textNotificationOptIn,
            skipVerify: false
        };

        this.props.register(model)
            .then(() => {
              this.setState({registerPending: false, step: 'verify'});
            })
            .catch((reason) => {
                this.setState({registerPending: false});
                this.props.toast.error(reason);
            });
    }    

    onVerifyAccountClicked() {
        var rules = [CodeValidationRule];
        var isValid = Validation.isValid(this.state, rules, (newState) => this.setState(newState));
        if (!isValid) {
            return;
        }
        
        this.setState({verifyPending: true});

        var username = this.props.usernameMode == 'Email' ? this.state.email : this.state.phone;
        this.props.login(username, this.state.code, this.props.localFavorites, this.props.savedLocationId, LocationHelper.instance.applyLocationIncludeExcludeRules)
        .then(() => {
            this.redirectAway();
        })
        .catch((reason) => {
            this.setState({verifyPending: false});
            if (this.props.usernameMode == 'Email') {
                this.props.toast.error('Invalid email address or code!');
            } else {
                this.props.toast.error('Invalid phone number or code!');
            }
        });
    }

    onEmailTextChanged(e) {
        this.setState({email: e.target.value});
    }

    onPhoneTextChanged(e) {
        this.setState({phone: e.target.value});
    }    

    onPasswordTextChanged(e) {
        this.setState({password: e.target.value});
    }

    onCodeTextChanged(e) {
        this.setState({code: e.target.value});
    }

    onTextChanged(e, fieldName) {
        this.setState({[fieldName]: e.target.value});
    }    

    renderRegisterForm() {
        var textNotificationOptInField = null;
        if (this.props.supportsTextNotifications) {
            textNotificationOptInField = (
                <div className="input full-size">
                    <Checkbox label="Send me order updates via text message" 
                        value={true} 
                        checked={this.state.textNotificationOptIn} 
                        onEnterPress={() => this.onRegisterPressed()}
                        onCheck={(value) => { this.setState({textNotificationOptIn: value}) }} />
                </div>
            );
        }

        return (
            <div>
                <div className="col-half center-block">
                </div>
                <div id="tab2_block" className="form col-half center-block new-customer-form tab-block active">
                    <h3>
                        Hmm... looks like we can't find an account for you. Let's get one set up!
                    </h3>
                    <div className="input full-size">
                        <TextField 
                            name="firstName"
                            placeholder="First Name" 
                            onChange={(e) => this.onTextChanged(e, 'firstName')} 
                            onEnterPress={() => this.onRegisterPressed()}
                            errorMessage={this.state.firstNameErrorMessage} />
                    </div>
                    <div className="input full-size">
                        <TextField 
                            name="lastName"
                            placeholder="Last Name" 
                            onChange={(e) => this.onTextChanged(e, 'lastName')} 
                            onEnterPress={() => this.onRegisterPressed()}
                            errorMessage={this.state.lastNameErrorMessage} />
                    </div>
        {this.props.usernameMode == 'Phone' && 
                    <div className="input full-size">
                        <EmailField 
                            name="email"
                            placeholder="Email Address" 
                            onChange={(e) => this.onTextChanged(e, 'email')} 
                            onEnterPress={() => this.onRegisterPressed()}
                            errorMessage={this.state.emailErrorMessage} />
                    </div>
        }
        {this.props.usernameMode == 'Email' && 
                    <div className="input full-size">
                        <PhoneField 
                            name="phone"
                            placeholder="Phone Number" 
                            onChange={(e) => this.onTextChanged(e, 'phone')} 
                            onEnterPress={() => this.onRegisterPressed()}
                            errorMessage={this.state.phoneErrorMessage} 
                            underMessage="(in case we need to reach you about your order)"
                        />
                    </div>
        }
        {textNotificationOptInField}
                    <div className="button full-size">
                        <button className="btn btn-primary" onClick={() => this.onRegisterPressed()}>Create Account</button>
                    </div>
                </div>
            </div>
        );
    }

    renderVerifyForm() {
        var changeUsernameLabel = 'Change Email';
        if (this.props.usernameMode == 'Phone') {
            changeUsernameLabel = 'Change Phone Number';
        }
        
        return (
        <div>
            <div className="col-half center-block">
            </div>
            <div id="tab2_block" className="form col-half center-block new-customer-form tab-block active">
                <h3>
                    Almost there! Just give us the code we sent and you'll be good to go!
                </h3>
                <div className="input full-size">
                  <TextField 
                    name="code"
                    placeholder="Code"
                    onChange={(e) => this.onCodeTextChanged(e)} 
                    onEnterPress={()=>this.onVerifyAccountClicked()}
                    errorMessage={this.state.codeErrorMessage} />
                    <div className="additional right">
                    Didn't get it? <a href="javascript://" onClick={() => this.onResendCodeClicked()}>Resend Code</a> or <a href="javascript://" onClick={() => this.onChangePhoneNumberClicked()}>{changeUsernameLabel}</a>
                    </div>
                </div>
                <div className="button full-size">
                    <button className="btn btn-primary" onClick={() => this.onVerifyAccountClicked()}>Verify Code</button>
                </div>
            </div>
        </div>
        );
    }

    render() {
        return (
            <div className="account">
                <Hero message="Welcome back, please login." />
                <div className="body">
                    <div className="container">
                        {this.state.step == 'login' && !this.state.loginPending && this.renderLoginForm()}
                        {this.state.step == 'register' && !this.state.registerPending && this.renderRegisterForm()}
                        {this.state.step == 'verify' && !this.state.verifyPending && this.renderVerifyForm()}
                        {(this.state.loginPending || this.state.verifyPending || this.state.registerPending) && this.renderPending()}                    
                    </div>
                </div>
            </div>
        );
    }

    renderEmailField() {
        if (this.props.usernameMode != 'Email') {
            return null;
        }

        return (
        <div className="input full-size">
            <EmailField 
                name="email"
                placeholder="Email Address" 
                onChange={(e) => this.onEmailTextChanged(e)} 
                onEnterPress={() => this.onLoginClicked()}
                errorMessage={this.state.emailErrorMessage} />
        </div>
        );
    }

    renderPhoneField() {
        if (this.props.usernameMode != 'Phone') {
            return null;
        }

        return (
        <div className="input full-size">
            <PhoneField 
                name="phone"
                placeholder="Phone Number" 
                onChange={(e) => this.onPhoneTextChanged(e)} 
                onEnterPress={() => this.onLoginClicked()}
                errorMessage={this.state.phoneErrorMessage} />
        </div>
        );
    }    

    renderPasswordField() {
        if (this.props.passwordMode != 'UserDefined') {
            return null;
        }

        return (
        <div className="input full-size">
            <PasswordField 
                name="password"
                placeholder="Password"
                onChange={(e) => this.onPasswordTextChanged(e)}
                onEnterPress={() => this.onLoginClicked()}
                errorMessage={this.state.passwordErrorMessage} />
            <div className="additional right"><Link to="/forgot-password">Forgot Password?</Link></div>
        </div>
        );
    }

    renderLoginHeader() {
        if (this.props.passwordMode == 'OneTime') {
            return null;
        } else {
            return (
                <div className="account-status-buttons tabs">
                    <a href="javascript://" id="tab1" className="active">Already a customer</a>
                    <Link to='/sign-up' id="tab2">I'm a new customer</Link>
                </div>
            );
        }
    }

    renderFormHeading() {
        if (this.props.passwordMode == 'OneTime') {
            var usernameLabel = this.props.usernameMode == 'Email' ? 'email address' : 'phone number';
            return (
                <h3>
                    Wether you're a returning customer or a new customer, we'll get you on your way.  Let's start with your {usernameLabel}.
                </h3>
            )
        }
        return null;
    }

    renderLoginForm() {
        return (
            <div>
                <div className="col-half center-block">
                    {this.renderLoginHeader()}
                </div>
                <div id="tab1_block" className="form col-half center-block current-customer-form tab-block active">
                    {this.renderFormHeading()}
                    {this.renderEmailField()}
                    {this.renderPhoneField()}
                    {this.renderPasswordField()}
                    <div className="button full-size">
                        <button className="btn btn-primary" onClick={()=>this.onLoginClicked()}>Continue</button>
                    </div>
                </div>
            </div>
        );
    }

    renderPending() {
        return (
            <div className="form col-half center-block text-center">
                <i className="fas fa-spinner fa-pulse fa-5x"></i>
            </div>
        );
    }    
}

const mapStateToProps = (state) => {
    return {
        supportsTextNotifications: state.catalog.data.HasTextNotifications,
        localFavorites: state.favorites.items,
        savedLocationId: state.location.selectedLocationId,
        usernameMode: state.catalog.data.UsernameMode,
        passwordMode: state.catalog.data.PasswordMode
    }
};

const mapDispatchToProps = (dispatch) => ({
    sendEmailLoginCode: (email) => {
        return new Promise((resolve, reject) => {
            ApiService.instance.accountSendLoginCode({
                email: email
            }, (error, data, response) => {
                if (response && response.status >= 200 && response.status < 300) {
                    resolve(response.status);
                } else if (response) {
                    reject(response.status);
                } else {
                    reject(0);
                }
            });
        });
    },
    sendPhoneLoginCode: (phone) => {
        return new Promise((resolve, reject) => {
            ApiService.instance.accountSendLoginCode({
                phoneNumber: phone
            }, (error, data, response) => {
                if (response && response.status >= 200 && response.status < 300) {
                    resolve(response.status);
                } else if (response) {
                    reject(response.status);
                } else {
                    reject(0);
                }
            });
        });
    },
    register: (model) => {
        return new Promise((resolve, reject) => {
          ApiService.instance.accountRegister(model, (error, data, response) => {
            if (error) {
              var message = 'Unable to complete registration!';
              if (response && response.body && response.body.length && response.body[0].Errors && response.body[0].Errors.length) {
                message = response.body[0].Errors[0];
              }
              reject(message);
              return;
            }
            resolve(data);
          });
        });
      },    
    login: (username, password, localFavorites, savedLocationId, applyLocationIncludeExcludeRulesCallback) => {
        dispatch({ type: 'AUTH_LOGIN_PENDING' });
        return new Promise((resolve, reject) => {
            ApiService.instance.login({
                Username: username,
                Password: password
            }, (error, data, response) => {
                if (error) {
                    dispatch({type: 'AUTH_LOGIN_COMPLETED', isLoggedIn: false });
                    reject("Invalid email address or password!");
                } else {
                    async.parallel({
                        authStatus: (cb) => ApiService.instance.accountStatus({}, (e, data, response) => {
                            dispatch({type: 'AUTH_STATUS', data: data });
                            cb(null, true);
                        }),                        
                        orders: (cb) => ApiService.instance.orderList({}, (e, data, response)=> {
                            dispatch({type: 'ORDERS_INITIALIZED', data: data });
                            cb(null, true);
                        }),
                        catalog: (cb) => ApiService.instance.catalogIndex({}, (e, data, response) => {
                            data.Locations = applyLocationIncludeExcludeRulesCallback(data.Locations);
                            dispatch({ type: 'CATALOG_INITIALIZED', data: data });
                            dispatch({ type: 'LOCATION_INITIALIZED', locations: data.Locations });
                            var locationToSelect = null;

                            if (data.Locations.length == 1) {
                                // auto select the only location
                                locationToSelect = data.Locations[0];
                            } else if (savedLocationId && data.Locations.length > 1) {
                                for(var i=0;i<data.Locations.length;i++) {
                                    var possibleLocation = data.Locations[i];
                                    if (possibleLocation.Id == savedLocationId) {
                                        locationToSelect = possibleLocation;
                                        break;
                                    }
                                }
                            }
    
                            if (locationToSelect) {
                                dispatch({ type: 'LOCATION_SELECTED', location: locationToSelect });
                                InventoryHubService.instance.locationChanged(locationToSelect.Id, () => {
                                    cb(null, true);
                                });
                            } else {
                                cb(null, true);
                            }
                        }),                        
                        /*
                        mergeFavorites: (cb) => ApiService.instance.favoriteMerge({items: localFavorites}, (e, data, response) => {
                            dispatch({type: 'FAVORITES_INITIALIZED', data: data });
                            cb(null, true);
                        }),
                        */
                        loyalty: (cb) => ApiService.instance.loyaltyStatus({}, (e, data, response) => {
                            dispatch({type: 'LOYALTY_INITIALIZED', data: data })
                            cb(null, true);
                        }),
                        cards: (cb) => CardService.instance.mergeInit((data, defaultCard) => {
                            dispatch({type: 'CARDS_INITIALIZED', cards: [], defaultCard: defaultCard, remote: data });
                            cb(null, true);
                        }),
                    }, (err, results) => {
                        dispatch({type: 'AUTH_LOGIN_COMPLETED', isLoggedIn: true });
                        resolve(true);
                    });
                }
            });
        });    
    }
});


export default connect(mapStateToProps, mapDispatchToProps)(SignInScreen);