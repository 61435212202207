/**
 * PoppinPay API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
* The GiftCardResultModel model module.
* @module model/GiftCardResultModel
* @version 1.0.0
*/
export default class GiftCardResultModel {
    /**
    * Constructs a new <code>GiftCardResultModel</code>.
    * @alias module:model/GiftCardResultModel
    * @class
    */

    constructor() {
        
        
        
    }

    /**
    * Constructs a <code>GiftCardResultModel</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/GiftCardResultModel} obj Optional instance to populate.
    * @return {module:model/GiftCardResultModel} The populated <code>GiftCardResultModel</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new GiftCardResultModel();
                        
            
            if (data.hasOwnProperty('Gan')) {
                obj['Gan'] = ApiClient.convertToType(data['Gan'], 'String');
            }
            if (data.hasOwnProperty('Id')) {
                obj['Id'] = ApiClient.convertToType(data['Id'], 'String');
            }
        }
        return obj;
    }

    /**
    * @member {String} Gan
    */
    'Gan' = undefined;
    /**
    * @member {String} Id
    */
    'Id' = undefined;




}
