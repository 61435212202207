import React from 'react';
import { connect } from 'react-redux';
import { Hero, TextField, PasswordField } from '../components';
import { Validation, ApiService } from '../lib';
import { Redirect } from 'react-router-dom';
import queryString from 'query-string';

const ValidationRules = [
    {
      input: 'code',
      output: 'codeErrorMessage',
      stopOnError: true,
      rules: [
        {
          rule: Validation.rules.notEmpty,
          message: 'Code is required'
        }      
      ]
    },
    {
      input: 'email',
      output: 'emailErrorMessage',
      stopOnError: true,
      rules: [
        {
          rule: Validation.rules.notEmpty,
          message: 'Email Address is required'
        },
        {
          rule: Validation.rules.email,
          message: 'Email address is not valid!'
        }
      ]
    },
    {
      input: 'password',
      output: 'passwordErrorMessage',
      stopOnError: true,
      rules: [
        {
          rule: Validation.rules.notEmpty,
          message: 'Password is required'
        }      
      ]
    },
    {
      input: 'passwordConfirmation',
      output: 'passwordConfirmationErrorMessage',
      stopOnError: true,
      rules: [
        {
          rule: Validation.rules.notEmpty,
          message: 'Password Confirmation is required'
        },
        {
          rule: Validation.rules.equalsOtherInput,
          message: 'Password Confirmation must match Password',
          other: 'password'
        }
      ]
    },
  ];
  

class ResetPasswordScreen extends React.Component {
    constructor(props) {
        super(props);
        const values = queryString.parse(this.props.location.search);
        this.state = {
            code: values.code,
            email: values.email,
            password: null,
            passwordConfirmation: null,
            codeErrorMessage: null,
            emailErrorMessage: null,
            passwordErrorMessage: null,
            passwordConfirmationErrorMessage: null,
            resetPending: false
          };
    }

    onResetClicked() {
        var isValid = Validation.isValid(this.state, ValidationRules, (newState) => this.setState(newState));
        if (!isValid) {
            return;
        }

        this.setState({resetPending: true});

        this.props.resetPassword(this.state.email, this.state.code, this.state.password, this.state.passwordConfirmation)
            .then(() => {
                this.props.toast.success('Password reset successfully!');
                this.setState({resetPending: false});
                this.props.history.push('/sign-in');
            })
            .catch((reason) => {
                this.setState({resetPending: false});
                this.props.toast.error('Unable to reset password!');
            });
    }

    onEmailTextChanged(e) {
        this.setState({email: e.target.value});
    }

    render() {
        if (!this.state.email) {
            return (<Redirect to="/forgot-password" />);
        }

        return (
            <div className="forgot-password">
                <Hero message="Reset Password" />
                <div className="body">
                    <div className="container">
                        {!this.state.resetPending && this.renderForm()}
                        {this.state.resetPending && this.renderPending()}
                    </div>
                </div>
            </div>
        );
    }

    onCodeTextChanged(e) {
        this.setState({code: e.target.value});
    }

    onPasswordTextChanged(e) {
        this.setState({password: e.target.value});
    }

    onPasswordConfirmationTextChanged(e) {
        this.setState({passwordConfirmation: e.target.value});
    }

    renderForm() {
        return (
            <div className="form col-half center-block">
                <p className="center">Enter the code sent to your email and provide a new password below.</p>
                <div className="input full-size">
                    <TextField 
                            name="code"
                            placeholder="Code" 
                            defaultValue={this.state.code}
                            onChange={(e) => this.onCodeTextChanged(e)} 
                            onEnterPress={()=>this.onResetClicked()}
                            errorMessage={this.state.codeErrorMessage} />
                </div>
                <div className="input full-size">
                    <PasswordField 
                        name="password"
                        placeholder="New Password"
                        onChange={(e) => this.onPasswordTextChanged(e)}
                        onEnterPress={()=>this.onResetClicked()}
                        errorMessage={this.state.passwordErrorMessage} />
                </div>
                <div className="input full-size">
                    <PasswordField 
                        name="passwordConfirm"
                        placeholder="Confirm Your New Password"
                        onChange={(e) => this.onPasswordConfirmationTextChanged(e)}
                        onEnterPress={()=>this.onResetClicked()}
                        errorMessage={this.state.passwordConfirmationErrorMessage} />
                </div>
                <div className="button full-size">
                    <button className="btn btn-primary" onClick={()=>this.onResetClicked()}>Reset</button>
                </div>
            </div>
        );
    }

    renderPending() {
        return (
            <div className="form col-half center-block text-center">
                <i className="fas fa-spinner fa-pulse fa-5x"></i>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {

    }
};

const mapDispatchToProps = (dispatch) => ({
    resetPassword: (email, code, password, passwordConfirmation) => {
        return new Promise((resolve, reject) => {
            ApiService.instance.resetPassword({
                email: email,
                code: code,
                password: password,
                confirmPassword: passwordConfirmation
            }, (error, data, response) => {
                if (error) {
                    reject(error);
                    return;
                } else {
                    resolve(true);
                }
            });            
        });
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordScreen);