/**
 * PoppinPay API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from "../ApiClient";
import ForgotPasswordModel from '../model/ForgotPasswordModel';
import LoginResultBindingModel from '../model/LoginResultBindingModel';
import ModelStateError from '../model/ModelStateError';
import ProfileBindingModel from '../model/ProfileBindingModel';
import ProfileResultModel from '../model/ProfileResultModel';
import RegisterBindingModel from '../model/RegisterBindingModel';
import ResendRegisterCodeBindingModel from '../model/ResendRegisterCodeBindingModel';
import ResetPasswordModel from '../model/ResetPasswordModel';
import StatusBindingModel from '../model/StatusBindingModel';
import StatusResultModel from '../model/StatusResultModel';
import UpdateBindingModel from '../model/UpdateBindingModel';
import VerifyRegisterModel from '../model/VerifyRegisterModel';

/**
* Account service.
* @module api/AccountApi
* @version 1.0.0
*/
export default class AccountApi {

    /**
    * Constructs a new AccountApi. 
    * @alias module:api/AccountApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }

    /**
     * Callback function to receive the result of the accountForgotPassword operation.
     * @callback module:api/AccountApi~accountForgotPasswordCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {module:api/AccountApi~accountForgotPasswordCallback} callback The callback function, accepting three arguments: error, data, response
     */
    accountForgotPassword(body, callback) {
      let postBody = body;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = null;

      return this.apiClient.callApi(
        '/api/Account/ForgotPassword', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the accountLogin operation.
     * @callback module:api/AccountApi~accountLoginCallback
     * @param {String} error Error message, if any.
     * @param {module:model/LoginResultBindingModel} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Object} opts Optional parameters
     * @param {module:api/AccountApi~accountLoginCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/LoginResultBindingModel}
     */
    accountLogin(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
        'client_id': opts['ClientId'],
        'grant_type': opts['GrantType'],
        'refresh_token': opts['RefreshToken'],
        'username': opts['Username'],
        'password': opts['Password']
      };

      let authNames = [];
      let contentTypes = ['multipart/form-data'];
      let accepts = ['application/json'];
      let returnType = LoginResultBindingModel;

      return this.apiClient.callApi(
        '/api/Account/Login', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the accountProfile operation.
     * @callback module:api/AccountApi~accountProfileCallback
     * @param {String} error Error message, if any.
     * @param {module:model/ProfileResultModel} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {module:api/AccountApi~accountProfileCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/ProfileResultModel}
     */
    accountProfile(body, callback) {
      let postBody = body;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['oauth2'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = ProfileResultModel;

      return this.apiClient.callApi(
        '/api/Account/Profile', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the accountRegister operation.
     * @callback module:api/AccountApi~accountRegisterCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {module:api/AccountApi~accountRegisterCallback} callback The callback function, accepting three arguments: error, data, response
     */
    accountRegister(body, callback) {
      let postBody = body;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = null;

      return this.apiClient.callApi(
        '/api/Account/Register', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the accountResendRegisterCode operation.
     * @callback module:api/AccountApi~accountResendRegisterCodeCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {module:api/AccountApi~accountResendRegisterCodeCallback} callback The callback function, accepting three arguments: error, data, response
     */
    accountResendRegisterCode(body, callback) {
      let postBody = body;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = null;

      return this.apiClient.callApi(
        '/api/Account/ResendRegisterCode', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the accountResetPassword operation.
     * @callback module:api/AccountApi~accountResetPasswordCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {module:api/AccountApi~accountResetPasswordCallback} callback The callback function, accepting three arguments: error, data, response
     */
    accountResetPassword(body, callback) {
      let postBody = body;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = null;

      return this.apiClient.callApi(
        '/api/Account/ResetPassword', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the accountSendLoginCode operation.
     * @callback module:api/AccountApi~accountSendLoginCodeCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {module:api/AccountApi~accountSendLoginCodeCallback} callback The callback function, accepting three arguments: error, data, response
     */
    accountSendLoginCode(body, callback) {
      let postBody = body;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = null;

      return this.apiClient.callApi(
        '/api/Account/SendLoginCode', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the accountStatus operation.
     * @callback module:api/AccountApi~accountStatusCallback
     * @param {String} error Error message, if any.
     * @param {module:model/StatusResultModel} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {module:api/AccountApi~accountStatusCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/StatusResultModel}
     */
    accountStatus(body, callback) {
      let postBody = body;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['oauth2'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = StatusResultModel;

      return this.apiClient.callApi(
        '/api/Account/Status', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the accountUpdate operation.
     * @callback module:api/AccountApi~accountUpdateCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {module:api/AccountApi~accountUpdateCallback} callback The callback function, accepting three arguments: error, data, response
     */
    accountUpdate(body, callback) {
      let postBody = body;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['oauth2'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = null;

      return this.apiClient.callApi(
        '/api/Account/Update', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the accountVerify operation.
     * @callback module:api/AccountApi~accountVerifyCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {module:api/AccountApi~accountVerifyCallback} callback The callback function, accepting three arguments: error, data, response
     */
    accountVerify(body, callback) {
      let postBody = body;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = null;

      return this.apiClient.callApi(
        '/api/Account/Verify', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

}
