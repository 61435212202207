const initialState = {
    initialized: false,
    initializing: false,
};

export default function api(state, action) {
    if (state === undefined) {
        return initialState;
    }
    switch(action.type) {
        case 'API_INITIALIZING':
        return {
            initialized: false,
            initializing: true
        };
        case 'API_INITIALIZED':
        return {
            initialized: true,
            initializing: false
        };
        default:
        return state;
    }
}