import React from 'react';
import { connect } from 'react-redux';
import {PhoneNumberFormat, PhoneNumberUtil} from 'google-libphonenumber';
import moment from 'moment';
import poppinPayLogo from '../img/poppinpay-logo.png';
import { Link } from 'react-router-dom';
import { default as GeoHelper } from '../geoHelper';
import { ConfigService } from '../lib';

class Footer extends React.Component {

    formatAddress() {
        var address = this.props.currentLocation.Address;

        var mapUrl = 'https://maps.google.com/?q=' + address.AddressLine1;

        var parts = [
            address.AddressLine1
        ];

        parts.push((<br key="1" />))
        if (address.AddressLine2 && address.AddressLine2 != '') {
            parts.push(address.AddressLine2);
            parts.push((<br key="2" />))

            mapUrl += ' ' + address.AddressLine2;
        }
        parts.push(address.Locality + ', ' + address.AdministrativeDistrictLevel1 + ' ' + address.PostalCode);
        parts.push((<br key="3" />))

        mapUrl += ' ' + address.Locality + ', ' + address.AdministrativeDistrictLevel1 + ' ' + address.PostalCode;

        return (
            <div className="address">
                {parts}
                <a target="_blank" href={mapUrl}>Map It</a>
            </div>
        );
    }


    formatTime(value) {
        var m = moment.duration(value);
        var hh = m.hours();
        var mm = m.minutes();
        var dd = 'AM';
        var h = hh;
        if (h >= 12) {
            h = hh - 12;
            dd = 'PM';
        }
        if (h == 0) {
            h = 12;
        }

        mm = mm < 10 ? "0" + mm.toString() : mm.toString();

        return h.toString() + ':' + mm + ' ' + dd;
    }

    formatHourPair(value, index, array) {
        var opens = this.formatTime(value.Opens);
        var closes = this.formatTime(value.Closes);
        return opens + ' - ' + closes;
    }

    formatHours() {

        var hours = this.props.currentLocation.Hours;
        if (hours == null || hours.length == 0) {
            return null;
        }

        var days = ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"];
        var map = {};
        for(var i=0;i<days.length;i++) {
            map[days[i]] = [];
        }

        for(var i=0;i<hours.length;i++) {
            var hour = hours[i];
            var list = map[hour.DayOfWeek];
            list.push(hour);
        }

        var results = [];

        for(var i=0;i<days.length;i++) {
            var day = days[i];
            var list = map[day] || [];
            var label = null;
            if (list.length == 0) {
                label = (<span key={day + i}><strong>{day}</strong>: Closed</span>);
                results.push(label);
                results.push((<br key={i} />));
                continue;
            } 

            var text = list.map((v, i, a) => this.formatHourPair(v,i,a)).join(", ");
            label = (<span key={day + i}><strong>{day}</strong>: {text}</span>);
            results.push(label);
            results.push((<br key={i} />));
        }

        return (
            <div className="hours">
                {results}
            </div>
        );
    }

    getContactValue(targetType) {
        var contacts = this.props.currentLocation.Contacts;
        for(var i=0;i<contacts.length;i++) {
            var contact = contacts[i];
            var type = contact.Type;
            if (type == targetType) {
                return contact.Value;
            }
        }
        return null;
    }

    formatPhone() {
        var companyPhone = this.getContactValue('Phone');

        if (!companyPhone) {
            return (<div></div>);
        }

        var pnu = PhoneNumberUtil.getInstance();

        var url = 'tel:' + companyPhone.replace(/\D/g,'');
        var text = pnu.format(pnu.parse(companyPhone, 'US'), PhoneNumberFormat.NATIONAL);

        return (
            <div className="phone">
                <a href={url}>{text}</a>
            </div>        
        );
    }

    onLocationClicked() {
        GeoHelper.instance.watch();
        this.props.openLocationModal();
    }

    render() {
        const catalog = this.props.catalog;
        const location = this.props.currentLocation;
        if (!this.props.initialized || !location || !catalog) {
            return (<div></div>);
        }

        var companyName = catalog.Name;
        var companyWebsite = this.getContactValue('Website');
        var instagram = this.getContactValue('Instagram');
        var facebook = this.getContactValue('Facebook');
        var twitter = this.getContactValue('Twitter');
        var youtube = this.getContactValue('YouTube');

        var social = (<div></div>);

        var instagramLink = null;
        if (instagram) {
            var instagramUrl = "https://www.instagram.com/" + instagram;
            instagramLink = (
                <a href={instagramUrl} target="_blank">
                    <i className="fab fa-instagram"></i><span className="sr-only">Follow us on Instagram</span>
                </a>
            );
        }

        var facebookLink = null;
        if (facebook) {
            var facebokUrl = facebook;
            facebookLink = (
                <a href={facebokUrl} target="_blank">
                    <i className="fab fa-facebook"></i><span className="sr-only">Follow us on Facebook</span>
                </a>
            );
        }

        var twitterLink = null;
        if (twitter) {
            var twitterUrl = 'https://www.twitter.com/' + twitter;
            twitterLink = (
                <a href={twitterUrl} target="_blank">
                    <i className="fab fa-twitter"></i><span className="sr-only">Follow us on Twitter</span>
                </a>
            );
        }

        var youtubeLink = null;
        if (youtube) {
            var youtubeUrl = 'https://www.youtube.com/' + youtube;
            youtubeLink = (
                <a href={youtubeUrl} target="_blank">
                    <i className="fab fa-youtube"></i><span className="sr-only">Follow us on YouTube</span>
                </a>
            );
        }

        var social = (
            <div className="social">
                {instagramLink}
                {facebookLink}
                {twitterLink}
                {youtubeLink}
            </div>
        );

        var changeLocationAction = null;
        if (this.props.hasMultipleLocations) {
            changeLocationAction = (<a href="javascript://" onClick={()=>this.onLocationClicked()}>Change my location</a>);
        }
        
        var companyLogo = global.imagesPath + 'logo.png';

        var websiteLink = null;
        if (companyWebsite) {
            websiteLink = (<a href={companyWebsite} className="website">Visit Website</a>);
        }

        var androidAppUrl = ConfigService.get().AndroidAppUrl;
        var androidLink = null;
        var appleAppUrl = ConfigService.get().AppleAppUrl;
        var appleLink = null;

        if (androidAppUrl) {
            androidLink = (<a href={androidAppUrl} target="_blank" className="android"><span className="sr-only">Android App on Google Play</span></a>);
        }

        if (appleAppUrl) {
            appleLink = (<a href={appleAppUrl} target="_blank" className="apple"><span className="sr-only">Available on the App Store</span></a>);
        }

        var appPromoContainer = null;
        if (androidAppUrl || appleAppUrl) {
            appPromoContainer = (
                <div>
                    <h4><span>Now Available</span> Order on the go:</h4>
                    <div className="app-store-links">
                        {appleLink}
                        {androidLink}
                    </div>
                </div>
            );
        }

        return (
            <footer>
            <div className="container">
                <div className="top">
                    <div className="branding">
                        <img src={companyLogo} className="logo" alt={companyName} />
                        {websiteLink}
                        {social}
                    </div>
                    <div className="location">
                        <h4>
                            Contact Us
                            {changeLocationAction}
                        </h4>
                        <div className="location-details">
                            {this.formatAddress()}
                            {this.formatHours()}
                            {this.formatPhone()}
                        </div>
                    </div>
                    <div className="app-promo">
                        {appPromoContainer}
                    </div>
                </div>
                <div className="bottom">
                    <div className="legal">
                        <Link to="/about">About Us</Link>                        
                        <a target="_blank" href="https://poppinpay.com/mobile-order-terms-of-use/">Terms and Conditions</a>
                        <a target="_blank" href="https://poppinpay.com/mobile-order-privacy-policy/">Privacy Policy</a>
                    </div>
                    <div className="poppinpay">
                        <span>Powered by</span> <a href="https://poppinpay.com/"><img src={poppinPayLogo} className="PoppinPay - logo" /></a>
                    </div>
                </div>
            </div>
          </footer>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        initialized: state.api.initialized,
        currentLocation: state.location.currentLocation,
        catalog: state.catalog.data,
        hasMultipleLocations: state.location.locations.length > 1,
    }
};

const mapDispatchToProps = (dispatch) => ({
    openLocationModal: () => {
        dispatch({type: 'LOCATION_CHOOSER'});
    }    
});

export default connect(mapStateToProps, mapDispatchToProps)(Footer);