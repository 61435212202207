class DineInDetails {
    note:string | undefined
    skipNote: Boolean=false
    
}

class DineInState
{
    dineInDetails:DineInDetails | undefined | null
};

const initialState:DineInState = {
    dineInDetails: null,
};

export default function dineIn(state:DineInState, action:any):DineInState {
    if (state === undefined) {
        return initialState;
    }
    switch(action.type) {
        case 'DINEIN_SET_DETAILS':
            return {
                ...state,
                dineInDetails: action.dineInDetails
            };
        default:
            return state;
    }
}