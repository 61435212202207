/**
 * PoppinPay API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import LoyaltyCouponType from './LoyaltyCouponType';

/**
* The LoyaltyCouponModel model module.
* @module model/LoyaltyCouponModel
* @version 1.0.0
*/
export default class LoyaltyCouponModel {
    /**
    * Constructs a new <code>LoyaltyCouponModel</code>.
    * @alias module:model/LoyaltyCouponModel
    * @class
    */

    constructor() {
        
        
        
    }

    /**
    * Constructs a <code>LoyaltyCouponModel</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/LoyaltyCouponModel} obj Optional instance to populate.
    * @return {module:model/LoyaltyCouponModel} The populated <code>LoyaltyCouponModel</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new LoyaltyCouponModel();
                        
            
            if (data.hasOwnProperty('Id')) {
                obj['Id'] = ApiClient.convertToType(data['Id'], 'String');
            }
            if (data.hasOwnProperty('Name')) {
                obj['Name'] = ApiClient.convertToType(data['Name'], 'String');
            }
            if (data.hasOwnProperty('Description')) {
                obj['Description'] = ApiClient.convertToType(data['Description'], 'String');
            }
            if (data.hasOwnProperty('Variations')) {
                obj['Variations'] = ApiClient.convertToType(data['Variations'], ['String']);
            }
            if (data.hasOwnProperty('Items')) {
                obj['Items'] = ApiClient.convertToType(data['Items'], ['String']);
            }
            if (data.hasOwnProperty('Categories')) {
                obj['Categories'] = ApiClient.convertToType(data['Categories'], ['String']);
            }
            if (data.hasOwnProperty('IssuedAt')) {
                obj['IssuedAt'] = ApiClient.convertToType(data['IssuedAt'], 'Date');
            }
            if (data.hasOwnProperty('ExpiresAt')) {
                obj['ExpiresAt'] = ApiClient.convertToType(data['ExpiresAt'], 'Date');
            }
            if (data.hasOwnProperty('Type')) {
                obj['Type'] = LoyaltyCouponType.constructFromObject(data['Type']);
            }
            if (data.hasOwnProperty('Percentage')) {
                obj['Percentage'] = ApiClient.convertToType(data['Percentage'], 'Number');
            }
            if (data.hasOwnProperty('AmountMoney')) {
                obj['AmountMoney'] = ApiClient.convertToType(data['AmountMoney'], 'Number');
            }
            if (data.hasOwnProperty('MaximumMoney')) {
                obj['MaximumMoney'] = ApiClient.convertToType(data['MaximumMoney'], 'Number');
            }
        }
        return obj;
    }

    /**
    * @member {String} Id
    */
    'Id' = undefined;
    /**
    * @member {String} Name
    */
    'Name' = undefined;
    /**
    * @member {String} Description
    */
    'Description' = undefined;
    /**
    * @member {Array.<String>} Variations
    */
    'Variations' = undefined;
    /**
    * @member {Array.<String>} Items
    */
    'Items' = undefined;
    /**
    * @member {Array.<String>} Categories
    */
    'Categories' = undefined;
    /**
    * @member {Date} IssuedAt
    */
    'IssuedAt' = undefined;
    /**
    * @member {Date} ExpiresAt
    */
    'ExpiresAt' = undefined;
    /**
    * @member {module:model/LoyaltyCouponType} Type
    */
    'Type' = undefined;
    /**
    * @member {Number} Percentage
    */
    'Percentage' = undefined;
    /**
    * @member {Number} AmountMoney
    */
    'AmountMoney' = undefined;
    /**
    * @member {Number} MaximumMoney
    */
    'MaximumMoney' = undefined;




}
