import './global';
import React from 'react';
import ReactDOM from 'react-dom';
import { unregister } from './registerServiceWorker';
import * as Sentry from '@sentry/browser';

unregister();

global.load
  .then(() => {
    var sentryDsn = global.configuration.SentryDsn || "https://721e733210b94004940452890ed997e0@sentry.io/1320021";

    Sentry.init({
      dsn: sentryDsn,
      beforeBreadcrumb: (breadcrumb, hint) => {
        if (breadcrumb.category == 'console') {
          return null;
        }

        if (breadcrumb.message != null && breadcrumb.message.length > 1024) {
          breadcrumb.message = breadcrumb.message.substr(0, 1024);
        }
        
        return breadcrumb;
      }
    });

    const App = require('./App').default;
    ReactDOM.render(<App />, document.getElementById('root'));
  });