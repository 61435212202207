import translationsEN from './locales/en.json';
import * as Sentry from '@sentry/browser';
import superagent from "superagent";

if (!global) {
    global = {};
}

global.language = 'en-US';

global.storage = {
    secure: {
        get: function(key) {
            return new Promise(function(resolve, reject) {
                resolve(null);
            });
        },
        set: function(key, value) {

        }
    },
    unsecure: {
        getItem: function(key) {
            return new Promise(function(resolve, reject) {
                var value = window.localStorage.getItem(key);
                resolve(value);
            });
        },
        setItem: function(key, value) {
            window.localStorage.setItem(key, value);
        }
    }
};

global.translations = {
    en: translationsEN
}

global.sentry = {
    captureMessage: (message, options) => {
        Sentry.withScope((scope) => {
            if (options) {
                if (options.extra) {
                    scope.setExtras(options.extra);
                }
                if (options.fingerprint) {
                    scope.setFingerprint(options.fingerprint);
                }
            }

            Sentry.captureMessage(message);
        });
    },
    captureBreadcrumb: Sentry.addBreadcrumb
};

var host = window.location.hostname.toLowerCase();

global.contentPath = host == 'localhost' ? '/' : '/_/' + host + '/';
global.imagesPath = global.contentPath + 'img/';

global.load = new Promise((resolve, reject) => {
    var url = global.contentPath + 'config.json';
    superagent
        .get(url)
        .set('Accept', 'application/json')
        .end((err, res) => {
          if (err) {
              reject();
              return;
          }  
          global.configuration = res.body;
          resolve(global);          
        });
});