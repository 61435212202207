/**
 * PoppinPay API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
/**
* Enum class UsernameModeType.
* @enum {}
* @readonly
*/
export default class UsernameModeType {
        /**
         * value: "Email"
         * @const
         */
        Email = "Email";

        /**
         * value: "Phone"
         * @const
         */
        Phone = "Phone";


    /**
    * Returns a <code>UsernameModeType</code> enum value from a Javascript object name.
    * @param {Object} data The plain JavaScript object containing the name of the enum value.
    * @return {module:model/UsernameModeType} The enum <code>UsernameModeType</code> value.
    */
    static constructFromObject(object) {
        return object;
    }
}
