import React from 'react';
import { connect } from 'react-redux';
import { ApiService } from '../lib';

class FavoriteIcon extends React.Component {
    render() {
        var favoriteStatus = '';        
        var icon = 'favorite_border';

        if (this._isFavorite()) {
            icon = 'favorite';
            favoriteStatus = 'Remove from favorites';
        } else {
            favoriteStatus = 'Add to favorites';
        }

        return (
            <a href="javascript://" className="favorite" onClick={() => this._toggleFavorite()}>
                <i className="material-icons">{icon}</i>
                <span className="sr-only">{favoriteStatus}</span>
            </a>
        );
    }

    _isFavorite() {
        var item = this.props.item;
        var list = this.props.favorites;
        let newList = list.filter((listItem) => { return listItem == item.Id });
        return newList.length > 0;
    }

    _toggleFavorite(e) {
        if (this._isFavorite()) {
            this.props.remove(this.props.item.Id);
        } else {
            this.props.add(this.props.item.Id);
        }
    }
}


const mapStateToProps = (state) => {
    var items = state.favorites.items;
    var remote = state.favorites.remote;
    var favorites = items.concat(remote.filter(function (item) {
        return items.indexOf(item) < 0;
    }));
    return {
        favorites: favorites
    }
};

const mapDispatchToProps = (dispatch) => ({
    add: (item) => {
        if (!ApiService.instance.isLoggedIn()) {
            dispatch({type:"FAVORITES_ADD", item: item});
        } else {
            dispatch({type:"FAVORITES_ADD_REMOTE", item: item});
            ApiService.instance.favoriteAdd({itemId: item}, (e, data, response) => {});
        }
    },
    remove: (item) => {
        if (!ApiService.instance.isLoggedIn()) {
            dispatch({type:"FAVORITES_REMOVE", item: item});
        } else {
            dispatch({type:"FAVORITES_REMOVE_REMOTE", item: item});
            ApiService.instance.favoriteRemove({itemId: item}, (e, data, response) => { });
        }
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(FavoriteIcon);