import React from 'react';
import { connect } from 'react-redux';
import { Internationalization, ConfigService, CurrencyFormatter, ApiService, ShoppingCartService, ErrorParser, InventoryHelper } from '../lib';

class OrderSummary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pending: false
        };
    }
    getSortedItemModifierLists(item) {
        var map = this.props.modifierListMap;
        return item.ModifierLists.sort((a, b) => {
            var listA = map.get(a.Id);
            var listB = map.get(b.Id);

            var nameA = listA.Name.toUpperCase();
            var nameB = listB.Name.toUpperCase();
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }
            return 0;
        });
    }

    renderCartEntry(entry) {
        var id = entry.item;

        var item = this.props.itemsById[id];

        var notes = null;
        if (entry.note) {
            notes = (<div className="notes"><strong>Notes:</strong> {entry.note}</div>);
        }

        var price = 0;
        var lineItem = this.props.preview.lineItems[entry.key];
        if (lineItem) {
            price = lineItem.grossSales;
        }

        var modifierListMap = this.props.modifierListMap;
        var modifierMap = this.props.modifierMap;

        var variationId = entry.variation;
        var variation = item.Variations.find((x) => x.Id == variationId);

        var modifierLines = [];
        var itemModifierLists = this.getSortedItemModifierLists(item);
        for (var i = 0; i < itemModifierLists.length; i++) {
            var itemModifierList = itemModifierLists[i];
            var selectedModifierIds = entry.modifiers[itemModifierList.Id];
            if (!selectedModifierIds || selectedModifierIds.length == 0) {
                continue;
            }


            var modifierNames = [];
            for (var j = 0; j < selectedModifierIds.length; j++) {
                var modifierId = selectedModifierIds[j];
                var modifier = modifierMap[modifierId];
                if (!modifier) {
                    continue;
                }
                modifierNames.push(modifier.Name);
            }

            if (modifierNames.length > 0) {
                var modifierList = modifierListMap.get(itemModifierList.Id);
                modifierLines.push(<span key={itemModifierList.Id}>{modifierList.Name + ': ' + modifierNames.join(', ')}<br /></span>);
            }
        }

        return (
            <div key={entry.key} className="item">
                <div className="details">
                    <h3>{item.Name}</h3>
                    <div className="description">
                        <span>{variation.Name}<br /></span>
                        {modifierLines}
                    </div>
                    <div className="qty">Quantity: {entry.quantity}</div>
                    {notes}
                </div>
                <div className="price">{CurrencyFormatter.format(price, this.props.currencyCode)}</div>
            </div>
        );
    }

    getRewardNote(coupon) {
        var percentage = coupon.Percentage;
        var maximumMoney = coupon.MaximumMoney;
        var amountMoney = coupon.AmountMoney;
        var type = coupon.Type;
        var categories = coupon.Categories || [];
        var items = coupon.Items || [];
        var currencyCode = this.props.currencyCode;

        var key = null;
        var model = {};

        if (percentage) {
            key = 'percentage_off';
            model.percentage = percentage;
            if (maximumMoney) {
                key += '_with_max';
                model.maximum = CurrencyFormatter.format(maximumMoney, currencyCode);
            }
        }
        else if (amountMoney) {
            key = 'amount_off';
            model.amount = CurrencyFormatter.format(amountMoney, currencyCode);
        }
        else {
            return null;
        }

        if (type == 'EntireOrder') {
            key += '_entire_order';
        }
        else if (type == 'Categories') {
            key += '_by_categories';
            model.count = categories.length;
        }
        else {
            key += '_by_items';
            model.count = items.length;
        }

        return Internationalization.strings('coupon_notes.' + key, model);
    }

    renderCoupon(coupon) {
        var note = this.getRewardNote(coupon);
        return (
            <div key={coupon.Id} className="item">
                <div className="details">
                    <h3>{coupon.Name}</h3>
                    <div className="note">{note}</div>
                </div>
            </div>
        );
    }

    canPlaceOrder() {
        // if we're not logged in then we need a guest profile, a current card, valid date & time and one or more items
        // if we are logged in, we need a current card, valid date & time and one or more items
        var grandTotal = this.props.preview.grandTotal;
        var hasValidProfile = this.props.isLoggedIn || this.props.guest;
        var hasValidCard = this.props.card != null || grandTotal == 0.0;
        var hasValidDate = this.props.date != null;
        var hasValidTime = this.props.time != null;
        var hasValidItems = this.props.items.length > 0;
        var hasPendingOrder = this.state.pending;
        //var hasAmount = this.props.preview && this.props.preview.grandTotal > 0;
        var hasFulfillment = this.props.fulfillmentType != null && this.props.cartFulfillmentSettings != null;
        var anyOutOfStock = this.props.anyOutOfStock;

        if (!hasValidProfile || !hasValidCard || !hasValidDate || !hasValidTime
            || !hasValidItems || hasPendingOrder || !hasFulfillment || anyOutOfStock) {
            return false;
        }

        if (this.props.fulfillmentType === 'DINEIN') {
            return this.props.dineInSettings.skipNote === true
                ? true
                : this.props.dineInSettings.note !== null
                    && this.props.dineInSettings.note !== undefined
                    && this.props.dineInSettings.note !== ''
                    ? true
                    : false;
        }

        return true;
    }

    onPlaceOrderClicked() {
        if (!this.canPlaceOrder()) {
            return;
        }

        this.setState({ pending: true });

        var guest = this.props.isLoggedIn ? null : this.props.guest;
        this.props.placeOrder(this.props.idempotencyKey, this.props.card, this.props.items,
            this.props.coupons, this.props.rewards, this.props.tipAmount, this.props.location, this.props.time,
            guest, this.props.fulfillmentType,
            this.props.cartFulfillmentSettings)
            .then((orderId) => {
                this.setState({ pending: false });
                this.props.toast.success('Order was placed!');
                this.props.history.push('/order-confirmation/' + orderId);
            })
            .catch((error) => {
                this.setState({ pending: false });
                var message = ErrorParser.parse(error, 'Could not place order!');
                this.props.toast.error(message);
            });
    }

    renderCheckoutMessage() {
        var settings = this.props.locationFulfillmentSettings;
        if (!settings || !settings.CheckoutMessage) {
            return null;
        }

        var message = settings.CheckoutMessage;

        return (
            <div className="checkout-message">{message}</div>
        );
    }

    render() {
        var subTotal = this.props.preview.grossTotal;
        var totalTaxes = this.props.preview.totalTaxes;
        var totalDiscounts = this.props.preview.totalDiscounts;
        var totalTips = this.props.preview.totalTips;
        var grandTotal = this.props.preview.grandTotal;

        var itemRows = [];
        for (var i = 0; i < this.props.items.length; i++) {
            var entry = this.props.items[i];
            var itemRow = this.renderCartEntry(entry);
            itemRows.push(itemRow);
        }

        var couponRows = [];
        for (var i = 0; i < this.props.coupons.length; i++) {
            var coupon = this.props.coupons[i];
            var couponRow = this.renderCoupon(coupon);
            couponRows.push(couponRow);
        }

        var canPlaceOrder = this.canPlaceOrder();

        var placeOrderButtonText = "Place Order";
        if (this.state.pending) {
            placeOrderButtonText = (
                <span>
                    <i className="fas fa-spinner fa-pulse"></i>
                    &nbsp;Please wait...
                </span>
            )
        }

        var tipButton = null;
        var hasTipping = false;

        if (!this.props.location.ShowTipping) {
            tipButton = null;
        } else if (totalTips > 0) {
            var color = ConfigService.get().PrimaryColor;
            hasTipping = true;
            tipButton = (
                <a onClick={() => this.props.openModifyTipModal()} href="javascript://">{CurrencyFormatter.format(totalTips, this.props.currencyCode)}</a>
            );
        } else {
            hasTipping = true;
            tipButton = (
                <a onClick={() => this.props.openModifyTipModal()} href="javascript://">Add Tip</a>
            );
        }

        var hasDiscount = totalDiscounts < 0;
        var discountAmount = null;
        if (hasDiscount) {
            discountAmount = (<div><span>{CurrencyFormatter.format(totalDiscounts, this.props.currencyCode)}</span><br /></div>);
        }

        return (
            <div className="inner">
                <h2>Order Summary</h2>
                {itemRows}
                {couponRows}
                <div className="price-summary">
                    <div className="txt">
                        Subtotal<br />
                        Tax<br />
                        {hasDiscount && (<span>Discount<br /></span>)}
                        {hasTipping && (<span>Tip<br /></span>)}
                        <strong>Total</strong>
                    </div>
                    <div className="totals">
                        {CurrencyFormatter.format(subTotal, this.props.currencyCode)}<br />
                        {CurrencyFormatter.format(totalTaxes, this.props.currencyCode)}<br />
                        {discountAmount}
                        {tipButton}
                        <strong>{CurrencyFormatter.format(grandTotal, this.props.currencyCode)}</strong>
                    </div>
                </div>
                {this.renderCheckoutMessage()}
                <a href="javascript://" onClick={() => this.onPlaceOrderClicked()} disabled={!canPlaceOrder} className={"btn " + (!canPlaceOrder ? 'btn-inactive' : 'btn-primary')}><span>{placeOrderButtonText}</span></a>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    var location = state.cart.location;
    var fulfillmentType = state.cart.fulfillmentType;
    var locationFulfillmentSettings = null;

    if (location && location.Fulfillments) {
        if (fulfillmentType == 'PICKUP') {
            locationFulfillmentSettings = location.Fulfillments.Pickup;
        } else if (fulfillmentType == 'CURBSIDE') {
            locationFulfillmentSettings = location.Fulfillments.Curbside;
        } else if (fulfillmentType == 'DELIVERY') {
            locationFulfillmentSettings = location.Fulfillments.Delivery;
        } else if (fulfillmentType == 'DINEIN') {
            locationFulfillmentSettings = location.Fulfillments.DineIn;
        }
    }

    var cartFulfillmentSettings = null;
    if (fulfillmentType == 'PICKUP') {
        cartFulfillmentSettings = state.cart.pickupSettings;
    } else if (fulfillmentType == 'CURBSIDE') {
        cartFulfillmentSettings = state.cart.curbsideSettings;
    } else if (fulfillmentType == 'DELIVERY') {
        cartFulfillmentSettings = state.cart.deliverySettings;
    } else if (fulfillmentType == 'DINEIN') {
        cartFulfillmentSettings = state.cart.dineInSettings;
    }

    var anyOutOfStock = false;

    var inventoryCounts = state.inventory.locationVariationMap[state.location.selectedLocationId] || {};
    var cartEntries = state.cart.items;
    var variationsById = state.catalog.variationsById;

    for (var i = 0; i < cartEntries.length; i++) {
        var cartEntry = cartEntries[i];
        var variationId = cartEntry.variation;

        var summary = InventoryHelper.getInventorySummary(variationId, variationsById, inventoryCounts, cartEntries);
        if (summary.stockCount <= 0) {
            anyOutOfStock = true;
            break;
        }
    }

    return {
        idempotencyKey: state.cart.idempotencyKey,

        modifierListMap: state.selectedItem.modifierListMap,
        modifierMap: state.selectedItem.modifierMap,
        itemsById: state.catalog.itemsById,
        preview: state.cart.preview,
        coupons: state.cart.coupons,
        rewards: state.cart.rewards,
        tipAmount: state.cart.tipAmount,
        items: state.cart.items,
        location: state.cart.location,
        currencyCode: state.location.currentLocation.Currency,

        isLoggedIn: state.auth.isLoggedIn,
        card: state.cards.selectedCard,
        date: state.cart.date,
        time: state.cart.time,
        guest: state.cart.guest,

        fulfillmentType: fulfillmentType,
        locationFulfillmentSettings: locationFulfillmentSettings,
        cartFulfillmentSettings: cartFulfillmentSettings,

        anyOutOfStock: anyOutOfStock,
        dineInSettings: state.cart.dineInSettings,
    }
};

const __orderQueueStatusInterval = 3000;
const __orderQueueStatusLimit = 10;

function __checkOrderQueueStatus(queueKey, guest, attempt, dispatch, resolve, reject) {

    console.log('check order status: attempt #' + attempt);

    function statusHandler(error, data, response) {
        if (!error) {
            if (data.Status == "Error") {
                reject(data.Errors);
                return;
            }

            if (data.Status == "Complete") {
                var orderId = data.OrderId;

                dispatch({ type: 'ORDER_PLACED', data: data.Order });

                ApiService.instance.loyaltyStatus({}, (e, data, response) => {
                    dispatch({ type: 'LOYALTY_INITIALIZED', data: data })
                    resolve(orderId);
                });

                return;
            }
        }

        if (attempt >= __orderQueueStatusLimit) {
            reject('Timeout');
            return;
        }

        setTimeout(() => {
            __checkOrderQueueStatus(queueKey, guest, attempt + 1, dispatch, resolve, reject);
        }, __orderQueueStatusInterval);
    }

    var model = {
        idempotencyKey: queueKey
    };

    if (guest) {
        ApiService.instance.orderGuestOrderStatus(model, statusHandler);
    } else {
        ApiService.instance.orderOrderStatus(model, statusHandler);
    }
}

const mapDispatchToProps = (dispatch) => ({
    openModifyTipModal: () => {
        dispatch({ type: 'CART_TIP_SHOW_MODIFY' });
    },
    queueOrder: (idempotencyKey, card, items, coupons, tipAmount, location, time, guest) => {
        return new Promise((resolve, reject) => {
            ShoppingCartService.instance.queueOrder(idempotencyKey, card, items, coupons, tipAmount, location, 'Web', time, guest, (error, data, response) => {
                if (error) {
                    reject(error);
                    return;
                }

                var queueKey = data.IdempotencyKey;
                setTimeout(() => {
                    __checkOrderQueueStatus(queueKey, guest, 1, dispatch, resolve, reject);
                }, __orderQueueStatusInterval);
            });
        });
    },
    placeOrder: (idempotencyKey, card, items, coupons, rewards, tipAmount, location, time, guest, fulfillmentType, fulfillmentSettings) => {
        return new Promise((resolve, reject) => {
            var placeOrderContext = {
                idempotencyKey: idempotencyKey,
                card: card,
                items: items,
                coupons: coupons,
                rewards: rewards,
                tipAmount: tipAmount,
                location: location,
                source: 'Web',
                time: time,
                guest: guest,
                fulfillmentType: fulfillmentType,
                fulfillmentSettings: fulfillmentSettings
            };

            ShoppingCartService.instance.placeContextOrder(placeOrderContext, (error, data, response) => {
                if (error) {
                    reject(error);
                    return;
                }

                var orderId = data.OrderId;

                dispatch({ type: 'ORDER_PLACED', data: data.Order });

                ApiService.instance.loyaltyStatus({}, (e, data, response) => {
                    dispatch({ type: 'LOYALTY_INITIALIZED', data: data })
                    resolve(orderId);
                });
            });
        });
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderSummary);