import {  CurrencyFormatter } from '../lib';

type RewardCatalogEntry = {
    type:string;
    uid:string
    category:any;
    item:any;
    variation:any;
};


export class RewardHelper {
    public static typeConverter(type:any):any{
        switch (type) {
            case 0:
                return 'EntireOrder';
                break;
            case 1:
                return 'Items';
                break;
            case 2:
                return 'Categories';
                break;
            default:
                return type;
                break;
        }
     
    }
    public static getRewardCatalog(reward:any, categoriesById:any, variationsById:any, itemsById:any):RewardCatalogEntry[] {       
        var categories = reward.Categories || [];
        var items = reward.Items || [];
        var variations = reward.Variations || [];
        var type = this.typeConverter(reward.Type);
        var usedItemsIds: {[key: string]: boolean} = { };
  
        if (type == 'Categories') {
            var result:RewardCatalogEntry[] = [];
            for(var i=0;i<categories.length;i++) {
                var categoryId = categories[i];
                var category = categoriesById[categoryId];
                if (category) {
                    result.push({
                        type:'Category',
                        uid:categoryId,
                        category: category,
                        item:null,
                        variation:null
                    });
                }
            }
            return result;
        } else if (type == 'Items') {
            var result:RewardCatalogEntry[] = [];
            
            for(var i=0;i<items.length;i++) {
                var itemId = items[i];

                var item = itemsById[itemId];
                if (item) {
                    result.push({
                        type:'Item',
                        uid: itemId,
                        item: item,
                        category: categoriesById[item.CategoryId],
                        variation: null
                    });
                }
            }

            for(var i=0;i<variations.length;i++) {
                var variationId = variations[i];

                var variation = variationsById[variationId];
                if (variation) {
                    result.push({
                        type:'Variation',
                        uid: variationId,
                        variation: variation,
                        item: itemsById[variation.ItemId],
                        category: categoriesById[variation.CategoryId]
                    })
                }
            }
            return result;
        }

        return [];
    }

    public static getRewardCount(reward:any, categoriesById:any, variationsById:any, itemsById:any ):number {
        var categories = reward.Categories || [];
        var items = reward.Items || [];
        var variations = reward.Variations || [];
        var type = this.typeConverter(reward.Type);

        if (type == 'Categories') {
            var count = 0;
            for(var i=0;i<categories.length;i++) {
                var categoryId = categories[i];
                var category = categoriesById[categoryId];
                if (category) {
                    count++;
                }
            }
            return count;
        } else if (type == 'Items') {
            
            var itemIds = [];
            var usedItemIds: {[key: string]: boolean} = { };

            for(var i=0;i<items.length;i++){
                var itemId = items[i];
                if (usedItemIds[itemId]) {
                    continue;
                }

                itemIds.push(itemId);
                usedItemIds[itemId] = true;
            }
            
            for(var i=0;i<variations.length;i++) {
                var variationId = variations[i];
                var variation = variationsById[variationId];
                if (variation) {
                    var itemId = variation.ItemId;
                    if (usedItemIds[itemId]) {
                        continue;
                    }
    
                    itemIds.push(itemId);
                    usedItemIds[itemId] = true;
                }
            }

            var count = 0;
            for(var i=0;i<itemIds.length;i++) {
                var itemId = itemIds[i];

                var item = itemsById[itemId];
                if (item) {
                    count++;
                }
            }

            return count;
        }  else {
            return 0;
        }
    }

    public static getRewardTranslationModel(reward:any, currencyCode:string, categoriesById:any, variationsById:any, itemsById:any): any {
        var percentage = reward.Percentage;
        var maximumMoney = reward.MaximumMoney;
        var amountMoney = reward.AmountMoney;
        var type = this.typeConverter(reward.Type);
        var key = null;
        var model = {
            percentage: null,
            maximum: '',
            amount: '',
            count: 0
        };

        if (percentage)
        {
            key = 'percentage_off';
            model.percentage = percentage;
            if (maximumMoney)
            {
                key += '_with_max';
                model.maximum = CurrencyFormatter.format(maximumMoney, currencyCode);
            }
        }
        else if (amountMoney)
        {
            key = 'amount_off';
            model.amount = CurrencyFormatter.format(amountMoney, currencyCode);
        }
        else
        {
            return null;
        }

        if (type == 'EntireOrder')
        {
            key += '_entire_order';
        }
        else if (type == 'Categories')
        {
            key += '_by_categories';
            model.count = this.getRewardCount(reward, categoriesById, variationsById, itemsById);
        }
        else
        {
            key += '_by_items';
            model.count = this.getRewardCount(reward, categoriesById, variationsById, itemsById);
        }

        return {
            key: key,
            model:model
        };
    }
}