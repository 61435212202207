const initialState = {
    locationVariationMap: {}
};

export default function inventory(state, action) {
    if (state === undefined) {
        return initialState;
    }
    switch(action.type) {
        case 'INVENTORY_UPDATED':
            var map = {...state.locationVariationMap};

            var variations = map[action.locationId];
            if (!variations) {
                variations = map[action.locationId] = {};
            }

            var variation = variations[action.variationId];
            if (!variation) {
                variation = variations[action.variationId] = {};
            }
            
            variation.count = action.count;

            return { ...state, locationVariationMap: map };
        case 'INVENTORY_BULK_UPDATED':
            // var next = { ...state };

            var map = {...state.locationVariationMap};

            for(var i=0;i<action.entries.length;i++) {
                var entry = action.entries[i];

                var variations = map[entry.locationId];
                if (!variations) {
                    variations = map[entry.locationId] = {};
                }
    
                var variation = variations[entry.variationId];
                if (!variation) {
                    variation = variations[entry.variationId] = {};
                }
                
                variation.count = entry.count;                
            }

            return { ...state, locationVariationMap: map };
    }
    return state;
}