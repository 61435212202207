import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { CurrencyFormatter } from '../lib';
import moment from 'moment-timezone';
import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';
import { Hero } from '../components';

class OrderConfirmationScreen extends React.Component {
    formatAddress(address) {
        var mapUrl = 'https://maps.google.com/?q=' + address.AddressLine1;

        var parts = [
            address.AddressLine1
        ];

        parts.push((<br key="1" />))
        if (address.AddressLine2 && address.AddressLine2 != '') {
            parts.push(address.AddressLine2);
            parts.push((<br key="2" />))

            mapUrl += ' ' + address.AddressLine2;
        }
        parts.push(address.Locality + ', ' + address.AdministrativeDistrictLevel1 + ' ' + address.PostalCode);
        parts.push((<br key="3" />))

        mapUrl += ' ' + address.Locality + ', ' + address.AdministrativeDistrictLevel1 + ' ' + address.PostalCode;

        return (
            <p className="address">
                {parts}
                <a target="_blank" href={mapUrl}>Map It</a>
            </p>
        );
    }

    formatPhone(location) {
        var companyPhone = null;

        var contacts = location.Contacts;
        for (var i = 0; i < contacts.length; i++) {
            var contact = contacts[i];
            var type = contact.Type;
            if (type == 'Phone') {
                companyPhone = contact.Value;
                break;
            }
        }

        if (!companyPhone) {
            return (<div></div>);
        }

        var pnu = PhoneNumberUtil.getInstance();

        var url = 'tel:' + companyPhone.replace(/\D/g, '');
        var text = pnu.format(pnu.parse(companyPhone, 'US'), PhoneNumberFormat.NATIONAL);

        return (
            <p className="phone">
                Talk to us:<br /><a href={url}>{text}</a>
            </p>
        );
    }

    renderLineItem(lineItem, location, index) {
        var descriptionParts = [];
        if (lineItem.VariationName) {
            descriptionParts.push(lineItem.VariationName);
        }
        for (var i = 0; i < lineItem.Modifiers.length; i++) {
            var modifier = lineItem.Modifiers[i];
            descriptionParts.push(modifier.Name);
        }

        let description = descriptionParts.join(', ');

        return (
            <div key={lineItem.ItemId + '-' + index} className="item">
                <div className="details">
                    <h3>{lineItem.ItemName}</h3>
                    <div className="description">{description}</div>
                    <div className="qty">Quantity: {lineItem.Quantity}</div>
                    {lineItem.Note && (<div className="notes"><strong>Notes:</strong> {lineItem.Note}</div>)}
                </div>
                <div className="price">{CurrencyFormatter.format(lineItem.Total, location.Currency)}</div>
            </div>
        );
    }

    renderLineItems(order, location) {
        return order.LineItems.map((lineItem, index) => this.renderLineItem(lineItem, location, index));
    }

    renderPriceSummary(order, location) {
        var hasTipping = order.TotalTips > 0;
        var hasDiscount = order.TotalDiscounts > 0;
        return (
            <div className="price-summary">
                <div className="txt">
                    Subtotal<br />
                    Tax<br />
                    {hasDiscount && (<span>Discount<br /></span>)}
                    {hasTipping && (<span>Tip<br /></span>)}
                    <strong>Total</strong>
                </div>
                <div className="totals">
                    {CurrencyFormatter.format(order.SubTotal, location.Currency)}<br />
                    {CurrencyFormatter.format(order.TotalTaxes, location.Currency)}<br />
                    {hasDiscount && (<span>{CurrencyFormatter.format(order.TotalDiscounts * -1, location.Currency)}<br /></span>)}
                    {hasTipping && (<span>{CurrencyFormatter.format(order.TotalTips, location.Currency)}<br /></span>)}
                    <strong>{CurrencyFormatter.format(order.GrandTotal, location.Currency)}</strong>
                </div>
            </div>
        );
    }

    renderSpecialNoteBlock(location, order) {
        var fulfillmentType = order.FulfillmentType;
        var settings = null;
        if (location && location.Fulfillments) {
            if (fulfillmentType == 'PICKUP') {
                settings = location.Fulfillments.Pickup;
            } else if (fulfillmentType == 'CURBSIDE') {
                settings = location.Fulfillments.Curbside;
            } else if (fulfillmentType == 'DELIVERY') {
                settings = location.Fulfillments.Delivery;
            } else if (fulfillmentType == 'DINEIN') {
                settings = location.Fulfillments.DineIn;
            }
        }

        var message = null;
        if (settings && settings.ConfirmationMessage) {
            message = settings.ConfirmationMessage;
        } else if (this.props.note) {
            message = this.props.note;
        } else {
            return null;
        }

        return (
            <div className="inner">
                <strong>Special Notes:</strong> {message}
            </div>
        );
    }

    render() {
        const orderId = this.props.match.params.orderId;

        if (!orderId) {
            return (<Redirect to="/" />);
        }

        const order = this.props.ordersById[orderId];

        if (!order) {
            return (<Redirect to="/" />);
        }

        const location = this.props.locationsById[order.LocationId];

        if (!location) {
            return (<Redirect to="/" />);
        }

        const pickupDateTime = moment(order.PickupAtUtc).tz(location.Timezone).format("LLLL");

        return (
            <div className="confirmation">
                <Hero message="Confirmation" />
                <div className="body">
                    <div className="container">
                        <div className="intro">
                            <p>Your payment has gone through. Below are details about your order.</p>
                        </div>
                        <div className="panels">
                            <div className="col-third what">
                                <div className="title">
                                    <h2>What</h2>
                                    <p>Below is what you have purchased:</p>
                                </div>
                                <div className="inner">
                                    {this.renderLineItems(order, location)}
                                    {this.renderPriceSummary(order, location)}
                                </div>
                            </div>
                            <div className="col-third">
                                <div className="title">
                                    <h2>When</h2>
                                    <p>Your order will be completed at the time below.</p>
                                </div>
                                <div className="inner">
                                    <p><strong>{pickupDateTime}</strong></p>
                                </div>
                                {this.renderSpecialNoteBlock(location, order)}
                            </div>
                            <div className="col-third">
                                <div className="title">
                                    <h2>Where</h2>
                                    <p>You can pickup your order at the below address:</p>
                                </div>
                                <div className="inner">
                                    {this.formatAddress(location.Address)}
                                    {this.formatPhone(location)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {

    const orders = state.history.orders;
    var ordersById = orders.reduce((o, value) => { o[value.Id] = value; return o; }, {});

    const locations = state.location.locations;
    var locationsById = locations.reduce((o, value) => { o[value.Id] = value; return o; }, {});

    return {
        ordersById: ordersById,
        locationsById: locationsById,
        note: state.content.confirmation.note,
    }
};

const mapDispatchToProps = (dispatch) => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(OrderConfirmationScreen);