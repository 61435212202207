import React from 'react';

export default class Hero extends React.Component {
    render() {
        var imageUrl = global.imagesPath + 'header-bg.jpg';
        var classes = ['hero'];
        if (this.props.small) {
            classes.push('small');
        }

        
        return (
            <div className={classes.join(' ')} style={{backgroundImage: "url(" + imageUrl + ")"}}>
                <h1>{this.props.message}</h1>
            </div>
        );
    }
}