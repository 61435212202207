import React from 'react';
import { default as InputError } from './inputError';

export default class PaymentForm extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            cardNumberError: null,
            expirationDateError: null,
            cvvError: null,
            postalCodeError: null,
            card: null
        };
    }

    goBack() {
      if (this.props.onBack) {
          this.props.onBack();
      }
    }

    async requestCardNonce() {
      try {
        const tokenResult = await this.tokenize(this.state.card);
        // console.log('Token Result: ', tokenResult);
        
        let postalCode = null;
        if (tokenResult.details.card.billing) {
          postalCode = tokenResult.details.card.billing.postalCode;
        } 
  
        if (postalCode === null) {
          postalCode = tokenResult.details.billing.postalCode;
        }
  
        let cardData = {
          billing_postal_code: postalCode,
          card_brand: tokenResult.details.card.brand,
          last_4: tokenResult.details.card.last4,
          exp_month: tokenResult.details.card.expMonth,
          exp_year: tokenResult.details.card.expYear
        }
  
        this.onTokenize(tokenResult.token, cardData);
      } catch (e) {
        // console.log(JSON.stringify(e));
      }
    }

    async init() {
      if (!window.Square) {
        throw new Error('Square.js failed to load properly');
      }

      let payments;
      try {
        payments = window.Square.payments(this.props.applicationId, this.props.locationId);
      } catch(e) {
       //  console.log(JSON.stringify(e));
        return;
      }

      let card;
      try {
        card = await this.initializeCard(payments);

        this.setState({card: card});
      } catch (e) {
        // console.error('Initializing Card failed', e);
        return;
      }
    }

    onNonce(nonce, cardData) {
        if (this.props.onNonce) {
            this.props.onNonce(nonce, cardData);
        }
    }

    onTokenize(token, card) {
      if (this.props.onTokenize) {
        this.props.onTokenize(token, card)
      }
    }

    async initializeCard(payments) {
      const card = await payments.card();
      await card.attach('#card-container');

      return card;
    }

    async tokenize(paymentMethod) {
      const tokenResult = await paymentMethod.tokenize();
      if (tokenResult.status === 'OK') {
        // console.log('Token/Nonce: ', tokenResult.token);
        return tokenResult;
      }
    }

    componentWillUnmount() {
      let card = this.state.card;
      if (card) {
        card.destroy();
        this.setState({card: null});
      }
    }

    async componentDidMount() {
        this.init();
    }    

    render() {
        return (
          <section>
            <h3>Card Info</h3>
            <div id="card-container" key="creditCardForm">
            </div>
            <div className="fields-side-by-side">
                  {this.props.showBack && (<button className="btn btn-primary" onClick={() => this.goBack()}>Back</button>)}
                  <button className="btn btn-primary" onClick={() => this.requestCardNonce()}>Add New Card</button>
              </div>
          </section>
        );
    }
}