import React from 'react';
import { connect } from 'react-redux';
import { EmailField, TextField, PasswordField, PhoneField, AccountHeader, Checkbox } from '../components';
import { Validation, ApiService } from '../lib';

const FirstNameValidationRule = {
  input: 'firstName',
  output: 'firstNameErrorMessage',
  stopOnError: true,
  rules: [
      {
          rule: Validation.rules.notEmpty,
          message: 'First Name is required'
      }      
  ]
};

const LastNameValidationRule = {
  input: 'lastName',
  output: 'lastNameErrorMessage',
  stopOnError: true,
  rules: [
      {
          rule: Validation.rules.notEmpty,
          message: 'Last Name is required'
      }      
  ]
};

const PhoneValidationRule = {
  input: 'phoneNumber',
  output: 'phoneNumberErrorMessage',
  stopOnError: true,
  rules: [
      {
          rule: Validation.rules.notEmpty,
          message: "Phone Number is required"
      },
      {
          rule: Validation.rules.phoneNumber,
          message: "Phone Number is not valid"
      }
  ]
};

const EmailValidationRule = {
  input: 'email',
  output: 'emailErrorMessage',
  stopOnError: true,
  rules: [
      {
          rule: Validation.rules.notEmpty,
          message: "Email Address is required"
      },
      {
          rule: Validation.rules.email,
          message: "Email Address is not valid"
      }
  ]
};

const PasswordValidationRule = {
  input: 'password',
  output: 'passwordErrorMessage',
  stopOnError: true,
  rules: [
    {
      rule: Validation.rules.notEmpty,
      message: 'Password is required',
      condition: (input, model, rule) => !!model.passwordConfirmation
    }      
  ]
};

const PasswordConfirmationValidationRule = {
  input: 'passwordConfirmation',
  output: 'passwordConfirmationErrorMessage',
  stopOnError: true,
  rules: [
    {
      rule: Validation.rules.notEmpty,
      message: 'Password Confirmation is required',
      condition: (input, model, rule) => !!model.password
    },
    {
      rule: Validation.rules.equalsOtherInput,
      message: 'Password Confirmation must match Password',
      other: 'password',
      condition: (input, model, rule) => !!model.password || !!model.passwordConfirmation
    }
  ]
};

class ProfileScreen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            firstName: null,
            lastName: null,
            phoneNumber: null,
            password: '',
            passwordConfirmation: '',
            textNotificationOptIn: null,
            firstNameErrorMessage: null,            
            lastNameErrorMessage: null,            
            phoneNumberErrorMessage: null,
            passwordErrorMessage: null,
            passwordConfirmationErrorMessage: null,
            updatePending: false,
            initial: {
                firstName: null,
                lastName: null,
                email: null,
                phoneNumber: null
            }
        };
    }

    componentDidMount() {
        this.props.getProfile()
            .then((data) => {
                this.setState({
                    firstName: data.firstName,
                    lastName: data.lastName,
                    email: data.email,
                    phoneNumber: data.phoneNumber,
                    initial: {
                        firstName: data.firstName,
                        lastName: data.lastName,
                        email: data.email,
                        phoneNumber: data.phoneNumber
                    }
                });
            });
    }

    onTextChanged(e, fieldName) {
        this.setState({[fieldName]: e.target.value});
    }

    onUpdatePressed() {
        var rules = [ FirstNameValidationRule, LastNameValidationRule ];

        if (this.props.usernameMode == 'Email' && this.props.passwordMode == 'UserDefined') {
          rules = [ ...rules, PhoneValidationRule, PasswordValidationRule, PasswordConfirmationValidationRule];
        } else if (this.props.usernameMode == 'Email' && this.props.passwordMode == 'OneTime') { 
            rules = [ ...rules, PhoneValidationRule];
        } else if (this.props.usernameMode == 'Phone' && this.props.passwordMode == 'UserDefined') { 
            rules = [ ...rules, EmailValidationRule, PasswordValidationRule, PasswordConfirmationValidationRule];
        } else if (this.props.usernameMode == 'Phone' && this.props.passwordMode == 'OneTime') { 
            rules = [ ...rules, EmailValidationRule];
        }

        var isValid = Validation.isValid(this.state, rules, (newState) => this.setState(newState));
        if (!isValid) {
            return;
        }

        this.setState({updatePending: true});

        var textNotificationOptIn = null;
        if (this.props.supportsTextNotifications) {
          if (this.state.textNotificationOptIn !== null) {
            textNotificationOptIn = this.state.textNotificationOptIn;
          } else {
            textNotificationOptIn = this.props.textNotificationOptIn;
          }        
        }

        var model = {
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            email: this.state.email,
            phoneNumber: this.state.phoneNumber,
            password: this.state.password,
            confirmPassword: this.state.passwordConfirmation,
            textNotificationOptIn: textNotificationOptIn
        };

        this.props.update(model)
            .then(() => {
                this.setState({
                    updatePending: false,
                    password: '',
                    passwordConfirmation: '',
                    initial: {
                        firstName: model.firstName,
                        lastName: model.lastName,
                        phoneNumber: model.phoneNumber,
                        email: model.email
                    }
                });
                this.props.toast.success('Profile updated!');
            })
            .catch((reason) => {
                this.setState({updatePending: false});
                this.props.toast.error(reason);
            });
    }    

    render() {
        return (
            <div className="">
                <AccountHeader />
                <div className="body my-account-pages">
                    <div className="container">
                        {!this.state.updatePending && this.renderForm()}
                        {this.state.updatePending && this.renderPending()}
                    </div>
                </div>
            </div>
        );
    }

    renderPending() {
      return (
          <div className="form col-half center-block text-center">
              <i className="fas fa-spinner fa-pulse fa-5x"></i>
          </div>
      );
  }

    renderForm() {
      var textNotificationOptIn = null;
      if (this.state.textNotificationOptIn !== null) {
        textNotificationOptIn = this.state.textNotificationOptIn;
      } else {
        textNotificationOptIn = this.props.textNotificationOptIn;
      }    

      var textNotificationOptInField = null;
      if (this.props.supportsTextNotifications) {
        textNotificationOptInField = (
          <div className="input full-size">
            <Checkbox label="Send me order updates via text message" 
              value={true} 
              checked={textNotificationOptIn} 
              onEnterPress={() => this.onUpdatePressed()}
              onCheck={(value) => { this.setState({textNotificationOptIn: value}) }} />
          </div>
        );
      }

        return (
            <div className="form col-half center-block">
                <div className="input full-size">
                    <TextField 
                        name="firstName"
                        placeholder="First Name" 
                        defaultValue={this.state.initial.firstName}
                        onChange={(e) => this.onTextChanged(e, 'firstName')} 
                        onEnterPress={()=>this.onUpdatePressed()}
                        errorMessage={this.state.firstNameErrorMessage} />
                </div>
                <div className="input full-size">
                    <TextField 
                        name="lastName"
                        placeholder="Last Name" 
                        defaultValue={this.state.initial.lastName}
                        onChange={(e) => this.onTextChanged(e, 'lastName')} 
                        onEnterPress={()=>this.onUpdatePressed()}
                        errorMessage={this.state.lastNameErrorMessage} />
                </div>
                <div className="input full-size">
                    <EmailField 
                        name="email"
                        placeholder="Email Address" 
                        defaultValue={this.state.initial.email}
                        disabled={this.props.usernameMode == 'Email'}
                        onChange={(e) => this.onTextChanged(e, 'email')} 
                        onEnterPress={()=>this.onUpdatePressed()}
                        errorMessage={this.state.emailErrorMessage}                         
                        />
                </div>
                <div className="input full-size">
                    <PhoneField 
                        name="phoneNumber"
                        placeholder="Phone Number" 
                        defaultValue={this.state.initial.phoneNumber}
                        disabled={this.props.usernameMode == 'Phone'}
                        onChange={(e) => this.onTextChanged(e, 'phoneNumber')} 
                        onEnterPress={()=>this.onUpdatePressed()}
                        errorMessage={this.state.phoneNumberErrorMessage} 
                        underMessage={this.props.usernameMode == 'Email' ? "(in case we need to reach you about your order)" : null}
                        />
                </div>             
                {this.props.passwordMode == 'UserDefined' && 
                  <div className="input full-size">
                      <PasswordField 
                          name="password"
                          placeholder="Password" 
                          value={this.state.password}
                          onChange={(e) => this.onTextChanged(e, 'password')} 
                          onEnterPress={()=>this.onUpdatePressed()}
                          errorMessage={this.state.passwordErrorMessage} />
                  </div>
                }
                {this.props.passwordMode == 'UserDefined' && 
                  <div className="input full-size">
                      <PasswordField 
                          name="passwordConfirm"
                          placeholder="Confirm Password"
                          value={this.state.passwordConfirmation}
                          onChange={(e) => this.onTextChanged(e, 'passwordConfirmation')}
                          onEnterPress={()=>this.onUpdatePressed()}
                          errorMessage={this.state.passwordConfirmationErrorMessage} />
                  </div>
                }
                {textNotificationOptInField}                
                <div className="button full-size">
                    <button className="btn btn-primary" onClick={()=>this.onUpdatePressed()}>Update Profile</button>
                </div>
            </div>
        );
    }

    renderPending() {
        return (
            <div className="form col-half center-block text-center">
                <i className="fas fa-spinner fa-pulse fa-5x"></i>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
      supportsTextNotifications: state.catalog.data.HasTextNotifications,
      textNotificationOptIn: state.auth.notifications.optIn === null ? false : state.auth.notifications.optIn,
      usernameMode: state.catalog.data.UsernameMode,
      passwordMode: state.catalog.data.PasswordMode
    }
  };
  

const mapDispatchToProps = (dispatch) => ({
    update: (model) => {
      return new Promise((resolve, reject) => {
        ApiService.instance.accountUpdate(model, (error, data, response) => {
          if (error) {
            var message = 'Could not update profile!';
            if (response && response.body && response.body.length && response.body[0].Errors && response.body[0].Errors.length) {
              message = response.body[0].Errors[0];
            }
            reject(message);
            return;
          }

          var updateResult = data;
          ApiService.instance.accountStatus({}, (e, data, response) => {
            dispatch({type: 'AUTH_STATUS', data: data });
            resolve(updateResult);
          });
        });
      });
    },
    getProfile: () => {
        return new Promise((resolve, reject) => {
            ApiService.instance.getProfile((error, data, response) => {
                if (error) {
                    reject(error);
                    return;
                }
                resolve(data);
            });
        });
    }
  });
  

export default connect(mapStateToProps, mapDispatchToProps)(ProfileScreen);