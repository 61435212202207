import React from 'react';
import { connect } from 'react-redux'; 
import { default as PaymentForm } from './paymentForm';
import { default as TextField } from './textField';
import { default as Checkbox } from './checkbox';
import { default as RadioButton } from './radioButton';
import { ApiService, ZipcodeService, Validation, CardService } from '../lib';

const ValidationRules = [
    {
      input: 'addressLine1',
      output: 'addressLine1ErrorMessage',
      stopOnError: true,
      rules: [
        {
          rule: Validation.rules.notEmpty,
          message: 'Address Line 1 is required'
        }      
      ]
    },
    {
        input: 'city',
        output: 'cityErrorMessage',
        stopOnError: true,
        rules: [
          {
            rule: Validation.rules.notEmpty,
            message: 'City is required'
          }      
        ]
      },
      {
        input: 'state',
        output: 'stateErrorMessage',
        stopOnError: true,
        rules: [
          {
            rule: Validation.rules.notEmpty,
            message: 'State is required'
          }      
        ]
      }
]; 


class CreditCardForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            addressLine1: null,
            addressLine2: null,
            city: null,
            state: null,
            remember: false,
            changeCardStage: 0,
            addressLine1ErrorMessage: null,
            addressLine2ErrorMessage: null,
            cityErrorMessage: null,
            stateErrorMessage: null,
            nextCard: null
        }
    }

    componentDidMount() {
        if (!this.props.selected && this.state.changeCardStage == 0 && this.props.cards.length > 0) {
            if (this.props.cards.length>0){
                this.setState({
                    nextCard: this.props.cards[0]
                });
            }
            this.setState({
                changeCardStage: 1
            });
        }
    }

    onNonce(nonce, cardData) {
        var model = {
            nonce: nonce,
            cardData: cardData
        };

        if (cardData && cardData.billing_postal_code) {
            var postalCode = cardData.billing_postal_code;
            var stateName = ZipcodeService.instance.lookupState(postalCode);
            if (stateName) {
                this.setState({state: stateName});
            }
        }
        this.props.pendingCard(model);
    }

    onTokenize(token, cardData) {
        // debugger;

        // var model = {
        //     nonce: tokenResult,
        //     cardData: card
        // };

        // this.props.pendingCard(model);
        this.onNonce(token, cardData);
    }

    renderCreditCardForm() {
        var payment = this.props.location.Payment;
        var showBack = this.props.cards.length > 0 || (this.props.transient && this.props.transient.nonce && !this.props.transient.id);
        return (
            <PaymentForm 
                key="paymentForm" 
                locationId={payment.LocationId} 
                applicationId={payment.ApplicationId} 
                onNonce={(nonce, cardData) => this.onNonce(nonce, cardData)} 
                onTokenize={(tokenResult, card) => this.onTokenize(tokenResult, card)}
                onBack={() => this.goBackFromCardForm()} 
                showBack={showBack} />
        );
    }

    onTextChanged(e, fieldName) {
        this.setState({[fieldName]: e.target.value});
    }    

    setBillingAddress() {
        var isValid = Validation.isValid(this.state, ValidationRules, (newState) => this.setState(newState));
        if (!isValid) {
            return;
        }

        this.addCard(this.state.remember);
    }

    addCard(remember) {
        var countryCode = this.props.countryCode;

        var card = {
            ...this.props.pendingCardModel,
            remember: remember
        };

        var postalCode = card.cardData.billing_postal_code;

        card.address = {
            address1: this.state.addressLine1,
            address2: this.state.addressLine2,
            city: this.state.city,
            state: this.state.state,
            postalCode: postalCode,
            countryCode: countryCode
        };

        this.setState({pending: true});

        this.props.registerCard(card, card.remember)
        .then(() => {
            this.setState({pending: false, changeCardStage: 0, nextCard: null});
        })
        .catch((reason) => {
            this.props.toast.error('There was a problem adding this card. Please try again or try a different card.');
            this.setState({ pending: false });
        });        
    }

    goBackToCardForm() {
        var initialState = {};
        for(var i=0;i<ValidationRules.length;i++) {
            initialState[ValidationRules[i].output] = null;
        }
        
        // reset the initial state to blank for all errors
        this.setState(initialState);

        this.props.pendingCard(null);
    }

    goBackFromCardForm() {
        var hasCardsInList = this.props.cards.length > 0 || (this.props.transient && this.props.transient.nonce && !this.props.transient.id);
        if (hasCardsInList) {
            this.setState({ changeCardStage: 1 });
        }  else {
            this.setState({ changeCardStage: 0 });
        }
    }

    renderBillingAddressForm() {
        var postalCode = this.props.pendingCardModel.cardData.billing_postal_code;        
        var stateName = ZipcodeService.instance.lookupState(postalCode);
        var saveCardField = null;
        if (this.props.isLoggedIn) {
            saveCardField = (
                <div className="input full-size">
                    <Checkbox label="Save This Card" value={true} checked={this.state.remember} onCheck={(value) => { this.setState({remember: value}) }} />
                </div>
            );
        }
        return (
            <section key="billingAddressForm">
                <h3>Billing Address</h3>
                <div className="save-card-box">
                    <div className="input full-size">
                        <TextField 
                            name="addressLine1"
                            placeholder="Address Line 1" 
                            onChange={(e) => this.onTextChanged(e, 'addressLine1')} 
                            onEnterPress={() => this.setBillingAddress()}
                            errorMessage={this.state.addressLine1ErrorMessage} />
                    </div>
                    <div className="input full-size">
                        <TextField 
                            name="addressLine2"
                            placeholder="Address Line 2" 
                            onChange={(e) => this.onTextChanged(e, 'addressLine2')} 
                            onEnterPress={() => this.setBillingAddress()}
                            errorMessage={this.state.addressLine2ErrorMessage} />
                    </div>
                    <div className="fields-side-by-side">
                        <div className="input full-size">
                            <TextField 
                                name="city"
                                placeholder="City" 
                                onChange={(e) => this.onTextChanged(e, 'city')} 
                                onEnterPress={() => this.setBillingAddress()}
                                errorMessage={this.state.cityErrorMessage} />
                        </div>
                        <div className="input full-size">
                            <TextField 
                                defaultValue={stateName}
                                name="state"
                                placeholder="State" 
                                onChange={(e) => this.onTextChanged(e, 'state')} 
                                onEnterPress={() => this.setBillingAddress()}
                                errorMessage={this.state.stateErrorMessage} />
                        </div>
                        <div className="input full-size">
                            <TextField 
                                name="postalCode"
                                placeholder="Postal Code" 
                                defaultValue={postalCode}
                                disabled
                                />
                        </div>
                    </div>
                    {saveCardField}                    
                    <div className="fields-side-by-side">
                        <button className="btn btn-primary" onClick={() => this.goBackToCardForm()}>Back</button>
                        <button className="btn btn-primary" onClick={() => this.setBillingAddress()}>Continue</button>
                    </div>
                </div>                
            </section>
        )
    }    

    getCardIcon(brand) {
        if (!brand) {
            return 'credit-card';
        }
        
        switch(brand.toLowerCase()) {
            case 'americanexpress':
            case 'american_express':
            case 'amex':            
                return 'cc-amex';
            case 'discover':
                return 'cc-discover';
            case 'discoverdiners':
                return 'cc-diners-club';
            case 'jcb':
                return 'cc-jcb';
            case 'mastercard':
                return 'cc-mastercard';
            case 'visa':
                return 'cc-visa';
            case 'unionpay':
            default:
                return 'credit-card';
        }
    }

    changeCreditCard() {
        if (this.props.isLoggedIn) {
            this.setState({
                changeCardStage: 1
            });
        } else {
            this.props.removeCard(this.props.selected, this.props.isLoggedIn);
        }
    }

    renderCurrentCard() {
        var card = this.props.selected;
        var cardIcon = this.getCardIcon(card.brand);
        var label = '************' + card.last4;

        var cardClasses = "fab fa-fw fa-3x fa-" + cardIcon;

        return (
        <section key="currentCard">
            <h3>Card Info</h3>
            <div className="current-card">
                <div><i className={cardClasses}></i> <span>{label}</span> <small>Exp {card.expMonth}/{card.expYear}</small></div>
                <div><a href="javascript://" className="btn btn-inline btn-primary change-it-btn" onClick={() => this.changeCreditCard()}><span>Change it</span></a></div>
            </div>
        </section>        
        );
    }

    renderCard(card) {
        var cardIcon = this.getCardIcon(card.brand);
        var label = '************' + card.last4;        
        var cardClasses = "fab fa-2x fa-" + cardIcon;

        var transientIcon = null;
        if (!card.id && card.nonce)  {
            transientIcon = (<i className="far fa-clock" />);
        }

        var thisCardKey = card.id || card.nonce;
        var selectedCardKey = null;
        if (this.state.nextCard) {
            selectedCardKey = this.state.nextCard.id || this.state.nextCard.nonce;
        } else if (this.props.selected) {
            selectedCardKey = this.props.selected.id || this.props.selected.nonce;
        }
        var isChecked = thisCardKey == selectedCardKey;        

        label = (<div><i className={cardClasses}></i> {label} <small>Exp {card.expMonth}/{card.expYear}</small> {transientIcon}</div>);

        var detail = (<i className="far fa-times-circle fa-2x icon" onClick={() => {this.onCardListRemoveClicked(card)}}></i>);

        return (
            <div className="card" key={thisCardKey}>
                <RadioButton value={thisCardKey} checked={isChecked} label={label} detail={detail} onSelect={(value) => { this.onCardListSelectClicked(card) }} />
            </div>
        )
    }

    onCardListSelectClicked(card) {
        this.setState({
            nextCard: card
        });
        //
    }

    onCardListRemoveClicked(card) {
        var isTransient = !card.id && card.nonce;
        var savedCardCount = this.props.cards.length;
        var isLastCard = (isTransient && savedCardCount == 0) || savedCardCount == 1 && !isTransient;
        this.props.removeCard(card, this.props.isLoggedIn);

        // if this was the last card, force the user back to the "main" flow
        // this should then show the new card chunk
        if (isLastCard) {
            this.setState({
                changeCardStage: 0
            });
        }        
    }

    onCardListBackClicked() {
        var hasValidCard = (!!this.state.nextCard) || (!!this.props.selected);
        if (!hasValidCard) {
            return;
        }

        if (this.state.nextCard) {
            this.props.selectCard(this.state.nextCard);
        }

        this.setState({
            changeCardStage: 0,
            nextCard: null
        });
    }

    onCardListAddClicked() {
        this.setState({
            changeCardStage: 2
        });
    }

    renderCardList() {
        var transientCard = null;
        if (this.props.transient && this.props.transient.nonce && !this.props.transient.id) {
            transientCard = this.renderCard(this.props.transient);
        }

        var hasNextCard = (!!this.state.nextCard) || (!!this.props.selected);

        return (
            <section key="cardList" className="card-list">
                <h3>Select Your Card</h3>
                <div className="card-list">
                    {this.props.cards.map(r => this.renderCard(r))}
                    {transientCard}
                </div>
                <div className="fields-side-by-side">
                    <button className={'btn btn-primary ' + (hasNextCard ? '' : 'btn-inactive') } disabled={!hasNextCard} onClick={() =>{ this.onCardListBackClicked() }}>Use this Payment Method</button>
                    <div className="at"> Or </div>
                    <button className="btn btn-primary" onClick={() => { this.onCardListAddClicked() }}>Add Card</button>
                </div>                
            </section>
        );
    }

    render() {
        var parts = [];

        if (this.props.selected && this.state.changeCardStage == 0) {
            parts.push(this.renderCurrentCard());
        } else if (this.state.changeCardStage == 1) {
            parts.push(this.renderCardList());
        } else if (this.props.pendingCardModel) {
            parts.push(this.renderBillingAddressForm());
        } else {
            parts.push(this.renderCreditCardForm());
        }

        return (
            <div>
                {parts}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        isLoggedIn: state.auth.isLoggedIn,
        countryCode: state.location.currentLocation.Country || 'US',
        location: state.location.currentLocation,
        cards: state.cards.cards,
        selected: state.cards.selectedCard,
        transient: state.cards.transientCard,
        pendingCardModel: state.cards.pendingCard
    }
};

const mapDispatchToProps = (dispatch) => ({
    pendingCard: (card) => {
        dispatch({type:'CARDS_PENDING', card: card});
    },
    registerCard: (fullCard, remember) => {
        return new Promise((resolve, reject) => {
            CardService.instance.registerCard(fullCard, (error, cardSummary, response) =>{
                if (error) {
                    reject(error);
                } else {
                    dispatch({type:'CARDS_REGISTERED', card: cardSummary, remember: remember});
                    resolve(cardSummary);
                }
            })
        });
    },    
    removeCard: (card, isLoggedIn) => {
        if (card.id) {
            ApiService.instance.cardRemove({ cardId: card.id }, (error, data, response) => { });
        }
        
        if (isLoggedIn) {
            // this keeps the secure storage in sync with the redux state            
            CardService.instance.remove(card);
        }
        dispatch({type: 'CARDS_REMOVE', card: card});
    },
    selectCard: (card) => {
        dispatch({type:'CARDS_SELECT', card: card});
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(CreditCardForm);