const CalculationPhase = {
    Subtotal: 'Subtotal',
    Total: 'Total',
    DiscountPercentage: 'DiscountPercentage',
    DiscountAmount: 'DiscountAmount'
}

const CalculationPhaseToInteger = {
    Subtotal: 0,
    Total: 1,
    DiscountPercentage: 2,
    DiscountAmount: 3
}

const InclusionType = {
    Additive: 'Additive',
    Inclusive: 'Inclusive'
}

const InclusionTypeToInteger = {
    Additive: 0,
    Inclusive: 1    
}

class TaxCollection {
    constructor() {
        this._keys = new Map();
        this._taxes = [];
    }

    add(taxes) {
        var changed = false;
        taxes.forEach((tax) => {
            if (!this._keys.has(tax.Id)) {
                this._keys.set(tax.Id, true);
                this._taxes.push(tax);
                changed = true;
            }
        });
        if (changed)
            this._taxes.sort((a,b) => this.sorter(a,b));
    }

    forEach(... args) {
        this._taxes.forEach(... args);
    }

    [Symbol.iterator]() {
        return this._taxes.values();
    }

    sorter(left, right) {
        if (left.CalculationPhase != right.CalculationPhase) {
            var l = CalculationPhaseToInteger[left.CalculationPhase];
            var r = CalculationPhaseToInteger[right.CalculationPhase];
            return l - r;
        }

        var leftPercentage = left.Percentage === null || left.Percentage === undefined ? null : parseFloat(left.Percentage);
        var rightPercentage = right.Percentage === null || right.Percentage === undefined ? null : parseFloat(right.Percentage);

        var equalByCompare = leftPercentage == null || rightPercentage == null ? leftPercentage == rightPercentage : this.compare(leftPercentage, rightPercentage) == 0;

        if (equalByCompare) {
            return this.compare(left.Id, right.Id);
        }
        if (leftPercentage == null) {
            return 1;
        }
        if (rightPercentage != null)
            return this.compare(leftPercentage, rightPercentage);
        return -1;
    }

    getValueOf(value) {
        if (value && typeof value.valueOf === "function") {
            value = value.valueOf();
        }
        return value;
    }

    compare (a, b) {
        // unbox objects, but do not confuse object literals
        // mercifully handles the Date case
        a = this.getValueOf(a);
        b = this.getValueOf(b);
        if (a === b)
            return 0;
        var aType = typeof a;
        var bType = typeof b;
        if (aType === "number" && bType === "number")
            return a - b;
        if (aType === "string" && bType === "string")
            return a < b ? -Infinity : Infinity;
            // the possibility of equality elimiated above
        if (a && typeof a.compare === "function")
            return a.compare(b);
        // not commutative, the relationship is reversed
        if (b && typeof b.compare === "function")
            return -b.compare(a);
        return 0;
    }
}

class ItemCalculator {
    constructor(baseAmount, quantity, taxes) {
        this._baseAmount = baseAmount;
        this._quantity = quantity;
        this._grossAmount = baseAmount * quantity;

        var taxMap = {};
        taxes.forEach((tax) => {
            taxMap[tax.Id] = tax;
        });

        this._taxes = taxMap;

        this._perAdjustment = {};
        this._perPhase = {};
    }

    get quantity() {
        return this._quantity;
    }

    get basis() {
        if (this._basis === undefined) {
            var inclusiveTotalRate = 0;
            var inclusiveSubtotalRate = 0;
            var additiveSubtotalRate = 0;

            Object.values(this._taxes).forEach((tax) => {
                if (tax.CalculationPhase === undefined || tax.InclusionType === undefined)
                    return;
                if (tax.CalculationPhase === null || tax.InclusionType === null)
                    return;
                var percentage = parseFloat(tax.Percentage) / 100;
                if (tax.InclusionType == InclusionType.Inclusive) {
                    if (tax.CalculationPhase == CalculationPhase.Subtotal)
                        inclusiveSubtotalRate += percentage;
                    else if (tax.CalculationPhase == CalculationPhase.Total)
                        inclusiveTotalRate += percentage;                        
                } else if (tax.InclusionType == InclusionType.Additive) {
                    if (tax.CalculationPhase == CalculationPhase.Subtotal)
                        additiveSubtotalRate += percentage;
                }                
            });

            var divisor = 1 + inclusiveSubtotalRate + inclusiveTotalRate * (1 + inclusiveSubtotalRate + additiveSubtotalRate);
            
            this._basis = this.subTotal / divisor;
        }
        return this._basis;
    }

    get grossAmount() {
        return this._grossAmount;
    }

    get baseAmount() {
        return this._baseAmount;
    }

    get subTotal() {
        return this._grossAmount + this.discountAdjustment;
    }

    get discountAdjustment() {
        var percentageDiscount = (this._perPhase[CalculationPhase.DiscountPercentage] || 0);
        var amountDiscount = (this._perPhase[CalculationPhase.DiscountAmount] || 0);
    
        return percentageDiscount + amountDiscount;
    }

    get totalDiscounts() {
        return this.trunc(this.discountAdjustment);
    }

    get subTotalAmount() {
        return this.trunc(this.subTotal);
    }

    get additiveTaxAdjustment() {
        var sum = 0;
        Object.entries(this._perAdjustment).forEach(([key, value]) => {
            if (this._taxes.hasOwnProperty(key)) {
                var tax = this._taxes[key];
                if (tax.InclusionType == InclusionType.Additive)
                    sum += value;
            }
        });  
        return sum;
    }

    get additiveTaxes() {
        return this.trunc(this.additiveTaxAdjustment);
    }

    get inclusveTaxAdjustment() {
        var sum = 0;
        Object.entries(this._perAdjustment).forEach(([key, value]) => {
            if (this._taxes.hasOwnProperty(key)) {
                var tax = this._taxes[key];
                if (tax.InclusionType == InclusionType.Inclusive)
                    sum += value;
            }
        });  
        return sum;
    }

    get inclusiveTaxes() {
        return this.trunc(this.inclusveTaxAdjustment);
    }

    get totalTaxes() {
        var sum = this.additiveTaxAdjustment + this.inclusveTaxAdjustment;
        return this.trunc(sum);
    }

    get adjustedTotal() {
        var sum = this.subTotal + this.additiveTaxAdjustment;
        return this.trunc(sum);
    }

    roundBasis() {
        var basis = this.subTotal;
        Object.entries(this._perAdjustment).forEach(([key, value]) => {
            if (this._taxes.hasOwnProperty(key)) {
                var tax = this._taxes[key];
                if (tax.InclusionType == InclusionType.Inclusive)
                    basis = basis - value;
            }
        });        
        this._basis = basis;
    }

    addAdjustment(id, phase, amount) {
        this._perPhase[phase] = (this._perPhase[phase] || 0) + amount;
        this._perAdjustment[id] = amount;
    }

    getBasisForCalculationPhase(phase) {
        if (phase == CalculationPhase.DiscountPercentage) {
            var percentageDiscounts = this._perPhase[CalculationPhase.DiscountPercentage] || 0;
            return this._grossAmount + percentageDiscounts;
        } else if (phase == CalculationPhase.DiscountAmount) {
            var amountDiscounts = this._perPhase[CalculationPhase.DiscountAmount] || 0;
            return this.getBasisForCalculationPhase(CalculationPhase.DiscountPercentage) + amountDiscounts;
        } else if (phase == CalculationPhase.Subtotal) {
            return this.basis;
        } else if (phase == CalculationPhase.Total) {
            var subTotalTaxes = this._perPhase[CalculationPhase.Subtotal] || 0;
            return this.getBasisForCalculationPhase(CalculationPhase.Subtotal) + subTotalTaxes;
        }
        throw 'Invalid Phase: ' + phase.toString();
    }

    trunc(x) {
        var n = x - x%1;
        return n===0 && (x<0 || (x===0 && (1/x !== 1/0))) ? -0 : n;
    };
}

export default class OrderCalculator {

    constructor(rawItems, coupon, rounding) {
        this._items = rawItems;
        this._rounding = rounding;
        this._collectedTaxes = new TaxCollection();
        this._collectedPerTax = {};
        this._calculators = {};
        this._calculated = false;
        this._coupon = coupon;

        this.calculate();
    }

    calculate() {
        if (this._calculated)
            return;


        this.cascadeCoupon();
        this.collectTaxes();
        this.calculateTaxes(CalculationPhase.Subtotal);
        this.calculateTaxes(CalculationPhase.Total);

        this._items.forEach((item) => {
            var calculator = this.getItemCalculator(item);
            calculator.roundBasis();
        });

        this._calculated = true;
    }

    getItemCalculator(rawItem) {
        var key = rawItem.key;
        var calculator = this._calculators[key];
        if (!calculator) {
            calculator = new ItemCalculator(rawItem.baseAmount, rawItem.quantity, rawItem.taxes);
            this._calculators[key] = calculator;
        }
        return calculator;
    }

    getItemCalculation(key) {
        return this._calculators[key];
    }

    cascadeCoupon() {
        var coupon = this._coupon;
        if (!coupon)
            return;

        var items = [];
        if (coupon.Type == 'EntireOrder') {
            items = this._items;
        } else if (coupon.Type == 'Items') {
            items = this._items.filter((item) => {
                if (coupon.Variations && coupon.Variations.length > 0) {
                    if (coupon.Variations.indexOf(item.variationId) >= 0)
                        return true;
                }

                if (coupon.Items && coupon.Items.length > 0) {
                    if (coupon.Items.indexOf(item.itemId) >= 0) {
                        return true;
                    }
                }

                return false;
            });
        } else if (coupon.Type == 'Categories') {
            items = this._items.filter((item) => {
                if (coupon.Categories && coupon.Categories.length > 0) {
                    if (coupon.Categories.indexOf(item.categoryId) >= 0)
                        return true;
                }

                return false;
            }); 
                       
        }
        if (items.length==0) {
            return;
        }
        this.cascadeCouponToItems(coupon, items);        
    }

    cascadeCouponToItems(coupon, items) {
        var percentage = null;
        var amount = null;
        var max = null;
        if (coupon.Type != 'EntireOrder') {
            items=items.sort(function(a,b){return a.baseAmount-b.baseAmount});
            items=[items[0]];
            max= items[0].baseAmount;
        } 
           
        if (coupon.Percentage) {
            percentage = parseFloat(coupon.Percentage);
            if (coupon.MaximumMoney) {
                
                if (max!==null){
                    max=Math.min(coupon.MaximumMoney, max*percentage/100.0);
                }else {
                    max=coupon.MaximumMoney;
                }
                
            }
            this.cascadePercentageCoupon(coupon.Id, percentage, max, items,coupon.Type);
        } else if (coupon.AmountMoney) {
            amount = coupon.AmountMoney;
            if (coupon.Type == 'EntireOrder') {
                // don't take $1 off every item, only take $1 off until we've reached the actual amount
                // this fixes a $1 off coupon applied to $0.50 item then $0.25, then $0.25, finally $0.00 for all the rest
                max = amount;
            }
            this.cascadeAmountCoupon(coupon.Id, amount, max, items,coupon.Type);
        }
    }

    cascadePercentageCoupon(id, percentage, max, items,couponType) {
        // this coupon will apply to every item until we have "consumed" up to the maximum amount if present
        var applied = 0;
        items.forEach((item) => {
            if (max != null && applied >= max)
                return;
            
            var calculator = this.getItemCalculator(item);
            var basis = calculator.getBasisForCalculationPhase(CalculationPhase.DiscountPercentage);
            var discount = (basis * percentage) / 100;
            if (couponType !== 'EntireOrder') {
                //if coupon is not entire order then only one of the cheapest items will get the discount
                discount=discount/calculator.quantity;   
            }
        
            if (max != null) {
                var newMax = max - applied;
                if (discount > newMax)
                    discount = newMax;
            }
            
            calculator.addAdjustment(id, CalculationPhase.DiscountPercentage, discount * -1);

            applied += discount;
        });
    }

    cascadeAmountCoupon(id, amount, max, items,couponType) {
        // this coupon will apply to every item until we have "consumed" up to the maximum amount if present
        var applied = 0;
        items.forEach((item) => {
            if (max != null && applied >= max)
                return;
            
            var calculator = this.getItemCalculator(item);

            var discount = amount * calculator.quantity;
            if (couponType !== 'EntireOrder') {
                //if coupon is not entire order then only one of the cheapest items will get the discount
                discount = amount * 1.00;
            }

            if (discount > calculator.grossAmount)
                discount = calculator.grossAmount;
        
            if (max != null) {
                var newMax = max - applied;
                if (discount > newMax)
                    discount = newMax;
            }
            
            calculator.addAdjustment(id, CalculationPhase.DiscountAmount, discount * -1);

            applied += discount;
        });
    }

    collectTaxes() {
        this._items.forEach((item) => {
            this._collectedTaxes.add(item.taxes);
        });
    }

    calculateTaxes(phase) {
        var bases = {};
        this._items.forEach((item) => {
            item.taxes.forEach((tax) => {
                if (tax.CalculationPhase == phase) {
                    var calculator = this.getItemCalculator(item);
                    var currentValue = bases[tax.Id] || 0;
                    bases[tax.Id] = currentValue + calculator.getBasisForCalculationPhase(phase);
                }
            });
        });

        this._collectedTaxes.forEach((tax) => {
            if (tax.CalculationPhase == phase) {
                var basis = bases[tax.Id] || 0;
                this._collectedPerTax[tax.Id] = this.collectTaxAdjustment(tax, basis);
            }
        });
    }

    collectTaxAdjustment(tax, basis) {
        var percentage = parseFloat(tax.Percentage) / 100;
        var collected = basis * percentage;
        collected = Math.min(collected, basis);
        collected = this._rounding(collected);
        this.bubbleTaxAcrossItems(tax.CalculationPhase, tax.Id, basis, collected);
        return collected;
    }

    bubbleTaxAcrossItems(phase, taxId, adjustmentBasis, collected) {
        this._items.forEach((item) => {
            if (!item.taxes.some((x) => x.Id == taxId))
                return;
            var itemShare = 0;
            var calculator = this.getItemCalculator(item);
            var itemBasis = calculator.getBasisForCalculationPhase(phase);
            if (adjustmentBasis == 0 || itemBasis == 0){
                itemShare = 0;
            } else {
                itemShare = Math.round(itemBasis / adjustmentBasis * collected);
            }
            calculator.addAdjustment(taxId, phase, itemShare);
            collected = collected - itemShare;
            adjustmentBasis = adjustmentBasis - itemBasis;
        })
    }
}