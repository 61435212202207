import React from 'react';
import { connect } from 'react-redux';
import { CurrencyFormatter, Internationalization, InventoryHelper } from '../lib';
import { FavoriteIcon } from './';

class ItemCard extends React.Component {
    isAllOutOfStock() {
        return InventoryHelper.isItemAllOutOfStock(this.props.item, this.props.variationsById, this.props.inventoryCounts, this.props.cartEntries);
    }

    render() {
        var item = this.props.item;

        var imageSource = null;
        if (item.ImageUrl) {
            imageSource = item.ImageUrl;
        } else {
            imageSource = global.imagesPath + 'item-placeholder.jpg';
        }

        var itemTitle = item.Name;
        var itemDescription = item.Description;

        var min = null;
        var max = null;

        for(var i=0;i<item.Variations.length;i++) {
            var variation = item.Variations[i];
            var price = variation.Price;
            if (min == null || price < min) {
                min = price;
            }
            if (max == null || price > max) {
                max = price;
            }
        }

        var priceLabel = null;
        if (this.isAllOutOfStock()) {
            priceLabel = Internationalization.strings('item_card.out_of_stock');
        } else if (min != null && max != null) {
            if (min == max) {
                priceLabel = CurrencyFormatter.format(min, this.props.currencyCode);
            } else {
                priceLabel = CurrencyFormatter.format(min, this.props.currencyCode) + ' - ' + CurrencyFormatter.format(max, this.props.currencyCode);
            }
        }

        return (
            <div className="item-card">
                <div className="image" style={{backgroundImage: 'url(' + imageSource + ')'}}>
                    
                </div>
                <div className="description">
                    <div className="header">
                        
                        <h2>{itemTitle}</h2>
                        <p>{itemDescription}</p>
                    </div>
                    <div className="footer">
                        <div className="price">{priceLabel}</div>
                        <a href="javascript://" onClick={() => this.addToBagClicked()} className="btn btn-inline btn-primary add-to-bag-btn"><span>{Internationalization.strings('item_card.add_to_bag')}</span></a>
                    </div>
                </div>
            </div>
        );
    }

    addToBagClicked() {
        this.props.select(this.props.item);
    }
}


const mapStateToProps = (state) => {
    return {
        currencyCode: state.location.currentLocation.Currency,
        inventoryCounts: state.inventory.locationVariationMap[state.location.selectedLocationId] || {},
        cartEntries: state.cart.items,
        variationsById: state.catalog.variationsById
    }
};

const mapDispatchToProps = (dispatch) => ({
 select: (item) => {
        dispatch({type: 'CATALOG_SELECT_ITEM', item: item});
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(ItemCard);