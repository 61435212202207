import React from 'react';
import { connect } from 'react-redux';
import { Validation } from '../lib';
import { RadioButton, TextField, Checkbox } from '../components';
import { Internationalization } from '../lib';

const ValidationRulesCurbside = [
    {
        input: 'vehicleMake',
        output: 'vehicleMakeErrorMessage',
        stopOnError: true,
        rules: [
            {
                rule: Validation.rules.notEmpty,
                message: 'Vehicle Make is required'
            }
        ]
    },
    {
        input: 'vehicleModel',
        output: 'vehicleModelErrorMessage',
        stopOnError: true,
        rules: [
            {
                rule: Validation.rules.notEmpty,
                message: 'Vehicle Model is required'
            }
        ]
    },
    {
        input: 'vehicleColor',
        output: 'vehicleColorErrorMessage',
        stopOnError: true,
        rules: [
            {
                rule: Validation.rules.notEmpty,
                message: 'Vehicle Color is required'
            }
        ]
    }
];

class FulfillmentForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            mode: 'edit',   // 'edit' | 'curbside' | 'pickup' | 'display'
            vehicleMake: null,
            vehicleModel: null,
            vehicleColor: null,
            curbsideNotes: null,
            pickupNotes: null,
            dineInDetails: {
                note: '',
                skipNote: false
            },
            fulfillmentType: null,
            vehicleMakeErrorMessage: null,
            vehicleModelErrorMessage: null,
            vehicleColorErrorMessage: null,
            stillWaiting: false,

            initial: {
                vehicleMake: null,
                vehicleModel: null,
                vehicleColor: null,
                curbsideNotes: null,
                pickupNotes: null,
                dineInDetails: {
                    note: '',
                    skipNote: false
                },
            }
        };
    }

    componentDidMount() {
        var stateChange = {};
        var changed = false;

        var vehicleDetails = this.props.vehicleDetails;
        if (vehicleDetails) {
            changed = true;
            stateChange = {
                ...stateChange,
                vehicleMake: vehicleDetails.make,
                vehicleModel: vehicleDetails.model,
                vehicleColor: vehicleDetails.color,
                curbsideNotes: vehicleDetails.note,
                initial: {
                    ...stateChange.initial,
                    vehicleMake: vehicleDetails.make,
                    vehicleModel: vehicleDetails.model,
                    vehicleColor: vehicleDetails.color,
                    curbsideNotes: vehicleDetails.note,
                }
            }
        }

        var pickupSettings = this.props.pickupSettings;
        if (pickupSettings) {
            changed = true;
            stateChange = {
                ...stateChange,
                pickupNotes: pickupSettings.notes,
                initial: {
                    ...stateChange.initial,
                    pickupNotes: pickupSettings.notes
                }
            };
        }

        var dineInDetails = this.props.dineInSettings;
        if (dineInDetails) {
            changed = true;
            stateChange = {
                ...stateChange,
                dineInDetails: {
                    note: dineInDetails.note,
                    skipNote: dineInDetails.skipNote
                },
                initial: {
                    ...stateChange.initial,
                    dineInDetails: {
                        note: dineInDetails.note,
                        skipNote: dineInDetails.skipNote
                    },
                }
            };
        }


        var fulfillmentType = this.props.fulfillmentType;
        if (fulfillmentType) {
            changed = true;
            stateChange = {
                ...stateChange,
                fulfillmentType: fulfillmentType,
            };

            if (fulfillmentType == 'PICKUP' || fulfillmentType == 'DINEIN') {
                stateChange.mode = 'edit';
            } else if (fulfillmentType == 'CURBSIDE') {
                var curbsideSettings = this.props.curbsideSettings;
                if (curbsideSettings && curbsideSettings.vehicleDetails && curbsideSettings.vehicleDetails.make && curbsideSettings.vehicleDetails.model && curbsideSettings.vehicleDetails.color) {
                    stateChange.mode = 'display';
                } else {
                    stateChange.mode = 'curbside';
                }
            }
        }

        var hasPickup = this.props.hasPickupFulfillment;
        var hasCurbside = this.props.hasCurbsideFulfillment;
        var hasDineIn = this.props.hasDineInFulfillment;

        var autoSelectPickup = false;
        var autoSelectDineIn = false;

        if (hasPickup && !hasCurbside && !hasDineIn) {
            changed = true;
            stateChange.fulfillmentType = 'PICKUP';
            autoSelectPickup = true;
        } else if (!hasPickup && hasCurbside && !hasDineIn) {
            changed = true;
            stateChange.fulfillmentType = 'CURBSIDE';
        } else if (hasDineIn && !hasCurbside && !hasPickup) {
            changed = true;
            stateChange.fulfillmentType = 'DINEIN';
            autoSelectDineIn = true;
        } else if (!hasPickup && !hasCurbside && !hasDineIn) {
            changed = true;
            autoSelectPickup = true;
            stateChange.fulfillmentType = 'PICKUP';
        }

        if (changed) {
            this.setState(stateChange);
        }

        if (autoSelectPickup) {
            var pickupNotes = null;
            if (stateChange.pickupNotes) {
                pickupNotes = stateChange.pickupNotes;
            } else if (stateChange.initial && stateChange.initial.pickupNotes) {
                pickupNotes = stateChange.initial.pickupNotes;
            }
            this.props.selectPickupFulfillment(pickupNotes);
        }

        if (autoSelectDineIn) {
            var dineInDetails = null;
            if (stateChange.dineInDetails) {
                dineInDetails = stateChange.dineInDetails;
            } else if (stateChange.initial && stateChange.initial.dineInDetails) {
                dineInDetails = stateChange.initial.dineInDetails;
            }

            this.props.selectDineInFulfillment(dineInDetails);
        }
    }

    onTextChanged(e, fieldName) {
        this.setState({ [fieldName]: e.target.value });
    }

    onBackPressed() {
        this.setState({ mode: 'edit' });
    }


    onUpdatePressed() {
        var validatedState = {
            ...this.state,
        };
        var isValid = Validation.isValid(this.state, ValidationRulesCurbside, (newState) => {
            validatedState = { ...validatedState, ...newState };
            this.setState(newState);
        });
        if (!isValid) {
            return;
        }

        var vehicleDetails = {
            make: this.state.vehicleMake,
            model: this.state.vehicleModel,
            color: this.state.vehicleColor,
            note: this.state.curbsideNotes
        }

        this.props.selectCurbsideFulfillment(vehicleDetails);
        var mode = (this.state.fulfillmentType === 'CURBSIDE') ? 'display' : 'edit';
        this.setState({
            ...validatedState,
            mode: mode,
            initial: {
                vehicleMake: this.state.vehicleMake,
                vehicleModel: this.state.vehicleModel,
                vehicleColor: this.state.vehicleColor,
                curbsideNotes: this.state.curbsideNotes,
                pickupNotes: this.state.pickupNotes,
                dineInDetails: this.state.dineInDetails
            }
        });
    }

    onCurbsideNoteChanged(e) {
        this.setState({ curbsideNotes: e.currentTarget.value });
    }

    onPickupNoteChanged(e) {
        this.setState({ pickupNotes: e.currentTarget.value });
        this.props.selectPickupFulfillment(e.currentTarget.value);
    }

    onDineInNoteChanged(e) {
        var dineInDetails = {
            note: e.currentTarget.value,
            skipNote: this.state.dineInDetails.skipNote
        }

        this.setState({ dineInDetails: { note: dineInDetails.note, skipNote: dineInDetails.skipNote } });

        this.props.selectDineInFulfillment(dineInDetails);
    }

    renderCurbsideEdit() {
        if (!this.props.hasCurbsideFulfillment) {
            return null;
        }

        if (this.state.fulfillmentType != 'CURBSIDE') {
            return null;
        }
        var note = "";
        if (!(this.state.initial.curbsideNotes === undefined && this.state.curbsideNotes === null)) {
            if (this.state.curbsideNotes != null) {
                note = this.state.curbsideNotes;
            } else {
                note = this.state.initial.curbsideNotes || "";
            }

        }

        return (
            <section className="pickup-date-and-time">
                <div className="curbside-form">
                    <h3>Vehicle Details</h3>
                    <div className="input full-size">
                        <TextField
                            name="vehicleMake"
                            value={this.state.vehicleMake}
                            placeholder="Make"
                            defaultValue={this.state.initial.vehicleMake}
                            onChange={(e) => this.onTextChanged(e, 'vehicleMake')}
                            onEnterPress={() => this.onUpdatePressed()}
                            errorMessage={this.state.vehicleMakeErrorMessage} />
                    </div>
                    <div className="input full-size">
                        <TextField
                            name="vehicleModel"
                            placeholder="Model"
                            value={this.state.vehicleModel}
                            defaultValue={this.state.initial.vehicleModel}
                            onChange={(e) => this.onTextChanged(e, 'vehicleModel')}
                            onEnterPress={() => this.onUpdatePressed()}
                            errorMessage={this.state.vehicleModelErrorMessage} />
                    </div>
                    <div className="input full-size">
                        <TextField
                            name="vehicleColor"
                            placeholder="Color"
                            value={this.state.vehicleColor}
                            defaultValue={this.state.initial.vehicleColor}
                            onChange={(e) => this.onTextChanged(e, 'vehicleColor')}
                            onEnterPress={() => this.onUpdatePressed()}
                            errorMessage={this.state.vehicleColorErrorMessage} />
                    </div>
                    <div className="input full-size">
                        <textarea maxLength={150} value={note} onChange={(text) => this.onCurbsideNoteChanged(text)} placeholder="Curbside Pick-Up Instructions"></textarea>
                    </div>
                    <div className="fields-side-by-side">
                        <button className="btn btn-primary" onClick={() => this.onUpdatePressed()}>Continue</button>
                    </div>
                </div>
            </section>
        );
    }

    renderDineInEdit() {
        if (!this.props.hasDineInFulfillment) {
            return null;
        }

        if (this.state.fulfillmentType != 'DINEIN') {
            return null;
        }

        var note = "";
        if (!(this.state.initial.dineInDetails.note === undefined && this.state.dineInDetails.note === null)) {
            if (this.state.dineInDetails.note != null) {
                note = this.state.dineInDetails.note;
            } else {
                note = this.state.initial.dineInDetails.note || "";
            }
        }

        return (
            <section className="pickup-date-and-time">
                <div className="curbside-form">
                    <h3>Dine In</h3>
                    <div className="input full-size">
                        <textarea disabled={this.state.stillWaiting ? true : false}
                            maxLength={150}
                            value={note}
                            onChange={(text) => this.onDineInNoteChanged(text)}
                            placeholder={Internationalization.strings('fulfillment_form.your_table_number')}>
                        </textarea>
                    </div>
                    <div className="input full-size">
                        <Checkbox label={Internationalization.strings('fulfillment_form.form.skip_note')}
                            value={false}
                            checked={this.state.stillWaiting}
                            onCheck={(value) => { this.onstillWaitingChecked(value, note) }} />
                    </div>
                </div>
            </section>
        );
    }

    renderPickupEdit() {
        if (!this.props.hasPickupFulfillment) {
            return null;
        }

        if (this.state.fulfillmentType != 'PICKUP') {
            return null;
        }

        var note = "";
        if (!(this.state.initial.pickupNotes === undefined && this.state.pickupNotes === null)) {
            if (this.state.pickupNotes != null) {
                note = this.state.pickupNotes;
            } else {
                note = this.state.initial.pickupNotes || "";
            }

        }



        return (
            <section className="pickup-date-and-time">
                <div className="curbside-form">
                    <h3>Pick-Up Instructions</h3>
                    <div className="input full-size">
                        <textarea maxLength={150} value={note} onChange={(text) => this.onPickupNoteChanged(text)} placeholder="Please type any extra notes you may have..."></textarea>
                    </div>
                </div>
            </section>
        );
    }

    onstillWaitingChecked(value, note) {
        this.setState({ stillWaiting: value });

        const defaultEmptyNote = 'No Table';

        var dineInDetails = {
            note: value ? defaultEmptyNote : note,
            skipNote: value
        };

        this.props.selectDineInFulfillment(dineInDetails);
    };

    onPickupChange(e, o) {
        this.setState({ fulfillmentType: 'PICKUP' });
        this.props.selectPickupFulfillment(null);
    }

    onCurbsideChange(e, o) {
        this.setState({ fulfillmentType: 'CURBSIDE' });

        if (this.state.vehicleColor !== null && this.state.vehicleMake !== null && this.state.vehicleModel !== null
            && this.state.vehicleColor !== '' && this.state.vehicleMake !== '' && this.state.vehicleModel !== '') {

            var vehicleDetails = {
                make: this.state.vehicleMake,
                model: this.state.vehicleModel,
                color: this.state.vehicleColor,
                note: this.state.curbsideNotes
            }

            this.props.selectCurbsideFulfillment(vehicleDetails);
        }
    }

    onDineInChange(e, o) {
        this.setState({ fulfillmentType: 'DINEIN' });
        var dineInDetails = {};
        this.props.selectDineInFulfillment(dineInDetails);
    }

    renderPickupField(e) {
        if (!this.props.hasPickupFulfillment) {
            return null;
        }

        // if (!this.props.hasCurbsideFulfillment) {
        //     return null;
        // }

        var isChecked = this.state.fulfillmentType === "PICKUP";
        return (
            <div className="input full-size">
                <RadioButton value='pickup' label='Pickup' checked={isChecked} onSelect={() => this.onPickupChange()} />
            </div>
        );
    }

    renderDineInField() {
        if (!this.props.hasDineInFulfillment) {
            return null;
        }

        var isChecked = this.state.fulfillmentType === "DINEIN";
        return (
            <div className="input full-size">
                <RadioButton value='dinein' label='Dine In' checked={isChecked} onSelect={() => this.onDineInChange()} />
            </div>
        );
    }

    renderCurbsideField() {
        if (!this.props.hasCurbsideFulfillment) {
            return null;
        }

        // if (!this.props.hasPickupFulfillment) {
        //     return null;
        // }

        var isChecked = this.state.fulfillmentType === "CURBSIDE";
        return (
            <RadioButton value='curbside' label='Curbside' checked={isChecked} onSelect={() => this.onCurbsideChange()} />
        );
    }
    renderCurbsideDisplay() {
        var notes = this.state.curbsideNotes;
        if (!notes) {
            notes = (<em>(None)</em>);
        }
        return (
            <section className="curbSideForm">
                <h3>Vehicle Details</h3>
                <div className="guest-profile">
                    <div className="detail">
                        <h4>Vehicle Info</h4>
                        <p>{this.state.vehicleColor} {this.state.vehicleMake} {this.state.vehicleModel} </p>
                        <h4>Additional Notes</h4>
                        <p>{notes}</p>
                    </div>
                    <div><a href="javascript://" className="btn btn-inline btn-primary change-it-btn" onClick={() => { this.onChangeClicked() }}><span>Change it</span></a></div>
                </div>
            </section>
        );
    }
    renderDisplayMode() {
        if (this.state.fulfillmentType === 'CURBSIDE') {
            return this.renderCurbsideDisplay();
        }
        if (this.state.fulfillmentType === 'DINEIN') {
            return this.renderDineInEdit();
        }
        if (this.state.fulfillmentType === 'PICKUP') {
            return this.renderPickupEdit();
        }
        if (!this.state.fulfillmentType) {
            return this.renderPicker();
        }
        return null;
    }

    onChangeClicked() {
        this.setState({ mode: "edit" });
    }

    hasMultipleFulfillments() {
        return (this.props.hasCurbsideFulfillment ? 1 : 0)
            + (this.props.hasPickupFulfillment ? 1 : 0)
            + (this.props.hasDineInFulfillment ? 1 : 0)
            >= 2;
    }

    render() {
        if (!this.props.hasPickupFulfillment && !this.props.hasCurbsideFulfillment && !this.props.hasDineInFulfillment) {
            return null;
        }

        var pickup_dineIn_curbside = null;

        if (this.props.hasPickupFulfillment && this.props.hasDineInFulfillment && this.props.hasCurbsideFulfillment) {
            pickup_dineIn_curbside = (<h3>Would you like pickup, dine in, or curbside?</h3>);
        } else if (this.props.hasPickupFulfillment && this.props.hasDineInFulfillment) {
            pickup_dineIn_curbside = (<h3>Would you like pickup or dine in?</h3>);
        } else if (this.props.hasPickupFulfillment && this.props.hasCurbsideFulfillment) {
            pickup_dineIn_curbside = (<h3>Would you like pickup or curbside?</h3>);
        } else if (this.props.hasCurbsideFulfillment && this.props.hasDineInFulfillment) {
            pickup_dineIn_curbside = (<h3>Would you like dine in or curbside?</h3>);
        }

        var selectorForm = null;
        if (this.hasMultipleFulfillments()) {
            selectorForm = (
                <section className="pickup-date-and-time">
                    {pickup_dineIn_curbside}

                    <div className='curbside-form'>
                        {this.renderPickupField()}
                        {this.renderDineInField()}
                        <div className="input full-size">
                            {this.renderCurbsideField()}
                            {this.state.fulfillmentType == 'CURBSIDE' && (<div style={{ paddingTop: 30, fontSize: 16 }}>Please describe your vehicle so we can quickly identify you when you arrive.</div>)}
                        </div>
                    </div>
                </section>
            );
        }

        return (
            <div>
                {selectorForm}
                {this.renderPickupEdit()}
                {this.renderDineInEdit()}
                {(this.state.mode == 'edit') && (this.state.fulfillmentType == 'CURBSIDE') && this.renderCurbsideEdit()}
                {(this.state.mode == 'display') && (this.state.fulfillmentType == 'CURBSIDE') && this.renderCurbsideDisplay()}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    var location = state.location.currentLocation;

    var hasPickupFulfillment = false;
    var hasCurbsideFulfillment = false;
    var hasDeliveryFulfillment = false;
    var hasDineInFulfillment = false;

    if (location && location.Fulfillments) {
        var pickup = location.Fulfillments.Pickup || { Enabled: false };
        var curbside = location.Fulfillments.Curbside || { Enabled: false };
        var delivery = location.Fulfillments.Delivery || { Enabled: false };
        var dineIn = location.Fulfillments.DineIn || { Enabled: false };

        if (pickup.Enabled) {
            hasPickupFulfillment = true;
        }
        if (curbside.Enabled) {
            hasCurbsideFulfillment = true;
        }
        if (delivery.Enabled) {
            hasDeliveryFulfillment = true;
        }
        if (dineIn.Enabled) {
            hasDineInFulfillment = true;
        }
    }

    if (!hasPickupFulfillment && !hasCurbsideFulfillment && !hasDineInFulfillment) {
        hasPickupFulfillment = true;
    }

    return {
        hasPickupFulfillment: hasPickupFulfillment,
        hasCurbsideFulfillment: hasCurbsideFulfillment,
        hasDeliveryFulfillment: hasDeliveryFulfillment,
        hasDineInFulfillment: hasDineInFulfillment,

        vehicleDetails: state.curbside.vehicleDetails,
        fulfillmentType: state.cart.fulfillmentType,
        pickupSettings: state.cart.pickupSettings || {},
        curbsideSettings: state.cart.curbsideSettings || {},
        dineInSettings: state.cart.dineInSettings || {},
    };
};

const mapDispatchToProps = (dispatch) => ({
    selectCurbsideFulfillment: (vehicleDetails) => {
        dispatch({ type: 'CURBSIDE_SET_VEHICLE_DETAILS', vehicleDetails: vehicleDetails });
        dispatch({ type: 'CART_SET_CURBSIDE_FULFILLMENT', vehicleDetails: vehicleDetails });
    },
    selectPickupFulfillment: (notes) => {
        dispatch({ type: 'CART_SET_PICKUP_FULFILLMENT' });
        dispatch({ type: 'SET_PICKUP_SETTINGS_NOTE', notes: notes });
    },
    selectDineInFulfillment: (dineInDetails) => {
        dispatch({ type: 'CART_SET_DINEIN_FULFILLMENT', dineInDetails });
        dispatch({ type: 'SET_DINEIN_SETTINGS_NOTE', note: dineInDetails.note });
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(FulfillmentForm);