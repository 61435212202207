import moment from 'moment-timezone';

export default class LocationService {
    isOpen(location) {
        if (location == null)
            return true;
        const locationTimeZone = location.Timezone;

        const currentTime = moment().tz(locationTimeZone);
        return location.Hours.filter(function(hours) {
            const isToday = hours.DayOfWeek === currentTime.format('dddd');
            const format = 'H:mm:ss';
            const time = moment(currentTime.format(format), format),
                open = moment(hours.Opens, format),
                close = moment(hours.Closes, format).subtract(15, 'minutes');
            const isInHours = time.isBetween(open, close);

            return isToday && isInHours;
        }).length > 0;
    }
}

LocationService.instance = new LocationService();