import React from 'react';
import { connect } from 'react-redux';
import { Hero, CategoryCard, StoreClosedModal, LocationSelectPartial } from '../components';
import { CustomStorage, SortService } from '../lib';

class HomeScreen extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            hasSeenTextNotificationModal: null
        };
    }

    componentDidMount() {
        CustomStorage.get('hasSeenTextNotificationModal') 
        .then((parsedResponse) => { 
            if (parsedResponse === null) {
                parsedResponse = false;
            }
            this.setState({hasSeenTextNotificationModal: parsedResponse});
            this.triggerTextNotificationModal(this.props);
        });        
    }

    componentWillReceiveProps(nextProps) {
        this.triggerTextNotificationModal(nextProps);
    }   
    
    triggerTextNotificationModal(nextProps) {
        if (this.state.hasSeenTextNotificationModal === false && nextProps.needsTextNotificationModal) {
            this.setState({hasSeenTextNotificationModal: true});
            var heading = 'Hey!';
            var message = "We've added text notifications. Hop over to your profile to opt in!";
            this.props.toast.custom('success', heading, message, () => {
                CustomStorage.set('hasSeenTextNotificationModal', true);
            });
        }        
    }

    renderLocationSelectScreen() {
        return (
            <div className="home-locations locations-modal">
                <LocationSelectPartial visible={!this.props.currentLocation} />
            </div>
        );
    }

    renderHomeScreen() {
        if (!this.props.currentLocation) {
            return null;
        }        
        var categoryCards = [];

        if (this.props.categories) {
            for (var i=0;i<this.props.categories.length;i++) {
                var category = this.props.categories[i];
                categoryCards.push((
                    <CategoryCard key={i} category={category} />
                ));
            }
        }

        var heroMessage = this.props.catalog.WebHomeHeadline;
        return (
            <div>
                <Hero message={heroMessage} />
                <div className="body">            
                    <div className="container category-list">
                        {categoryCards}
                    </div>
                </div>
            </div>
        );
    }

    render() {
        return (
            <div>
                <StoreClosedModal />
                {this.renderLocationSelectScreen()}
                {this.renderHomeScreen()}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    var supportsTextNotifications = state.catalog.data.HasTextNotifications;
    var needsTextNotificationModal = state.auth.isLoggedIn && state.auth.isVerified && state.auth.notifications.optIn === null && supportsTextNotifications == true;
    var sortedCategories = SortService.instance.sortCategories(state.catalog.categories, state.catalog.data);
    return {
        categories: sortedCategories,
        catalog: state.catalog.data,
        needsTextNotificationModal: needsTextNotificationModal,
        currentLocation: state.location.currentLocation,
    }
};

const mapDispatchToProps = (dispatch) => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(HomeScreen);