const initialState = {
    about: '<div></div>',
	home: {
		headline: null,
		subHeadline: null
    },
    confirmation: {
        note: null
    }    
};

 
export default function content(state, action) {
    if (state === undefined) {
        return initialState;
    }
    switch(action.type) {
        case 'CATALOG_INITIALIZING':
            return {
                about: '<div></div>',
				home: {
					headline: null,
					subHeadline: null
                },
                confirmation: {
                    note: null
                }
            };
        case 'CATALOG_INITIALIZED':
			var data = action.data || {};
            return {
                about: data.AboutContent || '<div></div>',
				home: {
					headline: data.HomeHeadline,
					subHeadline: data.HomeSubHeadline
                },
                confirmation: {
                    note: data.ConfirmationNote
                }
            };
        default:
            return state;
    }  
}