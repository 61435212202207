/**
 * PoppinPay API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from "../ApiClient";
import ModelStateError from '../model/ModelStateError';
import OrderGuestPlaceBindingModel from '../model/OrderGuestPlaceBindingModel';
import OrderGuestPlaceResultModel from '../model/OrderGuestPlaceResultModel';
import OrderGuestQueueBindingModel from '../model/OrderGuestQueueBindingModel';
import OrderGuestQueueResultModel from '../model/OrderGuestQueueResultModel';
import OrderGuestStatusBindingModel from '../model/OrderGuestStatusBindingModel';
import OrderListBindingModel from '../model/OrderListBindingModel';
import OrderListResultModel from '../model/OrderListResultModel';
import OrderPlaceBindingModel from '../model/OrderPlaceBindingModel';
import OrderPlaceGuestModel from '../model/OrderPlaceGuestModel';
import OrderPlaceModel from '../model/OrderPlaceModel';
import OrderPlaceResultModel from '../model/OrderPlaceResultModel';
import OrderPreviewBindingModel from '../model/OrderPreviewBindingModel';
import OrderPreviewModel from '../model/OrderPreviewModel';
import OrderPreviewResultModel from '../model/OrderPreviewResultModel';
import OrderQueueBindingModel from '../model/OrderQueueBindingModel';
import OrderQueueResultModel from '../model/OrderQueueResultModel';
import OrderStatusBindingModel from '../model/OrderStatusBindingModel';
import OrderStatusResultModel from '../model/OrderStatusResultModel';

/**
* Order service.
* @module api/OrderApi
* @version 1.0.0
*/
export default class OrderApi {

    /**
    * Constructs a new OrderApi. 
    * @alias module:api/OrderApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }

    /**
     * Callback function to receive the result of the orderGuestOrderStatus operation.
     * @callback module:api/OrderApi~orderGuestOrderStatusCallback
     * @param {String} error Error message, if any.
     * @param {module:model/OrderStatusResultModel} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {module:api/OrderApi~orderGuestOrderStatusCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/OrderStatusResultModel}
     */
    orderGuestOrderStatus(body, callback) {
      let postBody = body;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = OrderStatusResultModel;

      return this.apiClient.callApi(
        '/api/Order/GuestOrderStatus', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the orderGuestPlace operation.
     * @callback module:api/OrderApi~orderGuestPlaceCallback
     * @param {String} error Error message, if any.
     * @param {module:model/OrderGuestPlaceResultModel} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {module:api/OrderApi~orderGuestPlaceCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/OrderGuestPlaceResultModel}
     */
    orderGuestPlace(body, callback) {
      let postBody = body;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = OrderGuestPlaceResultModel;

      return this.apiClient.callApi(
        '/api/Order/GuestPlace', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the orderGuestPlace2 operation.
     * @callback module:api/OrderApi~orderGuestPlace2Callback
     * @param {String} error Error message, if any.
     * @param {module:model/OrderPlaceResultModel} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {module:api/OrderApi~orderGuestPlace2Callback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/OrderPlaceResultModel}
     */
    orderGuestPlace2(body, callback) {
      let postBody = body;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = OrderPlaceResultModel;

      return this.apiClient.callApi(
        '/api/v2/Order/GuestPlace', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the orderGuestQueue operation.
     * @callback module:api/OrderApi~orderGuestQueueCallback
     * @param {String} error Error message, if any.
     * @param {module:model/OrderGuestQueueResultModel} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {module:api/OrderApi~orderGuestQueueCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/OrderGuestQueueResultModel}
     */
    orderGuestQueue(body, callback) {
      let postBody = body;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = OrderGuestQueueResultModel;

      return this.apiClient.callApi(
        '/api/Order/GuestQueue', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the orderList operation.
     * @callback module:api/OrderApi~orderListCallback
     * @param {String} error Error message, if any.
     * @param {module:model/OrderListResultModel} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {module:api/OrderApi~orderListCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/OrderListResultModel}
     */
    orderList(body, callback) {
      let postBody = body;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['oauth2'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = OrderListResultModel;

      return this.apiClient.callApi(
        '/api/Order/List', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the orderOrderStatus operation.
     * @callback module:api/OrderApi~orderOrderStatusCallback
     * @param {String} error Error message, if any.
     * @param {module:model/OrderStatusResultModel} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {module:api/OrderApi~orderOrderStatusCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/OrderStatusResultModel}
     */
    orderOrderStatus(body, callback) {
      let postBody = body;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['oauth2'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = OrderStatusResultModel;

      return this.apiClient.callApi(
        '/api/Order/OrderStatus', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the orderPlace operation.
     * @callback module:api/OrderApi~orderPlaceCallback
     * @param {String} error Error message, if any.
     * @param {module:model/OrderPlaceResultModel} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {module:api/OrderApi~orderPlaceCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/OrderPlaceResultModel}
     */
    orderPlace(body, callback) {
      let postBody = body;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['oauth2'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = OrderPlaceResultModel;

      return this.apiClient.callApi(
        '/api/Order/Place', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the orderPlace2 operation.
     * @callback module:api/OrderApi~orderPlace2Callback
     * @param {String} error Error message, if any.
     * @param {module:model/OrderPlaceResultModel} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {module:api/OrderApi~orderPlace2Callback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/OrderPlaceResultModel}
     */
    orderPlace2(body, callback) {
      let postBody = body;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['oauth2'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = OrderPlaceResultModel;

      return this.apiClient.callApi(
        '/api/v2/Order/Place', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the orderPreview operation.
     * @callback module:api/OrderApi~orderPreviewCallback
     * @param {String} error Error message, if any.
     * @param {module:model/OrderPreviewResultModel} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {module:api/OrderApi~orderPreviewCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/OrderPreviewResultModel}
     */
    orderPreview(body, callback) {
      let postBody = body;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = OrderPreviewResultModel;

      return this.apiClient.callApi(
        '/api/v2/Order/Preview', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the orderPreviewOrder operation.
     * @callback module:api/OrderApi~orderPreviewOrderCallback
     * @param {String} error Error message, if any.
     * @param {module:model/OrderPreviewResultModel} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {module:api/OrderApi~orderPreviewOrderCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/OrderPreviewResultModel}
     */
    orderPreviewOrder(body, callback) {
      let postBody = body;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = OrderPreviewResultModel;

      return this.apiClient.callApi(
        '/api/Order/Preview', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the orderQueue operation.
     * @callback module:api/OrderApi~orderQueueCallback
     * @param {String} error Error message, if any.
     * @param {module:model/OrderQueueResultModel} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {module:api/OrderApi~orderQueueCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/OrderQueueResultModel}
     */
    orderQueue(body, callback) {
      let postBody = body;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['oauth2'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = OrderQueueResultModel;

      return this.apiClient.callApi(
        '/api/Order/Queue', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

}
