import React from 'react';
import { connect } from 'react-redux';
import { Hero, ItemCard, CategoriesSlider } from '../components';
import { Redirect } from 'react-router-dom';
import { SortService } from '../lib';

class CategoryScreen extends React.Component {
    render() {
        if (!this.props.category) {
            return (<Redirect to="/" />)
        }
        var itemCards = [];

        var items = this.props.items;
        
        for (var i=0;i<items.length;i++) {
            var item = items[i];
            itemCards.push((
                <ItemCard key={i} item={item} />
            ));
        }

        var categoryName = this.props.category.Name;

        return (
            <div>
                <Hero message={categoryName} />
                <CategoriesSlider />
                <div className="body">
                    <div className="container sub-category-list">
                        {itemCards}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    var id = state.catalog.selectedCategoryId;
    var category = null;
    
    if (id) {
        var categories = state.catalog.categories;
        for(var i=0;i<categories.length;i++){
            if (categories[i].Id == id) {
                category = categories[i];
                break;
            }
        }
    }

    var allItems = category ? category.Children : [];
    allItems = SortService.instance.sortItems(allItems, state.catalog.data);

    return {
        category: category,
        items: allItems
    };
};

const mapDispatchToProps = (dispatch) => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(CategoryScreen);