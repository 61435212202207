/**
 * PoppinPay API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import CardResultModel from './CardResultModel';

/**
* The ListCardResultModel model module.
* @module model/ListCardResultModel
* @version 1.0.0
*/
export default class ListCardResultModel {
    /**
    * Constructs a new <code>ListCardResultModel</code>.
    * @alias module:model/ListCardResultModel
    * @class
    */

    constructor() {
        
        
        
    }

    /**
    * Constructs a <code>ListCardResultModel</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/ListCardResultModel} obj Optional instance to populate.
    * @return {module:model/ListCardResultModel} The populated <code>ListCardResultModel</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new ListCardResultModel();
                        
            
            if (data.hasOwnProperty('Cards')) {
                obj['Cards'] = ApiClient.convertToType(data['Cards'], [CardResultModel]);
            }
        }
        return obj;
    }

    /**
    * @member {Array.<module:model/CardResultModel>} Cards
    */
    'Cards' = undefined;




}
