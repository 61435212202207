import React from 'react';
import { connect } from 'react-redux';
import { Redirect, NavLink, withRouter } from 'react-router-dom';
import { AccountHeader,CouponsSubNav } from '../components';
import { Internationalization, CurrencyFormatter } from '../lib';

class CouponsScreen extends React.Component {
    render() {
        return (
            
            <div className="my-acct-my-rewards">
                <AccountHeader />
                <CouponsSubNav/>
                <div className="body my-account-pages">
                    <div className="container">
                        <div className="rewards-list">
                            {this.props.coupons.length == 0 && <h2 className="sub-heading">You don't have any coupons.</h2>}
                            {this.props.coupons.length > 0 && <h2 className="sub-heading">Only one discount can be applied per order.</h2>}
                            {this.renderRewards()}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    addToBagClicked(coupon) {
        this.props.select(coupon);
        this.props.toast.success("Coupon was added!");
    }

    getRewardNote(coupon) {
        var percentage = coupon.Percentage;
        var maximumMoney = coupon.MaximumMoney;
        var amountMoney = coupon.AmountMoney;
        var type = coupon.Type;
        var categories = coupon.Categories || [];
        var items = coupon.Items || [];
        var currencyCode = this.props.currencyCode;

        var key = null;
        var model = {};

        if (percentage)
        {
            key = 'percentage_off';
            model.percentage = percentage;
            if (maximumMoney)
            {
                key += '_with_max';
                model.maximum = CurrencyFormatter.format(maximumMoney, currencyCode);
            }
        }
        else if (amountMoney)
        {
            key = 'amount_off';
            model.amount = CurrencyFormatter.format(amountMoney, currencyCode);
        }
        else
        {
            return null;
        }

        if (type == 'EntireOrder')
        {
            key += '_entire_order';
        }
        else if (type == 'Categories')
        {
            key += '_by_categories';
            model.count = categories.length;
        }
        else
        {
            key += '_by_items';
            model.count = items.length;
        }

        return Internationalization.strings('coupon_notes.' + key, model);
    }    

    renderReward(coupon) {

        var name = coupon.Name;
        var description = coupon.Description;

        var note = this.getRewardNote(coupon);

        var imageClasses = ['fas','fa-5x', 'fa-fw'];
        if (coupon.Percentage) {
            imageClasses.push('fa-percent');
        } else {
            imageClasses.push('fa-tags');
        }

        var image = (<i className={imageClasses.join(' ')}></i>);

        return (
            <div className="row" key={coupon.Id}>
                <div className="pic">{image}</div>
                <div className="title-info">
                    <h3><a href="javascript://" onClick={() => this.addToBagClicked(coupon)}>{name}</a></h3>
                    <div className="description">{description}</div>
                    <div className="notes">{note}</div>
                </div>
                <div>
                    <a href="javascript://" onClick={() => this.addToBagClicked(coupon)} className="btn btn-inline btn-primary add-to-bag-btn"><span>Redeem</span></a>
                </div>
            </div>
        );
    }

    renderRewards() {
        var rewards = [];
        for(var i=0;i<this.props.coupons.length;i++) {
            var coupon = this.props.coupons[i];
            rewards.push(this.renderReward(coupon));
        }        
        return rewards;
    }
}

const mapStateToProps = (state) => {
    return {
        coupons: state.loyalty.coupons,
        location: state.location.currentLocation,
        currencyCode: state.location.currentLocation.Currency,
    }
}; 

const mapDispatchToProps = (dispatch) => ({
    select: (coupon) => {
        dispatch({type:'CART_COUPON_ADD', coupon: coupon});
    }
});
  

export default connect(mapStateToProps, mapDispatchToProps)(CouponsScreen);