import ShoppingCartService from "../shoppingcartservice";

var previousIdempotencyKey = null;

function handleNewState(store) {
    var newState = store.getState();
    var newCart = newState.cart;
    if (newCart.idempotencyKey != previousIdempotencyKey) {
        if (previousIdempotencyKey === null) {
            previousIdempotencyKey = newCart.idempotencyKey;
            return;
        }
        previousIdempotencyKey = newCart.idempotencyKey;

        if (newCart.items.length == 0) {
            return;
        }

        if (newCart.location === null) {
            return;
        }

        var previewOrderContext = {
            idempotencyKey: newCart.idempotencyKey,
            items: newCart.items,
            coupons: newCart.coupons,
            rewards: newCart.rewards,
            location: newCart.location
        };        

        ShoppingCartService.instance.previewContextOrder(previewOrderContext, (error, data, response) => {
            debugger;
        });
    }
}

export const cartPreviewCalculator = store => next => action => {
    var result = next(action);
    handleNewState(store);
    return result;
};
