import { DeliveryAddressModel, DeliveryAddressValidationResult } from '../poppin_pay_api/index';

class NewDeliveryAddressSettings {
    address: DeliveryAddressModel | undefined | null
    validationResults: DeliveryAddressValidationResult[] | undefined | null
}

class DeliveryState
{
    selectedAddressId: string | undefined | null
    addresses!: DeliveryAddressModel[];
    newAddress: NewDeliveryAddressSettings | undefined | null
    editAddress: DeliveryAddressModel | undefined | null
};

const initialState:DeliveryState = {
    selectedAddressId: null,
    addresses: [],
    newAddress: null,
    editAddress: null
};

export default function delivery(state:DeliveryState, action:any):DeliveryState {
    if (state === undefined) {
        return initialState;
    }
    switch(action.type) {
        case 'DELIVERY_ADDRESSES_INITIALIZED':
            var selectedAddressId = state.selectedAddressId;
            
            var foundSelected = false;
            var addresses = [];
            var incomingAddresses = action.addresses || [];
            for(var i=0;i<incomingAddresses.length;i++) {
                var incomingAddress = incomingAddresses[i];
                if (incomingAddress.Id == selectedAddressId) {
                    foundSelected = true;
                }
                addresses.push(incomingAddress);
            }

            if (!foundSelected) {
                selectedAddressId = null;
            }

            return {
                ...state,
                addresses: addresses,
                selectedAddressId: selectedAddressId
            };
        case 'DELIVERY_ADDRESSES_NEW':
            return {
                ...state,
                newAddress: {
                    address: action.address,
                    validationResults: action.validationResults
                },
            };
        case 'DELIVERY_ADDRESSES_SELECT':
            return {
                ...state,
                selectedAddressId: action.id
            };
        case 'DELIVERY_ADDRESSES_ADDED':
            var list = [ ...state.addresses ];
            list.push(action.address);
            return {
                ...state,
                selectedAddressId: action.address.Id,
                addresses: list
            };
        case 'DELIVERY_ADDRESSES_EDIT':
            return {
                ...state,
                editAddress: action.address
            };
        case 'DELIVERY_ADDRESSES_UPDATED':
            var list = [ ...state.addresses ];
            for(var i=0;i<list.length;i++) {
                if (list[i].Id == action.address.Id) {
                    list[i] = action.address;
                }
            }
            return {
                ...state,
                addresses: list,
            };
        case 'DELIVERY_ADDRESSES_DELETED':
            var list = [ ... state.addresses.filter((a) => a.Id != action.addressId) ];
            var selectedAddressId = state.selectedAddressId;
            if (action.addressId == selectedAddressId) {
                selectedAddressId = null;
            }
            return {
                ...state,
                addresses: list,
                selectedAddressId: selectedAddressId
            };
        default:
            return state;
    }
}