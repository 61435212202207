import React from 'react';
import { default as TextField } from './textField';

export default class EmailField extends React.Component {
    render() {
        var props = {
            ...this.props,
            type: "email"
        };

        return (<TextField {...props} />);
    }
}