import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Hero } from '../components';

class AboutScreen extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const rawHtml = {__html: this.props.content};

        var companyName = this.props.catalog.Name;

        return (
            <div>
                <Hero message={'Welcome to ' + companyName} />
                <div className="body">
                    <div className="container about-container">
                        <div dangerouslySetInnerHTML={rawHtml}></div>    
                    </div>
                </div>
            </div>
        );
    }    
}

const mapStateToProps = (state) => {
    return {
        content: state.content.about,
        catalog: state.catalog.data
    }
};

const mapDispatchToProps = (dispatch) => ({
   
});


export default connect(mapStateToProps, mapDispatchToProps)(AboutScreen);