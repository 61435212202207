import React from 'react';
import { Redirect, NavLink, withRouter } from 'react-router-dom';
import { ApiService, CardService } from '../lib';
import async from 'async';
import { connect } from 'react-redux';

class AccountHeader extends React.Component {
    onLogoutClicked() {
        this.props.logout();
    }

    render() {
        if (!this.props.isLoggedIn) {
            return (<Redirect to="/" />);
        }

        return (
            <div className="my-account-menu">
                <div><NavLink to="/my-account" activeClassName='active'><span>Profile</span></NavLink></div>
                <div><NavLink to="/my-cards" activeClassName='active'><span>Saved Cards</span></NavLink></div>
                <div><NavLink to="/my-coupons" activeClassName='active'><span>My Coupons</span></NavLink></div>
                <div><NavLink to="/my-orders" activeClassName='active'><span>Order History</span></NavLink></div>
                <div><a href="javascript://" onClick={() => this.onLogoutClicked() }><span>Logout</span></a></div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isLoggedIn: state.auth.isLoggedIn,
    }
  };
  

const mapDispatchToProps = (dispatch) => ({
    logout: () => {
        return new Promise((resolve, reject) => {
            ApiService.instance.logout();
            async.parallel({
                loyalty: (cb) => ApiService.instance.loyaltyStatus({}, (e, data, response) => {
                    dispatch({type: 'LOYALTY_INITIALIZED', data: data })
                    cb(null, true);
                }),
                cards: (cb) => {
                    dispatch({type: 'CARDS_INITIALIZED', cards: [], defaultCard: null  });
                    cb(null, true);
                },
            }, (err, results) => {
                dispatch({type: 'AUTH_SIGNED_OUT'});
                resolve(true);
            });      
        });
    }
  });

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AccountHeader));