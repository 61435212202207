import ApiService from './apiService';


export default class GiftCardService {
    constructor() {
        this.giftcards = [];
        
    }

    addGiftCard(model, callback) {
        var giftcard = {
            Gan: null,
            ClientId: null
        };

        ApiService.instance.getAuthProfile((profileError, profile, profileResponse) => {
            ApiService.instance.giftcards.cardAdd({
                Gan:model.Gan,
                ClientId:model.ClientId
            }, (error, data, response) => {
                if (!error) {
                    giftcard.id = data.GiftCardId;
                    this.giftcards.push(giftcard);
                    
                    this.save();
                }
                callback(error, giftcard, response);
            });
        });
       
    }
    removeGiftCard(model, callback) {
        var giftcard = {
            GiftCardId: null,
            ClientId: null
        };

        ApiService.instance.getAuthProfile((profileError, profile, profileResponse) => {
            ApiService.instance.giftcards.removeGiftCard({
                GiftCardId:model.GiftCardId,
                ClientId:model.ClientId
            }, (error, data, response) => {
                if (!error) {
                    giftcard.GiftCardId = data.GiftCardId;
                    this.giftcards.remove(giftcard);

                }
                callback(error, giftcard, response);
            });
        });
       
    }

    listGiftCards(model, callback) {
        var giftcard = {
            ClientId: null
        };

        ApiService.instance.getAuthProfile((profileError, profile, profileResponse) => {
            ApiService.instance.giftcards.listGiftCards({                
                ClientId:model.ClientId
            }, (error, data, response) => {
                callback(error, giftcard, response);
            });
        });
       
    }
    balanceGiftCard(model, callback) {
        var giftcard = {
            GiftCardId: null,
            ClientId: null
        };

        ApiService.instance.getAuthProfile((profileError, profile, profileResponse) => {
            ApiService.instance.giftcards.giftCardBalance({                
                GiftCardId: model.GiftCardId,
                ClientId:model.ClientId
            }, (error, data, response) => {
                callback(error, giftcard, response);
            });
        });
       
    }
    reloadGiftCard(model, callback) {
        var giftcard = {
            Source: null,
            Card: null,
            IdempotencyKey: null,
            GiftCardId: null,
            ReloadAmount: 0,
            SendText: true,
            ClientId: null,
            LocationId: null,
            CouponId: null,
            DiscountCodeId: null
        };

        ApiService.instance.getAuthProfile((profileError, profile, profileResponse) => {
            // register this with the API, store it locally
            ApiService.instance.giftcards.giftCardReload({                
                Source: model.Source,
                Card: model.Card,
                IdempotencyKey: model.IdempotencyKey,
                GiftCardId: model.GiftCardId,
                ReloadAmount: model.ReloadAmount,
                SendText: model.SendText,
                ClientId: model.ClientId,
                LocationId: model.LocationId,
                CouponId: model.CouponId,
                DiscountCodeId: model.DiscountCodeId
            }, (error, data, response) => {
                callback(error, giftcard, response);
            });
        });
       
    }
  }


  GiftCardService.instance = new GiftCardService();