import React from 'react';
import { connect } from 'react-redux';
import { FavoriteIcon, AccountHeader } from '../components';

class FavoritesScreen extends React.Component {
    render() {
        return (
            <div className="my-acct-my-favorites">
                <AccountHeader />
                <div className="body my-account-pages">
                    <div className="container">
                        <div className="favorites-list">
                            {this.props.favorites.length == 0 && <h2>You don't have any favorites.</h2>}
                            {this.renderFavorites()}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    addToBagClicked(item) {
        this.props.select(item);
    }

    renderFavorite(item) {
        var imageSource = null;
        if (item.ImageUrl) {
            imageSource = item.ImageUrl;
        } else {
            imageSource = global.imagesPath + 'item-placeholder.jpg';
        }

        var itemTitle = item.Name;
        var itemDescription = item.Description;

        return (
            <div className="row" key={item.Id}>
                <div className="fav">
                    <FavoriteIcon item={item} />
                </div>
                <div className="pic"><img src={imageSource} alt={itemTitle} /></div>
                <div className="title-info">
                    <h3><a href="javascript://" onClick={() => this.addToBagClicked(item)}>{itemTitle}</a></h3>
                    <div className="description">{itemDescription}</div>
                </div>
                <div>
                    <a href="javascript://" onClick={() => this.addToBagClicked(item)} className="btn btn-inline btn-primary add-to-bag-btn"><span>Add to Bag</span></a>
                </div>
            </div>
        );
    }

    renderFavorites() {
        var rows = [];
        for(var i=0;i<this.props.favorites.length;i++){
            var item = this.props.favorites[i];
            rows.push(this.renderFavorite(item));
        }
        return rows;
    }
}

const mapStateToProps = (state) => {
    var items = state.favorites.items;
    var remote = state.favorites.remote;
    var favorites = items.concat(remote.filter(function (item) {
        return items.indexOf(item) < 0;
    }));    

    var items = [];
    var itemMap = state.catalog.itemsById;
    for(var i=0;i<favorites.length;i++) {
        var favorite = favorites[i];
        var item = itemMap[favorite];
        if (item) {
            items.push(item);
        }
    }

    items.sort((a,b) => (a.Name > b.Name) ? 1 : ((b.Name > a.Name) ? -1 : 0)); 

    return {
        favorites: items
    }
}; 

const mapDispatchToProps = (dispatch) => ({
    select: (item) => {
        dispatch({type: 'CATALOG_SELECT_ITEM', item: item});
    }
});
  

export default connect(mapStateToProps, mapDispatchToProps)(FavoritesScreen);