/**
 * PoppinPay API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from "../ApiClient";
import AddGiftCardBindingModel from '../model/AddGiftCardBindingModel';
import GiftCardBalanceBindingModel from '../model/GiftCardBalanceBindingModel';
import GiftCardBalanceResultModel from '../model/GiftCardBalanceResultModel';
import GiftCardLoadModel from '../model/GiftCardLoadModel';
import GiftCardReloadResultModel from '../model/GiftCardReloadResultModel';
import ListCardBindingModel from '../model/ListCardBindingModel';
import ListGiftCardResultModel from '../model/ListGiftCardResultModel';
import ModelStateError from '../model/ModelStateError';
import RemoveGiftCardBindingModel from '../model/RemoveGiftCardBindingModel';

/**
* GiftCard service.
* @module api/GiftCardApi
* @version 1.0.0
*/
export default class GiftCardApi {

    /**
    * Constructs a new GiftCardApi. 
    * @alias module:api/GiftCardApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }

    /**
     * Callback function to receive the result of the giftCardAdd operation.
     * @callback module:api/GiftCardApi~giftCardAddCallback
     * @param {String} error Error message, if any.
     * @param {module:model/GiftCardBalanceResultModel} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {module:api/GiftCardApi~giftCardAddCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/GiftCardBalanceResultModel}
     */
    giftCardAdd(body, callback) {
      let postBody = body;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['oauth2'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = GiftCardBalanceResultModel;

      return this.apiClient.callApi(
        '/api/GiftCard/Add', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the giftCardBalance operation.
     * @callback module:api/GiftCardApi~giftCardBalanceCallback
     * @param {String} error Error message, if any.
     * @param {module:model/GiftCardBalanceResultModel} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {module:api/GiftCardApi~giftCardBalanceCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/GiftCardBalanceResultModel}
     */
    giftCardBalance(body, callback) {
      let postBody = body;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['oauth2'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = GiftCardBalanceResultModel;

      return this.apiClient.callApi(
        '/api/GiftCard/Balance', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the giftCardList operation.
     * @callback module:api/GiftCardApi~giftCardListCallback
     * @param {String} error Error message, if any.
     * @param {module:model/ListGiftCardResultModel} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {module:api/GiftCardApi~giftCardListCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/ListGiftCardResultModel}
     */
    giftCardList(body, callback) {
      let postBody = body;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['oauth2'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = ListGiftCardResultModel;

      return this.apiClient.callApi(
        '/api/GiftCard/List', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the giftCardReload operation.
     * @callback module:api/GiftCardApi~giftCardReloadCallback
     * @param {String} error Error message, if any.
     * @param {module:model/GiftCardReloadResultModel} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {module:api/GiftCardApi~giftCardReloadCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/GiftCardReloadResultModel}
     */
    giftCardReload(body, callback) {
      let postBody = body;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['oauth2'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = GiftCardReloadResultModel;

      return this.apiClient.callApi(
        '/api/GiftCard/Reload', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the giftCardRemove operation.
     * @callback module:api/GiftCardApi~giftCardRemoveCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {module:api/GiftCardApi~giftCardRemoveCallback} callback The callback function, accepting three arguments: error, data, response
     */
    giftCardRemove(body, callback) {
      let postBody = body;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['oauth2'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = null;

      return this.apiClient.callApi(
        '/api/GiftCard/Remove', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

}
