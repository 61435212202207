import React from 'react';
import async from 'async';
import { connect } from 'react-redux';
import Favicon from 'react-favicon';
import { ApiService, CardService, ConfigService, InventoryHubService } from './lib';
import { HomeScreen, AboutScreen, CategoryScreen, CartScreen, SignInScreen, SignUpScreen, ForgotPasswordScreen, ResetPasswordScreen, ProfileScreen, FavoritesScreen, HistoryScreen, CheckoutScreen, OrderConfirmationScreen, SavedCardsScreen, CouponsScreen,RewardsScreen } from './screens';
import { BrowserRouter, HashRouter, MemoryRouter, Route, Switch, withRouter, Redirect } from 'react-router-dom';
import { Header, Footer, ItemSidebarModal, SmartLocationModal, ScrollToTop, ErrorModal, VerifyAccountModal, ModifyTipModal, CartNotifier } from './components';
import {Helmet} from "react-helmet";
import moment from 'moment';

const HeaderComponent = withRouter(props => <Header {...props}/>);

class Loader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            toast: null
        }
    }

    componentDidMount() {
        this.props.init(this.props.localFavorites, this.props.savedLocationId, this.applyLocationIncludeExcludeRules);
    }

    setToastRef(ref) {
        if (!this.state.toast) {
            this.setState({toast: ref});
        }
    }

    render() {
        var favIconPath = global.imagesPath + 'favicon.png';

        var pageRouter = null;
        if (this.props.initialized) {
            pageRouter = (
                <Switch>
                    <Route exact path="/" render={(props) => <HomeScreen {...props} toast={this.state.toast} />} />
                    <Route path="/cart" render={(props) => <CartScreen {...props} toast={this.state.toast} />} />
                    <Route path="/about" render={(props) => <AboutScreen {...props} toast={this.state.toast} />} />
                    <Route path="/categories/:categoryId" render={(props) => <CategoryScreen {...props} toast={this.state.toast} />} />
                    <Route path="/sign-in" render={(props) => <SignInScreen {...props} toast={this.state.toast} />} />
                    <Route path="/sign-up" render={(props) => <SignUpScreen {...props} toast={this.state.toast} />} />
                    <Route path="/forgot-password" render={(props) => <ForgotPasswordScreen {...props} toast={this.state.toast} />} />
                    <Route path="/reset-password" render={(props) => <ResetPasswordScreen {...props} toast={this.state.toast} />} />
                    <Route path="/my-account" render={(props) => <ProfileScreen {...props} toast={this.state.toast} />} />
                    <Route path="/my-coupons" render={(props) => <CouponsScreen {...props} toast={this.state.toast} />} />                        
                    <Route path="/my-rewards" render={(props) => <RewardsScreen {...props} toast={this.state.toast} />} />                        
                    <Route path="/my-orders" render={(props) => <HistoryScreen {...props} toast={this.state.toast} />} />
                    <Route path="/my-cards" render={(props) => <SavedCardsScreen {...props} toast={this.state.toast} />} />
                    <Route path="/checkout" render={(props) => <CheckoutScreen {...props} toast={this.state.toast} />} />
                    <Route path="/order-confirmation/:orderId" render={(props) => <OrderConfirmationScreen {...props} toast={this.state.toast} />} />
                    <Route render={() => <Redirect to="/" />} />
                </Switch>
            );
        } else {
            pageRouter = (
                <div className="form col-half center-block text-center">
                    <i className="fas fa-spinner fa-pulse fa-5x"></i>
                </div>
            )
        }

        var pageTitle = 'Order Online';

        var build = process.env.REACT_APP_BUILD_NUMBER;
        var suffix = null;
        if(build) {
            suffix = '?v' + build;
        } else {
            suffix = '?v' + moment().format('YYYYMMDDHH');
        }

        var themeCssFile = global.contentPath + 'theme.css' + suffix;

        return (
            <BrowserRouter>
                <ScrollToTop>
                    <Favicon url={favIconPath} />
                    <Helmet>
                        <title>{pageTitle}</title>
                        <link rel="stylesheet" type="text/css" href={themeCssFile} />
                    </Helmet>                    
                    <HeaderComponent />
                    <ErrorModal ref={ ref => this.setToastRef(ref) } />                
                    {pageRouter}
                    <Footer />
                    <ItemSidebarModal toast={this.state.toast} />
                    <SmartLocationModal toast={this.state.toast} />
                    <VerifyAccountModal toast={this.state.toast} />
                    <ModifyTipModal toast={this.state.toast} />
                    <CartNotifier toast={this.state.toast} />
                </ScrollToTop>
            </BrowserRouter>
        );
    }

    applyLocationIncludeExcludeRules(locations) {
        var config = ConfigService.get();

        var includeLocations = config.IncludeLocations;
        var excludeLocations = config.ExcludeLocations;

        var useInclude = includeLocations && includeLocations.length > 0;
        var useExclude = excludeLocations && excludeLocations.length > 0;
        if (!useInclude && !useExclude) {
            return locations;
        }

        if (useInclude) {
            includeLocations = includeLocations.map((v) => v.toLowerCase());
        }
        if (useExclude) {
            excludeLocations = excludeLocations.map((v) => v.toLowerCase());
        }

        var finalLocations = [];
        for(var i=0;i<locations.length;i++) {
            var location = locations[i];

            var id = location.Id.toLowerCase();

            var keepInclude = useInclude && includeLocations.indexOf(id) >= 0;
            var keepExclude = useExclude && !(excludeLocations.indexOf(id) >= 0);
            if (keepInclude || keepExclude) {
                finalLocations.push(location);
            }
        }
        return finalLocations;
    }
}

const mapStateToProps = (state) => {
    return {
        initialized: state.api.initialized,
        localFavorites: state.favorites.items,
        savedLocationId: state.location.selectedLocationId,
        catalog: state.catalog.data,
    }
};

const mapDispatchToProps = (dispatch) => ({
    init: (localFavorites, savedLocationId, applyLocationIncludeExcludeRulesCallback) => {
        dispatch({ type: 'API_INITIALIZING' });
        ApiService.instance.initialize(() => {
            async.parallel({
                autoLogin: (cb) => ApiService.instance.tryAutoLogin((e, data, response) => {
                    dispatch({type:'AUTH_AUTOLOGIN_INITIALIZED', isLoggedIn: data});
                    cb(null, data);
                })
            }, (error, results) => {
                var steps = {
                    loyalty: (cb) => ApiService.instance.loyaltyStatus({}, (e, data, response) => {
                        dispatch({type: 'LOYALTY_INITIALIZED', data: data })
                        cb(null, true);
                    }),
                    catalog: (cb) => ApiService.instance.catalogIndex({}, (e, data, response) => {
                        data.Locations = applyLocationIncludeExcludeRulesCallback(data.Locations);
                        dispatch({ type: 'CATALOG_INITIALIZED', data: data });
                        dispatch({ type: 'LOCATION_INITIALIZED', locations: data.Locations });
                        var locationToSelect = null;

                        if (data.Locations.length == 1) {
                            // auto select the only location
                            locationToSelect = data.Locations[0];
                        } else if (savedLocationId && data.Locations.length > 1) {
                            for(var i=0;i<data.Locations.length;i++) {
                                var possibleLocation = data.Locations[i];
                                if (possibleLocation.Id == savedLocationId) {
                                    locationToSelect = possibleLocation;
                                    break;
                                }
                            }
                        }

                        if (locationToSelect) {
                            dispatch({ type: 'LOCATION_SELECTED', location: locationToSelect });
                            InventoryHubService.instance.locationChanged(locationToSelect.Id, () => {
                                cb(null, true);
                            });
                        } else {
                            cb(null, true);
                        }
                    }),
                    inventory: (cb) => InventoryHubService.instance.start((connected, error) => {
                        if (!connected && error) {
                            console.error(error);
                        }
                        cb(null, true);
                    })                                 
                };

                if (ApiService.instance.isLoggedIn()) {
                    steps['authStatus'] = (cb) => ApiService.instance.accountStatus({}, (e, data, response) => {
                        dispatch({type: 'AUTH_STATUS', data: data });
                        cb(null, true);
                    });   
                                        
                    steps['orders'] = (cb) => ApiService.instance.orderList({}, (e, data, response)=> {
                        dispatch({type: 'ORDERS_INITIALIZED', data: data });
                        cb(null, true);
                    });
                    /*
                    steps['mergeFavorites'] = (cb) => ApiService.instance.favoriteMerge({items: localFavorites}, (e, data, response) => {
                        dispatch({type: 'FAVORITES_INITIALIZED', data: data });
                        cb(null, true);
                    });
                    */
                    steps['cards'] = (cb) => CardService.instance.mergeInit((data, defaultCard) => {
                        dispatch({type: 'CARDS_INITIALIZED', cards: [], defaultCard: defaultCard, remote: data });
                        cb(null, true);
                    });
                } else {
                    steps['cards'] = (cb) => {
                        dispatch({type: 'CARDS_INITIALIZED', cards: [], defaultCard: null  });
                        cb(null, true);
                    };
                }

                async.parallel(steps, (err, results) => {
                    dispatch({ type: 'API_INITIALIZED' });
                });
            });
        });        
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(Loader);