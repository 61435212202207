import PhoneNumber from 'awesome-phonenumber';
export default {
    rules: {
        email(text) {
            var re = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
            return re.test(text);
        },
    
        notEmpty(text) {
            return !(!text || 0 === text.length);
        },

        equalsOtherInput(text, model, rule) {
            var other = model[rule.other];
            return text === other;
        },

        phoneNumber(text) {
            var pn = new PhoneNumber( text, 'US' );
            return pn.isValid();
        },

        number(text) {
            var number = new Number(text);
            
            if (Number.isNaN(number)) {
                return false;
            }

            if (isNaN(number)) {
                return false;
            }

            return true;
        }
    },

    isValid(model, validationRules, setState, errorMessageCallback = null, context = null) {
        var initialState = {};
        for(var i=0;i<validationRules.length;i++) {
            initialState[validationRules[i].output] = null;
        }
        // reset the initial state to blank for all errors
        setState(initialState);

        var finalState = {};
        var anyErrors = false;

        // walk each entry
        for(var i=0;i<validationRules.length;i++) {
            var validationRule = validationRules[i];
            var input = model[validationRule.input];
            var rules = validationRule.rules;

            var errorMessage = null;
            // walk each rule in each entry
            for(var j=0;j<rules.length;j++){
                var rule = rules[j];
                if (rule.condition) {
                    if (!rule.condition(input, model, rule, context)) {
                        continue;
                    }
                }
                if (!rule.rule(input, model, rule, context)) {
                    if (errorMessageCallback) {
                        errorMessage = errorMessageCallback(rule);
                    } else if (rule.messageCallback) {
                        errorMessage = rule.messageCallback(input, model, rule, context);
                    } else {
                        errorMessage = rule.message;
                    }
                    break;
                }
            }

            // if there was an error include it in the state
            if (errorMessage) {
                anyErrors = true;
                finalState[validationRule.output] = errorMessage;

                // stop if we are told to stop processing future entries
                if (validationRule.stopOnError) {
                    break;
                }
            }
        }

        // if there were any errors write them to the state
        if (anyErrors) {
            setState(finalState);
            return false;
        }

        return true;
    }
}