import React from 'react';
import { connect } from 'react-redux';
import { AccountHeader } from '../components';
import { CurrencyFormatter, Internationalization, ReorderService } from '../lib';
import moment from 'moment-timezone';

class HistoryScreen extends React.Component {
    render() {
        return (
            <div className="my-acct-order-history">
                <AccountHeader />
                <div className="body my-account-pages">
                    <div className="container">
                        <div className="order-history-list">
                            {this.props.orders.length == 0 && <h2>You don't have any prior orders.</h2>}
                            {this.renderOrders()}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    reorderLineItem(item) {
        var service = new ReorderService(this.props.location, this.props.itemsById, this.props.inventoryCounts, this.props.cartEntries);
        var status = service.getLineItemStatus(item);
        if (status.valid) {
            var cartEntry = service.getLineItemCartEntry(item);
            this.props.add(cartEntry);
            this.props.toast.success(Internationalization.strings('history_line_item.reorder.added_to_cart'));
        }
    }

    reorderOrder(order) {
        var service = new ReorderService(this.props.location, this.props.itemsById, this.props.inventoryCounts, this.props.cartEntries);

        for(var i=0;i<order.LineItems.length;i++) {
            var item = order.LineItems[i];
            var status = service.getLineItemStatus(item);
            if (!status.valid) {
                return;
            }
        }
        
        for(var i=0;i<order.LineItems.length;i++) {
            var item = order.LineItems[i];
            var status = service.getLineItemStatus(item);
            var cartEntry = service.getLineItemCartEntry(item);
            this.props.add(cartEntry);         
        }

        this.props.toast.success(Internationalization.strings('history_screen.reorder.added_to_cart'));
    }

    getReorderMessage(reason) {
        var reorderNote = null;
        switch(reason) {
            case "ORDERLINEITEM_INVALID":
                reorderNote = Internationalization.strings("history_line_item.reorder.line_item_invalid");
                break;
            case "ITEM_IS_REQUIRED":
                reorderNote = Internationalization.strings("history_line_item.reorder.item_missing");
                break;                    
            case "VARIATION_IS_REQUIRED":
                reorderNote = Internationalization.strings("history_line_item.reorder.variation_missing");
                break;                    
            case "MODIFIERLIST_IS_REQUIRED":
                reorderNote = Internationalization.strings("history_line_item.reorder.modifierlist_is_required");
                break;
            case "MODIFIERLIST_MIN_NOT_MET":
                reorderNote = Internationalization.strings("history_line_item.reorder.modifierlist_min_not_met");
                break;
            case "MODIFIERLIST_MAX_EXCEEDED":
                reorderNote = Internationalization.strings("history_line_item.reorder.modifierlist_max_exceeded");
                break;                    
            case "MODIFIER_MISSING_OPTIONAL":
                reorderNote = Internationalization.strings("history_line_item.reorder.modifier_missing_optional");
                break;                    
            case "VARIATION_OUT_OF_STOCK":
                reorderNote = Internationalization.strings("history_line_item.reorder.variation_out_of_stock");
                break;
            case "VARIATION_REDUCED_QUANTITY":
                reorderNote = Internationalization.strings("history_line_item.reorder.variation_reduced_quantity");
                break;                
        }
        return reorderNote;
    }    

    renderItem(item, index, status) {

        var noteLine = item.Note ? (<div className="notes"><strong>Notes:</strong> {item.Note}</div>) : null;

        var imageUrl = item.ImageUrl || (global.imagesPath + 'item-placeholder.jpg');

        var descriptionParts = [];
        if (item.VariationName) {
            descriptionParts.push(item.VariationName);
        }
        for(var i=0;i<item.Modifiers.length;i++){
            var modifier = item.Modifiers[i];
            descriptionParts.push(modifier.Name);
        }

        let description = descriptionParts.join(', ');

        var reorderButtonText = Internationalization.strings("history_line_item.reorder.button");
        var reorderNote = "";
        var canReorder = status.valid;

        var reorderNoteClass = ["reorder-note"];

        if (status.reason) {
            reorderButtonText += " *";
            if (status.valid) {
                reorderNoteClass.push("text-warning");
            } else {
                reorderNoteClass.push("text-danger");
            }

            if (status.reasons && status.reasons.length) {
                for(var i=0;i<status.reasons.length;i++) {
                    if (i > 0) {
                        reorderNote += ' ';
                    }
                    reorderNote += this.getReorderMessage(status.reasons[i]);
                }
            } else {
                reorderNote = this.getReorderMessage(status.reason);
            }
        }        

        var reorderButtonClass = [
            "btn",
            "btn-inline",
            "btn-small",
        ];

        if (canReorder) {
            reorderButtonClass.push("btn-primary");
        } else {
            reorderButtonClass.push("btn-inactive");
        }

        return (
            <div key={item.ItemId + '-' + index} className="item">
                <div className="pic"><img src={imageUrl} alt={item.Name} /></div>
                <div className="details">
                    <h3>{item.ItemName}</h3>
                    <div className="description">{description}</div>
                    <div className="qty">Quantity: {item.Quantity}</div>
                    {noteLine}
                    <div>
                        <a href="javascript://" onClick={()=>this.reorderLineItem(item)} class={reorderButtonClass.join(" ")} disabled={!canReorder}>{reorderButtonText}</a>
                    </div>
                    <div className={reorderNoteClass.join(" ")}>
                        {reorderNote}
                    </div>
                </div>
                <div className="price">{CurrencyFormatter.format(item.GrossSales, this.props.currencyCode)}</div>
            </div>
        );
    }

    renderOrder(order) {
        const orderDateLabel = moment(order.ChargeDateUtc).tz(this.props.timezone).format("MMMM Do h:mm a");    
        const pickupDateLabel = moment(order.PickupAtUtc).tz(this.props.timezone).format("MMMM Do h:mm a");    

        var reorderButtonText = Internationalization.strings("history_screen.reorder.button");
        var canReorder = true;
        var hasReason = false;

        var reorderButtonClass = [
            "btn",
            "btn-inline",
            "btn-small",
        ];
      
        var service = new ReorderService(this.props.location, this.props.itemsById, this.props.inventoryCounts, this.props.cartEntries);

        var items = [];
        for(var i=0;i<order.LineItems.length;i++){
            var item = order.LineItems[i];
            var status = service.getLineItemStatus(item);
            var needsReason = status.reason || (status.reasons && status.reasons.length);

            if (needsReason && !hasReason) {
                hasReason = true;
                reorderButtonText += " *";
            }

            if (!status.valid) {
                canReorder = false;
            }            

            items.push(this.renderItem(item, i, status));
        }

        if (canReorder) {
            reorderButtonClass.push("btn-primary");
        } else {
            reorderButtonClass.push("btn-inactive");
        }

        var discountLabel = null;
        var discountTotal = null;
        if (order.TotalDiscounts > 0) {
            discountLabel = (<span>Discount<br/></span>);
            discountTotal = (<span>{CurrencyFormatter.format(order.TotalDiscounts * -1, this.props.currencyCode)}<br/></span>);            
        }

        var tipLabel = null;
        var tipTotal = null;
        if (order.TotalTips > 0) {
            tipLabel = (<span>Tip<br/></span>);
            tipTotal = (<span>{CurrencyFormatter.format(order.TotalTips, this.props.currencyCode)}<br/></span>);
        }

        return (
            <div key={order.Id} className="row">
                <div className="title">
                    <div className="detail">
                        <h2>Order on: {orderDateLabel}</h2>
                        <h4>Pickup at: {pickupDateLabel}</h4>
                    </div>
                    <div className="reorder">
                        <a href="javascript://" onClick={()=>this.reorderOrder(order)} class={reorderButtonClass.join(" ")} disabled={!canReorder}>{reorderButtonText}</a>
                    </div>
                </div>

                {items}
                <div className="price-summary">
                    <div className="txt">
                        Subtotal<br />
                        Tax<br />
                        {discountLabel}
                        {tipLabel}
                        <strong>Total</strong>
                    </div>
                    <div className="totals">
                        {CurrencyFormatter.format(order.SubTotal, this.props.currencyCode)}<br />
                        {CurrencyFormatter.format(order.TotalTaxes, this.props.currencyCode)}<br />
                        {discountTotal}
                        {tipTotal}
                        <strong>{CurrencyFormatter.format(order.GrandTotal, this.props.currenyCode)}</strong>
                    </div>
                </div>
            </div>
        );
    }

    renderOrders() {
        var rows = [];
        for(var i=0;i<this.props.orders.length;i++){
            var order = this.props.orders[i];
            rows.push(this.renderOrder(order));
        }
        return rows;
    }
}

const mapStateToProps = (state) => {
    const history = state.history.orders; 
    
    var location = state.location.currentLocation;

    var results = [];
    history.forEach((entry) => {
      if (!entry) {
        return;
      }

      if (entry.LocationId != location.Id) {
          return;
      }

      results.push(entry);
    });

    var inventoryCounts = state.inventory.locationVariationMap[state.location.selectedLocationId] || {};
    var cartEntries = state.cart.items;    

    return {
        orders: results,
        timezone: location.Timezone,
        currencyCode: location.Currency,
        location: state.location.currentLocation,
        itemsById: state.catalog.itemsById,
        inventoryCounts: inventoryCounts,
        cartEntries: cartEntries        
    }
}; 

const mapDispatchToProps = (dispatch) => ({
    add: (cartItem) => {
        dispatch({type: 'CART_ADD', cartItem: cartItem});
    }    
});
  

export default connect(mapStateToProps, mapDispatchToProps)(HistoryScreen);