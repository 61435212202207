import {  Catalog, Location, Category } from '../poppin_pay_api/index';

const initialState = {
    initialized: false,
    initializing: false,
    data: null,
    categories: [],
    categoriesById: {},
    selectedCategoryId: null,
    itemsById: {},
    variationsById: {},
    lastSelectedLocationId: null
};

function getLookups(location: Location|null) {

    var result = {
        categories: <any>[],
        categoriesById: <any>{},
        itemsById: <any>{},
        variationsById: <any>{}
    };

    if (!location) {
        return result;
    }

    for(var i=0;i<location.Categories.length;i++) {
        var categoryRaw:Category = location.Categories[i];

        if (!categoryRaw.Items || categoryRaw.Items.length == 0)
            continue;

        var category = {
            Id: categoryRaw.Id,
            Name: categoryRaw.Name,
            Description: categoryRaw.Description,
            Ordinal: categoryRaw.Ordinal,
            ImageUrl: null,
            Children: <any>[],
            Items: <any>[],
        };

        result.categories.push(category);
        result.categoriesById[category.Id] = category;        

        for (var j=0;j<categoryRaw.Items.length;j++) {
            var item = categoryRaw.Items[j];
            
            item.CategoryId = category.Id;

            result.itemsById[item.Id] = item;
            category.Children.push(item);
            category.Items.push(item);

            if (!item.Variations || item.Variations.length == 0)
                continue;

            for(var k=0;k<item.Variations.length;k++) {
                var variation = item.Variations[k];
                variation.ItemId = item.Id;
                variation.CategoryId = category.Id;
                result.variationsById[variation.Id] = variation;
            }
        }
    }    

    return result;
}
  
function getLookupsFromCatalog(catalog: Catalog, lastSelectedLocationId:null|string) {
    var selectedLocation:(Location|null) = null;
    if (lastSelectedLocationId) {
        for(var i=0;i<catalog.Locations.length;i++) {
            var location:Location = catalog.Locations[i];
            if (location.Id == lastSelectedLocationId) {
                selectedLocation = location;
                break;
            }
        }
    }

    return getLookups(selectedLocation);
}

export default function catalog(state:any, action:any) {
    if (state === undefined) {
        return initialState;
    }
    switch(action.type) {
        case 'CATALOG_INITIALIZING':
            return {
                ...state,
                initialized: false,
                initializing: true,
                data: null,
                categories: [],
                categoriesById: {},
                itemsById: {},
                variationsById: {},
            };
        case 'CATALOG_INITIALIZED':
            var lookups = getLookupsFromCatalog(action.data, state.lastSelectedLocationId);
            return {
                ...state,
                initialized: true,
                initializing: false,
                data: action.data,
                categories: lookups.categories,
                categoriesById: lookups.categoriesById,
                itemsById: lookups.itemsById,
                variationsById: lookups.variationsById
            };
        case 'LOCATION_SELECTED':
            var lookups = getLookups(action.location);
            return {
                ...state,
                lastSelectedLocationId: action.location.Id,
                categories: lookups.categories,
                categoriesById: lookups.categoriesById,
                itemsById: lookups.itemsById,
                variationsById: lookups.variationsById
            };
        case 'CATALOG_SELECT_CATEGORY':
            return {
                ...state,
                selectedCategoryId: action.category.Id,
            };
        default:
            return state;
    }  
}