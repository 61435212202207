const initialState = {
    giftcards: [],
    selectedGiftCard: null,
    saveModalVisible: false
};


export default function giftcards(state, action) {
    if (state === undefined) {
        return initialState;
    }
    switch (action.type) {
        case 'GIFTCARDS_INITIALIZED':
            var giftcards = action.giftcards ? [...action.giftcards] : null;
            var selectedGiftCard = action.selectedGiftCard ? { ...action.selectedGiftCard } : null;
            return {
                giftcards: giftcards,
                selectedGiftCard: selectedGiftCard,
                saveModalVisible: false
            };
        case 'GIFTCARDS_SELECT':
            return {
                ...state,
                selectedGiftCard: action.giftcard,
                saveModalVisible: false
            };
        case 'GIFTCARDS_REMOVE':
            var giftcard = action.giftcard;
            var giftcards = state.giftcards.filter((listItem) => { return listItem.id != giftcard.id; });
            var selectedGiftCard = state.selectedGiftCard;
            if (selectedGiftCard && ((giftcard.id && selectedGiftCard.id && giftcard.id == selectedGiftCard.id) || (giftcard.nonce && selectedGiftCard.nonce && giftcard.nonce == selectedGiftCard.nonce)))
                selectedGiftCard = null;
            return {
                ...state,
                giftcards: giftcards,
                selectedGiftCard: selectedGiftCard,
                saveModalVisible: false
            };
        case 'GIFTCARDS_SHOW_SAVE_CONFIRMATION':
            return {
                ...state,
                saveModalVisible: true
            };
        case 'GIFTCARDS_HIDE_SAVE_CONFIRMATION':
            return {
                ...state,
                saveModalVisible: false
            };
        default:
            return state;
    }
}