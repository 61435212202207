/**
 * PoppinPay API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
* The AddressModel model module.
* @module model/AddressModel
* @version 1.0.0
*/
export default class AddressModel {
    /**
    * Constructs a new <code>AddressModel</code>.
    * @alias module:model/AddressModel
    * @class
    */

    constructor() {
        
        
        
    }

    /**
    * Constructs a <code>AddressModel</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/AddressModel} obj Optional instance to populate.
    * @return {module:model/AddressModel} The populated <code>AddressModel</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new AddressModel();
                        
            
            if (data.hasOwnProperty('AddressLine1')) {
                obj['AddressLine1'] = ApiClient.convertToType(data['AddressLine1'], 'String');
            }
            if (data.hasOwnProperty('AddressLine2')) {
                obj['AddressLine2'] = ApiClient.convertToType(data['AddressLine2'], 'String');
            }
            if (data.hasOwnProperty('AddressLine3')) {
                obj['AddressLine3'] = ApiClient.convertToType(data['AddressLine3'], 'String');
            }
            if (data.hasOwnProperty('Locality')) {
                obj['Locality'] = ApiClient.convertToType(data['Locality'], 'String');
            }
            if (data.hasOwnProperty('Sublocality1')) {
                obj['Sublocality1'] = ApiClient.convertToType(data['Sublocality1'], 'String');
            }
            if (data.hasOwnProperty('Sublocality2')) {
                obj['Sublocality2'] = ApiClient.convertToType(data['Sublocality2'], 'String');
            }
            if (data.hasOwnProperty('Sublocality3')) {
                obj['Sublocality3'] = ApiClient.convertToType(data['Sublocality3'], 'String');
            }
            if (data.hasOwnProperty('AdministrativeDistrictLevel1')) {
                obj['AdministrativeDistrictLevel1'] = ApiClient.convertToType(data['AdministrativeDistrictLevel1'], 'String');
            }
            if (data.hasOwnProperty('AdministrativeDistrictLevel2')) {
                obj['AdministrativeDistrictLevel2'] = ApiClient.convertToType(data['AdministrativeDistrictLevel2'], 'String');
            }
            if (data.hasOwnProperty('AdministrativeDistrictLevel3')) {
                obj['AdministrativeDistrictLevel3'] = ApiClient.convertToType(data['AdministrativeDistrictLevel3'], 'String');
            }
            if (data.hasOwnProperty('PostalCode')) {
                obj['PostalCode'] = ApiClient.convertToType(data['PostalCode'], 'String');
            }
            if (data.hasOwnProperty('Country')) {
                obj['Country'] = ApiClient.convertToType(data['Country'], 'String');
            }
            if (data.hasOwnProperty('Longitude')) {
                obj['Longitude'] = ApiClient.convertToType(data['Longitude'], 'Number');
            }
            if (data.hasOwnProperty('Latitude')) {
                obj['Latitude'] = ApiClient.convertToType(data['Latitude'], 'Number');
            }
            if (data.hasOwnProperty('FormattedAddress')) {
                obj['FormattedAddress'] = ApiClient.convertToType(data['FormattedAddress'], 'String');
            }
        }
        return obj;
    }

    /**
    * @member {String} AddressLine1
    */
    'AddressLine1' = undefined;
    /**
    * @member {String} AddressLine2
    */
    'AddressLine2' = undefined;
    /**
    * @member {String} AddressLine3
    */
    'AddressLine3' = undefined;
    /**
    * @member {String} Locality
    */
    'Locality' = undefined;
    /**
    * @member {String} Sublocality1
    */
    'Sublocality1' = undefined;
    /**
    * @member {String} Sublocality2
    */
    'Sublocality2' = undefined;
    /**
    * @member {String} Sublocality3
    */
    'Sublocality3' = undefined;
    /**
    * @member {String} AdministrativeDistrictLevel1
    */
    'AdministrativeDistrictLevel1' = undefined;
    /**
    * @member {String} AdministrativeDistrictLevel2
    */
    'AdministrativeDistrictLevel2' = undefined;
    /**
    * @member {String} AdministrativeDistrictLevel3
    */
    'AdministrativeDistrictLevel3' = undefined;
    /**
    * @member {String} PostalCode
    */
    'PostalCode' = undefined;
    /**
    * @member {String} Country
    */
    'Country' = undefined;
    /**
    * @member {Number} Longitude
    */
    'Longitude' = undefined;
    /**
    * @member {Number} Latitude
    */
    'Latitude' = undefined;
    /**
    * @member {String} FormattedAddress
    */
    'FormattedAddress' = undefined;




}
