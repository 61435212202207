import React from 'react';
import { connect } from 'react-redux';

class PickupDateTimeForm extends React.Component {

    onDateSelected(dateKey) {
        var date = this.props.validDates.find((v) => v.format('x') == dateKey);
        this.props.setDate(date);
    }

    renderDateOption(date) {
        var dayOfWeek = date.format('dddd');
        var dateText = date.format('LL');

        var key = date.format('x');

        return (
            <option key={key} value={key}>{dayOfWeek} {dateText}</option>
        )
    }

    renderDateField() {
        if (!this.props.validDates || this.props.validDates.length == 0) {
            return null;
        }

        return (
            <select value={this.props.date} disabled={this.props.fulfillmentType === 'DINEIN' ? true : false} onChange={(e) => this.onDateSelected(e.target.value)}>
                {this.props.validDates.map(r => this.renderDateOption(r))}
            </select>
        );
    }

    onTimeSelected(timeKey) {
        var time = this.props.validTimes.find((v) => v.key == timeKey);
        this.props.setTime(time);
    }

    renderTimeOption(time) {
        var timeText = time.longLabel;
        var key = time.key;

        return (
            <option key={key} value={key}>{timeText}</option>
        );
    }

    renderTimeField() {
        if (!this.props.validTimes || this.props.validTimes.length == 0) {
            return null;
        }
        var value = this.props.time ? this.props.time.key : null;

        return (
            <select value={value} disabled={this.props.fulfillmentType === 'DINEIN' ? true : false} onChange={(e) => this.onTimeSelected(e.target.value)}>
                {this.props.validTimes.map(r => this.renderTimeOption(r))}
            </select>
        );
    }

    renderForm() {
        return (
            <div className="fields-inline">
                <div className="input full-size">
                    {this.renderDateField()}
                </div>
                <div className="at"> @ </div>
                <div className="input full-size">
                    {this.renderTimeField()}
                </div>
            </div>
        );
    }

    render() {
        return (
            <section className="pickup-date-and-time">
                <h3>Pickup Date &amp; Time</h3>
                {this.renderForm()}
            </section>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        validDates: state.cart.validDates,
        date: state.cart.date,
        validTimes: state.cart.validTimes,
        time: state.cart.time,
        fulfillmentType: state.cart.fulfillmentType,
    }
};

const mapDispatchToProps = (dispatch) => ({
    setDate: (date) => {
        dispatch({ type: 'CART_DATE_SELECTED', date: date });
    },
    setTime: (time) => {
        dispatch({ type: 'CART_TIME_SELECTED', time: time });
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(PickupDateTimeForm);