import React from 'react';
import { connect } from 'react-redux';

class CartNotifier extends React.Component {

    componentWillReceiveProps(nextProps) {
        if (nextProps.removedCount > 0 && nextProps.removedCount != this.props.removedCount) {
            this.props.toast.warning('We removed items from your cart because they are not available at this location.', () => {
                this.props.clearRemovedCount();
            });
        }
    } 

    render() {
        return null;
    }
}

const mapStateToProps = (state) => {
    return {
        removedCount: state.cart.removedCount,
    }
};

const mapDispatchToProps = (dispatch) => ({
    clearRemovedCount: () => {
        dispatch({type:'CART_CLEAR_REMOVED_COUNT'});
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(CartNotifier);